import { BackofficeApi } from 'apis/backoffice-api';
import { backofficeApiConfig } from 'config';
import { KeyValueModel, ResourceAutocompleteListParams, ResourceInfoModel, ResourceInfoModelArrayResultHandler, ResourceInfoModelPaginationResult, ResourceResourceInfoListParams, ResourceInfoCriteria, ResourceInfoProfilingCriteria } from 'apis/backoffice-api/data-contracts';
import { useSelector } from 'react-redux';
import { AuthReducerState } from 'redux/reducers/authReducer';

export const useResourceService = () => {
  const { accessToken } = useSelector((state: { auth: AuthReducerState }) => state.auth);

  const backofficeApi = new BackofficeApi({
    baseURL: backofficeApiConfig.baseURL,
    headers: { "Authorization": `bearer ${accessToken}` }
  });

  return {
    resourceResourceInfoAllList: async (): Promise<ResourceInfoModel[] | undefined | null> =>
      backofficeApi.resourceResourceInfoAllList().then((resp) => resp.data.data),
    resourceStatusList: async (): Promise<KeyValueModel[] | null | undefined> =>
      backofficeApi.resourceStatusList().then((resp) => resp?.data.data),
    resourceAutocompleteList: async (query: ResourceAutocompleteListParams): Promise<KeyValueModel[] | undefined | null> =>
      backofficeApi.resourceAutocompleteList(query).then((resp) => resp.data.data),
    resourceResourceInfoListFiltered: async (resourceResourceInfoListParams:ResourceResourceInfoListParams): Promise<ResourceInfoModelPaginationResult | undefined> =>
      backofficeApi.resourceResourceInfoList(resourceResourceInfoListParams).then((resp) => resp.data.data),
    resourceResourceInfoFilteredList: async (resourceResourceInfoListParams:ResourceResourceInfoListParams): Promise<ResourceInfoModel[] | undefined | null> =>
      backofficeApi.resourceResourceInfoAllFilteredList(resourceResourceInfoListParams).then((resp) => resp.data.data),
    allResourceResourceInfoIdsList: async (resourceResourceInfoListParams:ResourceResourceInfoListParams): Promise<string[] | undefined | null> =>
      backofficeApi.resourceResourceInfoIdsList(resourceResourceInfoListParams).then((resp) => resp.data.data),
    resourceInfoByResourceIdsPaginated: async (resourceInfoCriteria:ResourceInfoCriteria): Promise<ResourceInfoModelPaginationResult | undefined> =>
      backofficeApi.resourceResourceInfoPaginatedResourceIdsCreate(resourceInfoCriteria).then((resp) => resp.data.data),
    resourceInfoByResourceIdsAndAssessmentIdsPaginated: async (resourceInfoCriteria:ResourceInfoProfilingCriteria): Promise<ResourceInfoModelPaginationResult | undefined> =>
        backofficeApi.resourceResourceInfoPaginatedResourceIdsAssessmentIdsCreate(resourceInfoCriteria).then((resp) => resp.data.data),
    resourceInfoWithAppraisersCountByResourceIdsPaginated: async (resourceInfoCriteria:ResourceInfoCriteria): Promise<ResourceInfoModelPaginationResult | undefined> =>
      backofficeApi.resourceResourceInfoPaginatedResourceIdsCountAppraisersCreate(resourceInfoCriteria).then((resp) => resp.data.data),
    resourceInfoByResourceIds: async (resourceInfoCriteria:ResourceInfoCriteria): Promise<ResourceInfoModel[] | undefined | null> =>
      backofficeApi.resourceResourceInfoAllFilteredResourceIdsCreate(resourceInfoCriteria).then((resp) => resp.data.data),
    resourceInfoByResourceIdsAndAssessmentIds: async (resourceInfoProfilingCriteria:ResourceInfoProfilingCriteria): Promise<ResourceInfoModel[] | undefined | null> =>
      backofficeApi.resourceResourceInfoAllFilteredResourceIdsAssessmentIdsCreate(resourceInfoProfilingCriteria).then((resp) => resp.data.data),
    resourceInfoFilteredByAssessmentList: async (query: ResourceInfoProfilingCriteria): Promise<ResourceInfoModelPaginationResult | undefined> =>
      backofficeApi.resourceResourceInfoPaginatedAssessmentListCreate(query).then((resp) => resp.data.data),
    // resourceInfoAssessmentListCreate: async (query: string[]): Promise<ResourceInfoModel[] | undefined | null> =>
    //   backofficeApi.resourceResourceInfoPaginatedAssessmentListCreate(query).then((resp) => resp.data.data),
    resourceResourceInfoAssessmentListAnyCreate: async (query: string[]): Promise<boolean | undefined | null> =>
      backofficeApi.resourceResourceInfoAssessmentListAnyCreate(query).then((resp) => resp.data.data),
    existAnyResourceWithAnswerInAllAssessmentIdsByResourceIdsAsync: async (query: ResourceInfoProfilingCriteria): Promise<boolean | undefined | null> =>
      backofficeApi.resourceResourceInfoAssessmentListResourceListIdAnyCreate(query).then((resp) => resp.data.data),
  }
}
