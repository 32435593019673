import { Paper, PaperProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SearchNotFoundProps extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({ searchQuery = '', ...other }: SearchNotFoundProps) {

  const { t } = useTranslation();

  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {t('common:capitalize', {value: t('common:notFound')})}
      </Typography>
      <Typography variant="body2" align="center">
        {t('common:noResultsFoundTryModifyTheFilter')}
      </Typography>
    </Paper>
  );
}
