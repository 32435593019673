/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthCreateUserCreateParams,
  ForgotPasswordCriteria,
  LoginByPasswordCriteria,
  LoginByTokenCriteria,
  NewPasswordCriteria,
  StringStringDictionaryResultHandler,
  VerifyTokenCriteria
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AuthApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Auth
   * @name AuthLoginPasswordCreate
   * @summary Logins by password asynchronous.
   * @request POST:/api/auth/login-password
   * @response `200` `void` Success
   */
  authLoginPasswordCreate = (data: LoginByPasswordCriteria, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/login-password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthLoginTokenCreate
   * @summary Login by Token.
   * @request POST:/api/auth/login-token
   * @response `200` `void` Success
   */
  authLoginTokenCreate = (data: LoginByTokenCriteria, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/login-token`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthForgotPasswordCreate
   * @summary Forgot password
   * @request POST:/api/auth/forgot-password
   * @response `200` `void` Success
   */
  authForgotPasswordCreate = (data: ForgotPasswordCriteria, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/forgot-password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthVerifyCreate
   * @summary Verify Token
   * @request POST:/api/auth/verify
   * @response `200` `void` Success
   */
  authVerifyCreate = (data: VerifyTokenCriteria, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/verify`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthNewPasswordCreate
   * @summary Set the new password
   * @request POST:/api/auth/new-password
   * @response `200` `void` Success
   */
  authNewPasswordCreate = (data: NewPasswordCriteria, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/new-password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGeneratePasswordCreate
   * @summary Generates the random password list.
   * @request POST:/api/auth/generate-password
   * @response `200` `void` Success
   */
  authGeneratePasswordCreate = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/auth/generate-password`,
      method: 'POST',
      ...params
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthCreateUserCreate
   * @summary Creates the by resource identifier asynchronous.
   * @request POST:/api/auth/create-user
   * @response `200` `StringStringDictionaryResultHandler` Success
   */
  authCreateUserCreate = (query: AuthCreateUserCreateParams, params: RequestParams = {}) =>
    this.request<StringStringDictionaryResultHandler, any>({
      path: `/api/auth/create-user`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params
    });
}
