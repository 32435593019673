import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";

// material
import { useTheme } from '@mui/material/styles';
import { Toolbar, Grid, Theme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TextInput } from "components/TextInput";
import { useTranslation } from "react-i18next";
export type AssessmentResourceFilterFormProps = {
  onSearch?: (prop: AssessmentResourceFilterFormValues) => void
 loading: boolean;
};

export type AssessmentResourceFilterFormValues = {
  name: string;

};

export const AssessmentResourceFilterFormInitialValues = {
  name: '',
}

export function AssessmentResourceFilterForm(props: AssessmentResourceFilterFormProps) {
  const { onSearch, loading } = props;

  const theme = useTheme();
  const styles = useStyles(theme);

  const FilterSchema = Yup.object().shape({});

  const formik = useFormik<AssessmentResourceFilterFormValues>({
    initialValues: AssessmentResourceFilterFormInitialValues,
    validationSchema: FilterSchema,
    onSubmit: async (values: AssessmentResourceFilterFormValues) => {
      onSearch && onSearch({ ...values });
    }
  });

  const { handleSubmit, getFieldProps } = formik;

  const { t } = useTranslation();

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Toolbar style={styles.toolbar}>
          <Grid container>
            <Grid item xs={12}>

              <Grid container>
                <Grid item xs={12} display='flex' alignContent={'flex-end'} justifyContent={'flex-end'} style={styles.card}>
                  <TextInput
                    {...getFieldProps('name')}
                    fullWidth
                    sx={{mr: 3}}
                    label={`${t('common:capitalize', {value: t('common:search')})}...`} margin="normal" />
                </Grid>
                <Grid item sx={{mr: 3}} xs={12} display='flex' alignContent={'flex-end'} justifyContent={'flex-end'} style={styles.card}>
                  <LoadingButton sx={{ minWidth: '150px' }} type="submit" variant="contained" loading={loading}>
                      {t('common:capitalize', { value: t('common:search') })}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Form>
    </FormikProvider>
  );

}

const useStyles = (theme: Theme) => ({
  toolbar: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    margin: theme.spacing(0, 0, 3, 0)
  },
  card: {
    padding: theme.spacing(0, 0, 0, 3)
  }
})

