import { useEffect, useState } from 'react';

// material
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Stack,
  Card,
  Typography,
  Avatar,
  Autocomplete,
  TextField,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  AutocompleteRenderInputParams,
  AutocompleteInputChangeReason
} from '@mui/material';
import { debounce } from '@mui/material/utils';

// components
import SearchNotFound from 'components/SearchNotFound/SearchNotFound';
import {
  AssessmentNodeConditionEnum,
  AssessmentNodeModel,
  AssessmentNodeStatusEnum,
  AssessmentNodeTypeEnum,
  ResourceInfoCriteria,
  ResourceInfoModel,
  ResourceInfoSearchTypeEnum,
  ResourceResourceInfoListParams,
} from 'apis/backoffice-api/data-contracts';
import { getFirstCharacter, capitalCaseWords } from 'utils/firstCharacter';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '@iconify/react';
import trashOutlined from '@iconify/icons-eva/trash-outline';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useResourceService } from 'services/Resource';
import { useSnackbar } from 'notistack';

export type Props = {
  relationshipName: string;
  assessmentId: string;
  resourceId: string;
  isReadOnly: boolean;
  appraiserAssessmentNodeModelsToEdit: AssessmentNodeModel[],
  setAppraiserAssessmentNodeModelsToEdit: (data: AssessmentNodeModel[]) => void,
};
interface ResourceInfo extends ResourceInfoModel {
  name?: string;
}

export default function AppraiserList({
  relationshipName,
  assessmentId,
  resourceId,
  isReadOnly,
  appraiserAssessmentNodeModelsToEdit, 
  setAppraiserAssessmentNodeModelsToEdit

}: Props) {
  const [offSet, setOffset] = useState<number>(1);
  const [limit] = useState<number>(300);//TODO: colocar para 50 e ver como fica
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());
  const [resourceInfosFiltered, setResourceInfosFiltered] = useState<ResourceInfo[]>([]);
  const [addedResourceInfos, setAddedResourceInfos] = useState<ResourceInfo[]>([]);
  const [didMount, setDidMount] = useState<boolean>(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const { resourceResourceInfoFilteredList: resourceResourceInfoAllFilteredList, resourceInfoByResourceIdsPaginated: resourceInfoByResourceIds } = useResourceService();
  
  const { t } = useTranslation();

  const defaultProps = {
    options: loadingAutocomplete ? [] : resourceInfosFiltered,
    getOptionLabel: (option: ResourceInfo) => `${option.name} (${option.email})` ?? ''
  };

  const listAssessmentNodes = () => {
    setLoading(true);
    
    let newFilteredAssessmentNodesByAppraiseeIdSet =
      appraiserAssessmentNodeModelsToEdit?.filter((assessmentNode) => {
        return (
          assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId &&
          assessmentNode?.type == relationshipName
        );
      }) ?? [];

    let resourceInfoIds = newFilteredAssessmentNodesByAppraiseeIdSet
      .filter((assessmentNode) => 
        {
          return assessmentNode.condition != AssessmentNodeConditionEnum.Deleted
          && !!assessmentNode.appraiserAssessmentParticipant?.resourceId
          && assessmentNode.appraiserAssessmentParticipant?.resourceId != ""
        })?.map((assessmentNode) => {
              return assessmentNode.appraiserAssessmentParticipant?.resourceId ?? ""
        });

    let resourceInfoCriteriaParams: ResourceInfoCriteria = 
    {
      limit: limit,
      offset: offSet,
      search: "",
      resourceIdList: resourceInfoIds
    };
    
    (async () => {
      await resourceInfoByResourceIds(resourceInfoCriteriaParams)
        .then((data) => {
          setTotalCount(data?.pagination?.totalCount ?? 0);
          setAddedResourceInfos(data?.result ?? []);
          setLoading(false);
        })
        .catch((errorValue) => {
          console.log(errorValue);          
          setLoading(false);
        });
    })();
  };
  
  const getResourceInfoFiltered = React.useMemo(
    () =>
      debounce(
        (
          textSearch:string
        ) => {
          setLoadingAutocomplete(true);
          if(!textSearch || textSearch === "" || textSearch.length < 3){
            setLoadingAutocomplete(false);
            return
          }

          (async () => {
          let resourceInfoCriteriaParams: ResourceResourceInfoListParams =
          {
            ResourceInfoSearchType: ResourceInfoSearchTypeEnum.Name,
            Search: textSearch,
            SearchExactlyText: false
          };

          await resourceResourceInfoAllFilteredList(resourceInfoCriteriaParams)
            .then((data) => {
              return [...data ?? []];
            })
            .then((data: ResourceInfo[]) =>
              data?.map((resource) => {
                resource.name = `${resource.firstName} ${resource.lastName}` || '--';
                resource.branch = resource.branch || '--';
                resource.department = resource.department || '--';
                resource.team = resource.team || '--';
                resource.role = resource.role || '--';
                resource.resourceId = resource.resourceId || '--';
                return resource;
              })
            )
            .then((data) => {
              setResourceInfosFiltered(data ?? []);
              setLoadingAutocomplete(false);
            })
            .catch((err) => { console.log(err); setLoadingAutocomplete(false); })
          })();
        },
        600,
      ),
    [],
  );

  const searchResourceInfo = (textSearch:string, reason:AutocompleteInputChangeReason) => {
    if(reason === 'input')
      getResourceInfoFiltered(textSearch);
  }

  const addResourceInfoToassessmentNode = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ResourceInfo | null
  ) => {
    if (newValue?.resourceId == resourceId) {
      setAutoCompleteKey(uuidv4());
      setResourceInfosFiltered([]);
      return;
    }

    let assessmentNodeModelsToUpdate = [...appraiserAssessmentNodeModelsToEdit];

    let appraiserAssessmentNodeToUpdateIndex = assessmentNodeModelsToUpdate.findIndex((assessmentNode) => 
      assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId 
      && assessmentNode.appraiserAssessmentParticipant?.resourceId == newValue?.resourceId);

    let typedNodeTypeString = relationshipName as keyof typeof AssessmentNodeTypeEnum;

    if (appraiserAssessmentNodeToUpdateIndex == -1){
      assessmentNodeModelsToUpdate = assessmentNodeModelsToUpdate.concat([
        {
          type: AssessmentNodeTypeEnum[typedNodeTypeString],
          status: AssessmentNodeStatusEnum.NotStarted,
          assessmentId: assessmentId,
          appraiseeAssessmentParticipant: { assessmentId: assessmentId, resourceId: resourceId },
          appraiserAssessmentParticipant: { assessmentId: assessmentId, resourceId: newValue?.resourceId },
          condition: AssessmentNodeConditionEnum.Added
        }
      ]);
    }else if(assessmentNodeModelsToUpdate[appraiserAssessmentNodeToUpdateIndex].condition == AssessmentNodeConditionEnum.Deleted){
      assessmentNodeModelsToUpdate[appraiserAssessmentNodeToUpdateIndex].condition == AssessmentNodeConditionEnum.Added;
    }else{
      enqueueSnackbar(`${t('assessment:employeeAddedPreviously')}`, { variant: 'warning', autoHideDuration: 4000 });
    }

    setAppraiserAssessmentNodeModelsToEdit(assessmentNodeModelsToUpdate ?? []);
    setAutoCompleteKey(uuidv4());
    setResourceInfosFiltered([]);
  };

  const deleteAssessmentNode = (appraiserResourceId: string) => {
    let assessmentNodeModelsToUpdate = [...appraiserAssessmentNodeModelsToEdit];
    
    let assessmentNodeModelsToUpdateIndex = assessmentNodeModelsToUpdate.findIndex((assessmentNode) => assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId 
          && assessmentNode.appraiserAssessmentParticipant?.resourceId == appraiserResourceId);

    if (!assessmentNodeModelsToUpdateIndex) return;

    assessmentNodeModelsToUpdate[assessmentNodeModelsToUpdateIndex].condition = AssessmentNodeConditionEnum.Deleted;

    setAppraiserAssessmentNodeModelsToEdit(assessmentNodeModelsToUpdate);
  };

  useEffect(() => {
    if(!didMount) return;
    (async () => {
      await listAssessmentNodes();
    })();
  }, [limit, offSet, appraiserAssessmentNodeModelsToEdit]);

  useEffect(() => {
    if(!didMount) return;
    (async () => {
      listAssessmentNodes();
    })();
  }, [didMount]);

  useEffect(() => {
    if(!didMount) setDidMount(true) ;
  }, []);

  return (
    <Card sx={{ pt: 3 }}>
      {!isReadOnly && (
        <Autocomplete
          {...defaultProps}
          id='resourceInfoSearch'
          key={autoCompleteKey}
          autoComplete
          noOptionsText={t('common:capitalize', { value: t('common:noOptions') })}
          loading={loadingAutocomplete}
          loadingText={`${t("common:loading")}...`}
          includeInputInList
          sx={{ width: 300 }}
          onInputChange={(event, newInputValue, reason) => {
            searchResourceInfo(newInputValue, reason);
          }}
          renderInput={(params:AutocompleteRenderInputParams) => (
            <TextField 
            {...params} 
            label={t('common:capitalize', { value: t('common:add') })} 
            variant='outlined'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingAutocomplete ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}/>
          )}
          onChange={addResourceInfoToassessmentNode}
        />
      )}
      <TableContainer sx={{ marginTop: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:name') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:email') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:role') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:department') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:team') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:branch') })}</TableCell>
              {!isReadOnly && <TableCell align='left' />}
            </TableRow>
          </TableHead>
          {!loading && totalCount > 0 ? (
            <TableBody>
              {addedResourceInfos
                .map((resourceInfo) => {
                  return (
                    <TableRow hover key={resourceInfo.resourceId}>
                      <TableCell align='left'>
                        <Stack direction='row' alignItems='center' spacing={2}>
                          {!!resourceInfo.profileImageUrl ? (
                            <Avatar alt={resourceInfo.firstName ?? ''} src={resourceInfo.profileImageUrl} />
                          ) : (
                            <Avatar>{getFirstCharacter(resourceInfo.firstName ?? '')}</Avatar>
                          )}
                          <Typography variant='subtitle2' noWrap>
                            {`${capitalCaseWords(resourceInfo?.firstName as string)} ${capitalCaseWords(
                              resourceInfo?.lastName ?? ''
                            )}`.trim()}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align='left'>{resourceInfo.email ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.role ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.department ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.team ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.branch ?? '--'}</TableCell>

                      {!isReadOnly && (
                        <TableCell align='right'>
                          <ListItemIcon
                            onClick={() => deleteAssessmentNode(resourceInfo.resourceId ?? '')}
                            sx={{ cursor: 'pointer' }}>
                            <Icon icon={trashOutlined} width={20} height={20} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('common:capitalize', { value: t('common:remove') })}
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {resourceInfosFiltered.filter((resourceInfo) => {
                return appraiserAssessmentNodeModelsToEdit?.filter((assessmentNode) => {
                  return (
                    assessmentNode?.type == relationshipName &&
                    resourceInfo.resourceId == assessmentNode?.appraiserAssessmentParticipant?.resourceId
                  );
                }).length;
              }).length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align='center' colSpan={8}>
                  {loading ? <CircularProgress /> : <SearchNotFound />}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[limit]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={offSet - 1}
        labelRowsPerPage='Rows'
        labelDisplayedRows={({ from, to, count }) => {
          return '' + from + '-' + to + `${t('common:TextPagination')}` + count;
        }}
        onPageChange={(e, page) => setOffset(page + 1)}
      />
    </Card>
  );
}
