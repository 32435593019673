import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// typings
import { assessmentPerformanceMode } from '../AssessmentAppraisees/typings';

// material
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  formControlClasses,
  Grid,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

// routes
import { PATH_BACKOFFICE } from 'routes/paths';

// hooks
import useSettings from 'hooks/useSettings';

// utils
import { getFirstCharacter, capitalCaseWords } from 'utils/firstCharacter';

// apis
import {
  AssessmentNodeModel,
  AssessmentNodeStatusEnum,
  AssessmentNodeTypeEnum,
  AssessmentPerformanceNextModel,
  KeyValueModel,
  ResourceEmployeeModel,
  ResourceInfoModel,
  ResourceModel,
  ResourceNodeTypeEnum,
  ResourceInfoCriteria,
  ResourceStatusEnum,
  AssessmentNodeConditionEnum,
  AssessmentEmailTemplateTypeEnum,
  AssessmentTypeEnum,
  AssessmentStatusEnum
} from 'apis/backoffice-api/data-contracts';

// components
import SearchNotFound from 'components/SearchNotFound/SearchNotFound';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import {
  AssessmentResourceFilterForm,
  AssessmentResourceFilterFormInitialValues,
  AssessmentResourceFilterFormValues
} from 'pages/assessments/performance/AssessmentResource/AssessmentResouceFilterForm';
import { AssessmentRelationshipModal } from 'pages/assessments/performance/AssessmentResource/components/AsessmentResourceModal';
import { AssessmentResourceActionMenu } from 'pages/assessments/performance/AssessmentResource/components/AssessmentResourceActionMenu';
import { useAssessmentPerformanceService } from 'services/AssessmentPerformanceService';
import { useResourceService } from 'services/Resource';

import { locationAssessmentNodesState } from '../AssessmentAppraisees/typings';
import { useTranslation } from 'react-i18next';
import { useAssessmentNodeService } from 'services/AssessmentNodeService';
import { Loading } from 'components/Loading';
import { LoadingButton } from '@mui/lab';


export default function AssessmentResourceRelationsList() {
  const navigate = useNavigate();
  const locationState = useLocation().state as locationAssessmentNodesState;


  const { assessmentPerformanceDetailNext, assessmentSendFirstWelcomeEmailCreate } = useAssessmentPerformanceService();
  const { assessmentNodeCreate } = useAssessmentNodeService();
  const { resourceInfoWithAppraisersCountByResourceIdsPaginated } = useResourceService();

  const { themeStretch } = useSettings();
  const [selfAssessmentNodes, setSelfAssessmentNodes] = useState<AssessmentNodeModel[]>([]);

  const [didMount, setDidMount] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [offSet, setOffset] = useState<number>(1);
  const [limit] = useState<number>(150);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<AssessmentResourceFilterFormValues>(AssessmentResourceFilterFormInitialValues);
  const [showNodeModal, setShowModal] = useState<boolean>(false);
  const [showAppraiseesSavedModal, setShowAppraiseesSavedModal] = useState<boolean>(false);
  const [resourceInfosFiltered, setResourceInfosFiltered] = useState<ResourceInfoModel[]>([]);
  const [assessmentDetail, setAssessmentDetail] = useState<AssessmentPerformanceNextModel | undefined>(undefined);

  const [assessmentId, setAssessmentId] = useState<string>('');

  const { t } = useTranslation();


  useEffect(() => {
    if(!didMount) return;
    (async () => {
      await fetchResourceInfoByAssessmentNodeResourceIds();
    })();
  }, [limit, offSet]);

  useEffect(() => {
    if(!didMount) return;
    (async () => {
      if(offSet > 1)
        setOffset(1);
      else
        await fetchResourceInfoByAssessmentNodeResourceIds();
    })();
  }, [filter,selfAssessmentNodes]);

  useEffect(() => {
    if(!didMount) return;
    if (locationState.selfAssessmentNodes) {
      setSelfAssessmentNodes(locationState.selfAssessmentNodes);
      setAssessmentId(locationState.selfAssessmentNodes[0].assessmentId);
      assessmentPerformanceDetailNext(locationState.selfAssessmentNodes[0].assessmentId ?? '').then((data) => {
        setAssessmentDetail(data);
      });
    }
  }, [didMount]);

  useEffect(() => {
    if(!didMount) setDidMount(true) ;
  }, []);

  const fetchResourceInfoByAssessmentNodeResourceIds = () => {
    setLoading(true);

    let assessmentNodesFiltered = selfAssessmentNodes.filter((assessmentNode) => 
    {
      return assessmentNode.type === AssessmentNodeTypeEnum.Self
      && assessmentNode.condition !== AssessmentNodeConditionEnum.Deleted      
    })?.map((assessmentNode) => {
          return assessmentNode.appraiseeAssessmentParticipant?.resourceId ?? ""
    });
    
    let resourceInfoCriteriaParams: ResourceInfoCriteria = 
    {
      limit: limit,
      offset: offSet,
      search: filter.name == "" || !filter.name ? undefined : filter.name,
      resourceIdList: assessmentNodesFiltered,
      assessmentId: assessmentId
    };

    (async () => {
      await resourceInfoWithAppraisersCountByResourceIdsPaginated(resourceInfoCriteriaParams)
        .then((data) => {
          setTotalCount(data?.pagination?.totalCount ?? 0);
          setResourceInfosFiltered(data?.result ?? []);
          setLoading(false);
        })
        .catch((errorValue) => {
          console.log(errorValue);          
          setLoading(false);
        });
    })();
  };

  const handleCloseRelationshipModal = () => {
    setShowModal(false);
  };

  const finish = async () => {
    setIsSubmitting(true);

    await assessmentNodeCreate(assessmentId, selfAssessmentNodes)
      .then(() => {
        if(assessmentDetail?.status == AssessmentStatusEnum.Started){
          const assessmentEmailTemplateType: AssessmentEmailTemplateTypeEnum =
          assessmentDetail?.type == AssessmentTypeEnum.Performance
              ? AssessmentEmailTemplateTypeEnum.WelcomePerformance
              : AssessmentEmailTemplateTypeEnum.WelcomeSkills;
          assessmentSendFirstWelcomeEmailCreate({assessmentId: assessmentId, forceAssessmentEmailTemplateTypeId: assessmentEmailTemplateType});
          setShowAppraiseesSavedModal(true);
        }else{
          navigate(PATH_BACKOFFICE.assessments.performance.root)
        }
      })
      .catch((erro) => console.log(erro));
    setIsSubmitting(false);
  };

  const EditAppraisees = async () => {
    navigate(PATH_BACKOFFICE.assessments.performance.appraisees, {
      state: {
        assessmentId: assessmentId ?? '',
        mode: assessmentPerformanceMode.EDITED,
        selfAssessmentNodes: selfAssessmentNodes,
        assessmentStatus:
          selfAssessmentNodes.length > 0
            ? selfAssessmentNodes[0].assessment !== undefined
              ? selfAssessmentNodes[0].assessment.status
              : AssessmentStatusEnum.NotStarted
            : AssessmentStatusEnum.NotStarted
      }
    });
  };

  return (
    <Loading loading={isSubmitting}>
      <Page title={`HR Metrics | ${t('common:capitalize', { value: t('common:resources') })}`}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={t('common:capitalize', { value: t('assessment:appraiseesGroup') })}
            links={[
              {
                name: `${t('common:capitalize', { value: t('assessment:performance') })}`,
                href: PATH_BACKOFFICE.assessments.performance.root
              },
              {
                name: `${t('common:capitalize', { value: t('assessment:editAssessment') })}`,
                href:
                  assessmentDetail && assessmentDetail.status === AssessmentStatusEnum.NotStarted
                    ? PATH_BACKOFFICE.assessments.performance.edit + '/' + (assessmentId ?? '')
                    : ''
              },
              { name: `${t('common:capitalize', { value: t('assessment:addPeople') })}` },
              { name: `${t('common:capitalize', { value: t('assessment:appraiseesGroup') })}` }
            ]}
          />
          <Card sx={{ pt: 3 }}>
            <Scrollbar>
              <TableContainer>
                <AssessmentResourceFilterForm onSearch={setFilter} loading={loading} />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>{t('common:capitalize', { value: t('common:name') })}</TableCell>
                      <TableCell align='left'>{t('common:capitalize', { value: t('common:email') })}</TableCell>
                      <TableCell align='center'>
                        {t('common:capitalize', { value: t('common:directManager') })}
                      </TableCell>
                      <TableCell align='center'>
                        {t('common:capitalize', { value: t('common:indirectManager') })}
                      </TableCell>
                      <TableCell align='center'>{t('common:capitalize', { value: t('common:subordinate') })}</TableCell>
                      <TableCell align='center'>{t('common:capitalize', { value: t('common:peer') })}</TableCell>
                      <TableCell align='center'>
                        {t('common:capitalize', { value: t('common:internalClient') })}
                      </TableCell>
                      <TableCell align='center'>
                        {t('common:capitalize', { value: t('common:externalClient') })}
                      </TableCell>
                      <TableCell align='left' />
                    </TableRow>
                  </TableHead>
                  {!loading && selfAssessmentNodes.length > 0 ? (
                    <TableBody>
                      {resourceInfosFiltered.map((resourceInfoFiltered) => {
                        return (
                          !!resourceInfoFiltered && (
                            <TableRow hover key={resourceInfoFiltered.resourceId}>
                              <TableCell align='left'>
                                <Stack direction='row' alignItems='center' spacing={2}>
                                  {!!resourceInfoFiltered?.profileImageUrl ? (
                                    <Avatar
                                      alt={resourceInfoFiltered?.firstName ?? ''}
                                      src={resourceInfoFiltered?.profileImageUrl}
                                    />
                                  ) : (
                                    <Avatar>{getFirstCharacter(resourceInfoFiltered?.firstName ?? '')}</Avatar>
                                  )}
                                  <Typography variant='subtitle2' noWrap>
                                    {`${capitalCaseWords(resourceInfoFiltered?.firstName as string)} ${capitalCaseWords(
                                      resourceInfoFiltered?.lastName ?? ''
                                    )}`.trim()}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align='left'>{resourceInfoFiltered.email}</TableCell>
                              <TableCell align='center'>{'(' + (resourceInfoFiltered.leaderCount ?? 0) + ')'}</TableCell>
                              <TableCell align='center'>{'(' + (resourceInfoFiltered.leaderIndirectCount ?? 0) + ')'}</TableCell>
                              <TableCell align='center'>{'(' + (resourceInfoFiltered.staffCount ?? 0) + ')'}</TableCell>
                              <TableCell align='center'>{'(' + (resourceInfoFiltered.peerCount ?? 0) + ')'}</TableCell>
                              <TableCell align='center'>{'(' + (resourceInfoFiltered.clientInternalCount ?? 0) + ')'}</TableCell>
                              <TableCell align='center'>{'(' + (resourceInfoFiltered.clientExternalCount ?? 0) + ')'}</TableCell>
                              <TableCell align='right'>
                                <AssessmentResourceActionMenu
                                  resourceId={resourceInfoFiltered.resourceId}
                                  resourceName={`${capitalCaseWords(
                                    resourceInfoFiltered?.firstName as string
                                  )} ${capitalCaseWords(resourceInfoFiltered?.lastName ?? '')}`.trim()}
                                  selfAssessmentNodes={selfAssessmentNodes}
                                  setSelfAssessmentNodes={setSelfAssessmentNodes}
                                  assessment={assessmentDetail}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        );
                      })}
                      {selfAssessmentNodes.length === 0 && (
                        <TableRow style={{ height: 53 }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={12}>
                          {loading ? <CircularProgress /> : <SearchNotFound />}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[limit]}
              component='div'
              count={totalCount}
              rowsPerPage={limit}
              page={offSet - 1}
              labelRowsPerPage='Rows'
              labelDisplayedRows={({ from, to, count }) => {
                return '' + from + '-' + to + `${t('common:TextPagination')}` + count;
              }}
              onPageChange={(e, page) => setOffset(page + 1)}
            />
          </Card>
          {
            <AssessmentRelationshipModal
              onClose={handleCloseRelationshipModal}
              assessmentNodeModel={selfAssessmentNodes}
              showModal={showNodeModal}
            />
          }
          <Box sx={{ pt: 3 }} display='flex' alignItems='flex-end' justifyContent='flex-end' marginBottom={5}>
            {selfAssessmentNodes[0]?.assessment?.status === AssessmentStatusEnum.NotStarted ||
            selfAssessmentNodes[0]?.assessment?.status === AssessmentStatusEnum.Started ||
            selfAssessmentNodes[0]?.assessment?.status === undefined ? (
              <>
                <LoadingButton
                  loading={loading || isSubmitting}
                  variant='outlined'
                  color='primary'
                  style={{ width: '200px', marginRight: 10 }}
                  onClick={EditAppraisees}>
                  {t('common:capitalize', { value: t('common:edit') })}
                </LoadingButton>
                <LoadingButton
                  loading={loading || isSubmitting}
                  variant='contained'
                  style={{ width: '200px' }}
                  onClick={finish}>
                  {t('common:capitalize', { value: t('common:save') })}
                </LoadingButton>
              </>
            ) : (
              <LoadingButton
                loading={loading || isSubmitting}
                variant='outlined'
                size='medium'
                sx={{ width: '200px' }}
                onClick={() => {
                  window.location.href = PATH_BACKOFFICE.assessments.performance.root;
                }}>
                {t('common:capitalize', { value: t('common:back') })}
              </LoadingButton>
            )}
          </Box>
        </Container>

        <Dialog open={showAppraiseesSavedModal} maxWidth='xs'>
        <DialogTitle>{t('common:capitalize', { value: t('assessment:updatedEmployeesAndEmailsWillBeSend') })}</DialogTitle>
        <DialogActions>
          <Button
            type='submit'
            variant='contained'
            autoFocus
            onClick={() => {
              navigate(PATH_BACKOFFICE.assessments.performance.root)
            }}>
            {t('common:capitalize', { value: t('common:continue') })}
          </Button>
        </DialogActions>
        </Dialog>
      </Page>
    </Loading>
  );
}

const styles = {
  labelButton: {
    backgroundColor: 'rgba(153, 153, 153, 0.16)',
    color: '#373737',
    padding: '16px 0',
    minWidth: '102px',
    cursor: 'pointer'
  }
};
