import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

// material
import { Stack, Alert, Link, } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// hooks
import useIsMountedRef from 'hooks/useIsMountedRef';

// components
import { Link as RouterLink } from 'react-router-dom';
import { MIconButton } from 'components/@material-extend';
import { TextInput } from "components/TextInput";
import { useTranslation } from 'react-i18next';

// services
import { useAuthService } from "services/AuthService";
import { PATH_AUTH } from 'routes/paths';
import { padding } from '@mui/system';

type LoginFormValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export function LoginForm() {
  const { login } = useAuthService();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('validations:emailMustBeValid')).required(t('validations:emailOrPasswordInvalid')),
    password: Yup.string().required(t('validations:passwordIsRequired'))
  });

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      await login(values.email, values.password)
        .then(() => {
          enqueueSnackbar(t('validations:loginSuccess'), {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        })
        .catch(() => {
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: t('validations:emailOrPasswordInvalid') });
          }
        })
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, handleChange, setFieldValue, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} >
          {/*errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>*/}
          <TextInput
            {...getFieldProps('email')}
            fullWidth
            autoComplete="username"
            mask="username"
            type="email"
            label={t('validations:emailAddress')}
            onChange={handleChange('email')}
            error={Boolean((touched.email && errors.email)|| errors.afterSubmit)}
            helperText={(touched.email && errors.email)|| errors.afterSubmit}
          />
          <TextInput
            {...getFieldProps('password')}
            fullWidth
            secureTextEntry
            autoComplete="current-password"
            onChange={(e) => setFieldValue('password', e.target.value.replace(' ', ''))}
            type="password"
            label={t('validations:password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <Stack  alignItems={"end"}>
            <Link component={RouterLink} alignItems="end" justifyContent="end" variant="subtitle2" to={PATH_AUTH.resetPassword}>
            {t("validations:forgotPassword")} 
          </Link>
          </Stack>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('common:login')}
          </LoadingButton>
        </Stack>    
      </Form>
    </FormikProvider>
  );
}
