import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import editOutline from '@iconify/icons-eva/edit-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import emailOutline from '@iconify/icons-eva/email-outline';
import eyeOutline from '@iconify/icons-eva/eye-outline';
import playCircle from '@iconify/icons-eva/play-circle-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import personAddOutline from '@iconify/icons-eva/person-add-outline';
import finish from '@iconify/icons-eva/close-circle-outline';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';
// routes
import { PATH_BACKOFFICE } from 'routes/paths';
import { AssessmentEmailTemplateTypeEnum, AssessmentStatusEnum } from 'apis/backoffice-api/data-contracts';
import { useAssessmentSkillService } from 'services/AssessmentSkillService';
import { useTranslation } from 'react-i18next';
import { useAssessmentNodeService } from 'services/AssessmentNodeService';
import { useSnackbar } from 'notistack';
import { useAssessmentPerformanceService } from 'services/AssessmentPerformanceService';
import { assessmentSkillMode } from '../AssessmentAppraisees/typings';
import { EmailModal } from 'pages/assessments/performance/components/EmailModal';

type UserMoreMenuProps = {
  assessmentName?: string | null;
  assessmentId?: string | null;
  assessmentType?: string | null;
  status?: AssessmentStatusEnum;
  fetchAssessmentSkills: Function;
  setLoading: (data: boolean) => void;
  hasAssessmentNodes: boolean;
  loading: boolean;
};

export function AssessmentSkillListActionMenu({
  assessmentName,
  assessmentId,
  assessmentType,
  status,
  fetchAssessmentSkills,
  setLoading,
  hasAssessmentNodes,
  loading
}: UserMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalSentVisible, setModalSentVisible] = useState<boolean>(false);
  const [modalFinishVisible, setModalFinishVisible] = useState<boolean>(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [modalStartVisible, setModalStartVisible] = useState<boolean>(false);
  const [modalNewPeriodVisible, setModalNewPeriodVisible] = useState<boolean>(false);
  const [assessmentToFinishNotCompletedYet, setAssessmentToFinishNotCompletedYet] = useState<boolean>(false);

  const [emailSent, setEmailSent] = useState<String>('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assessmentSendEmail, sendEmailToNotInitiated } = useAssessmentPerformanceService();
  const {
    assessmentSkillDelete,
    startAssessmentSkill,
    finishAssessmentSkill,
    assessmentSkillGetModel,
    assessmentFinishVerify,
    assessmentSkillCreateNewPeriod
  } = useAssessmentSkillService();
  const [disabledconsultAppraiseesOrAppraisers, setDisabledconsultAppraiseesOrAppraisers] = useState<boolean>(true);
  const { assessmentNodesDetail } = useAssessmentNodeService();

  useEffect(() => {
    (async () => {
      await handleShouldConsultAppraiseesOrAppraisers(assessmentId);
    })();
  }, []);

  const handleFinishAssessmentModal = async (id?: string | null) => {
    await assessmentFinishVerify(id ?? '').then((data) => {
      setAssessmentToFinishNotCompletedYet(data.data ?? false);
      setModalFinishVisible(true);
    });
  };

  const handleDeleteAssessment = async (id?: string | null) => {
    setLoading(true);
    await assessmentSkillDelete(id ?? '');
    fetchAssessmentSkills();
    setLoading(false);
  };

  const handleCountNodes = async (id?: string | null) => {
    if (hasAssessmentNodes) {
      setModalVisible(true);
    } else {
      enqueueSnackbar(t('assessment:noPeopleAdded'), { variant: 'error' });
    }
  };

  const handleShouldConsultAppraiseesOrAppraisers = async (id?: string | null) => {
    if (hasAssessmentNodes) {
      setDisabledconsultAppraiseesOrAppraisers(false);
    } else {
      setDisabledconsultAppraiseesOrAppraisers(true);
    }
  };

  const handleCreateAssessmentNewPeriod = async (assessmentId?: string | null) => {
    setLoading(true);
    await assessmentSkillCreateNewPeriod(assessmentId ?? '').then((model) => {
      if (model != undefined) navigate(`${PATH_BACKOFFICE.assessments.skills.edit}/${model.assessmentId}`);
    });
  };

  const handleStartChecking = async (status: AssessmentStatusEnum | undefined, id?: string | null) => {
    const assessmentId: string = id ?? '';

    if (status === AssessmentStatusEnum.Completed) {
      setModalNewPeriodVisible(true);
    } else {
      if (hasAssessmentNodes) {
        setModalStartVisible(true);
      } else {
        enqueueSnackbar(t('assessment:noPeopleAdded'), { variant: 'error' });
      }
    }
  };

  const handleStartAssessment = async (id?: string | null) => {
    setLoading(true);
    await startAssessmentSkill(id ?? '')
      .then((data) => {})
      .catch(() => {});
    setLoading(false);
    fetchAssessmentSkills();

    enqueueSnackbar(`${t('common:capitalize', { value: t('assessment:AssessmentStartedAndEmailsSent') })}`, {
      variant: 'success'
    });
  };

  const handleGoToConsultAppraiseesOrAppraisers = async (assessmentId: string) => {
    setLoading(true);
    const assessmentNodes = await assessmentNodesDetail(assessmentId);
    if (assessmentNodes) {
      navigate(PATH_BACKOFFICE.assessments.skills.appraiseesGroup, {
        state: {
          assessmentId: assessmentNodes[0]?.assessmentId ?? '',
          mode: assessmentSkillMode.EDITED,
          assessmentNodes: assessmentNodes
        }
      });
    }
  };

  const handleGoToAddPeopleToAssessment = async (assessmentId: string, status: AssessmentStatusEnum) => {
    const assessmentNodes = await assessmentNodesDetail(assessmentId);
    if (assessmentNodes) {
      navigate(PATH_BACKOFFICE.assessments.skills.appraisees, {
        state: {
          assessmentId: assessmentId,
          assessmentStatus: status,
          mode: assessmentSkillMode.NEW,
          assessmentNodes: assessmentNodes
        }
      });
    }
  };

  const handleFinishAssessment = async (id?: string | null) => {
    setLoading(true);
    await finishAssessmentSkill(id ?? '')
      .then((data) => {
        enqueueSnackbar(`${t('common:capitalize', { value: t('assessment:assessmentCompleted') })}`, {
          variant: 'success'
        });
      })
      .catch(() => {});
    fetchAssessmentSkills();
    setLoading(false);
  };

  /* const handleConfirmAction = async () => {
    setModalVisible(false);
    await handleSendEmail(assessmentId);
  } */

  /* const handleEmailToUnicialized = async () => {
    setModalUnicializedVisible(false);
    
    await handleSendEmailToNotInitiated(assessmentId);
  } */

  const handleCloseEmployeeModal = () => {
    setIsOpen(false);
    setModalVisible(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 400, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem
          key={status}
          component={RouterLink}
          to={`${PATH_BACKOFFICE.assessments.skills.edit}/${assessmentId}`}
          sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={status === AssessmentStatusEnum.NotStarted ? editOutline : eyeOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={t('common:capitalize', {
              value:
                status === AssessmentStatusEnum.NotStarted ? t('skill:editSkillsAssessment') : t('skill:consultSkills')
            })}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {status !== AssessmentStatusEnum.Started && (
          <MenuItem sx={{ color: 'text.secondary' }} key={status}>
            <ListItemIcon>
              <Icon icon={playCircle} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={
                status === AssessmentStatusEnum.Completed
                  ? t('common:capitalize', { value: t('assessment:createNewAssessmentPeriod') })
                  : t('common:capitalize', { value: t('skill:startAssessmentPeriod') })
              }
              primaryTypographyProps={{ variant: 'body2' }}
              onClick={() => handleStartChecking(status, assessmentId)}
            />
          </MenuItem>
        )}

        {disabledconsultAppraiseesOrAppraisers && status === AssessmentStatusEnum.NotStarted ? (
          <MenuItem sx={{ color: 'text.secondary' }} key={status}>
            <ListItemIcon>
              <Icon icon={personAddOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t('common:capitalize', { value: t('assessment:addPeopleToAssessment') })}
              primaryTypographyProps={{ variant: 'body2' }}
              onClick={() => handleGoToAddPeopleToAssessment(assessmentId ?? '', status)}
            />
          </MenuItem>
        ) : (
          <MenuItem sx={{ color: 'text.secondary' }} key={status}>
            <ListItemIcon>
              <Icon icon={peopleOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t('common:capitalize', { value: t('assessment:consultAppraiseesOrAppraisers') })}
              primaryTypographyProps={{ variant: 'body2' }}
              onClick={() => handleGoToConsultAppraiseesOrAppraisers(assessmentId ?? '')}
            />
          </MenuItem>
        )}

        {status === AssessmentStatusEnum.NotStarted && (
          <MenuItem sx={{ color: 'text.secondary' }} key={status}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t('common:capitalize', { value: t('skill:removeSkillsAssessment') })}
              primaryTypographyProps={{ variant: 'body2' }}
              onClick={() => setModalDeleteVisible(true)}
            />
          </MenuItem>
        )}

        <MenuItem
          sx={{ color: 'text.secondary' }}
          key={status}
          hidden={true}
          disabled={
            status !== AssessmentStatusEnum.NotStarted && status !== AssessmentStatusEnum.Started ? true : false
          }>
          <ListItemIcon>
            <Icon icon={emailOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={t('common:capitalize', { value: t('assessment:sendEmail') })}
            primaryTypographyProps={{ variant: 'body2' }}
            onClick={() => handleCountNodes(assessmentId)}
          />
        </MenuItem>

        {status === AssessmentStatusEnum.Started && (
          <MenuItem sx={{ color: 'text.secondary' }} key={status}>
            <ListItemIcon>
              <Icon icon={finish} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t('common:capitalize', { value: t('skill:finishAssessmentPeriod') })}
              primaryTypographyProps={{ variant: 'body2' }}
              onClick={() => handleFinishAssessmentModal(assessmentId)}
            />
          </MenuItem>
        )}

        <EmailModal
          onClose={handleCloseEmployeeModal}
          assessmentName={assessmentName ?? ''}
          assessmentId={assessmentId ?? ''}
          assessmentType={assessmentType ?? ''}
          setLoading={setLoading}
          loading={loading}
          showModal={modalVisible}
          status={status}
        />

        <Dialog open={modalSentVisible} onClose={() => setIsOpen(false)} maxWidth='xs'>
          <DialogTitle>
            {emailSent + ` ${t('common:capitalize', { value: t('assessment:emailsSentSuccessfully') })}`}
          </DialogTitle>
          <DialogActions>
            <Button variant='contained' onClick={() => setModalSentVisible(false)} autoFocus>
              {t('common:capitalize', { value: t('common:close') })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={modalFinishVisible} onClose={() => setIsOpen(false)} maxWidth='xs'>
          {assessmentToFinishNotCompletedYet ? (
            <DialogTitle>{t('assessment:thereAreAssessmentsPending')}</DialogTitle>
          ) : (
            <DialogTitle>
              {t('common:capitalize', { value: t('assessment:doYouWantToFinishTheAssessment') })}
            </DialogTitle>
          )}
          <DialogActions>
            <Button onClick={() => setModalFinishVisible(false)}>
              {t('common:capitalize', { value: t('common:cancel') })}
            </Button>
            <Button variant='contained' onClick={() => handleFinishAssessment(assessmentId)} autoFocus>
              {t('common:capitalize', { value: t('common:finish') })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={modalStartVisible} onClose={() => setIsOpen(false)} maxWidth='xs'>
          <DialogTitle>{t('common:capitalize', { value: t('assessment:doYouWantToStartTheAssessment') })}</DialogTitle>
          <DialogActions>
            <Button onClick={() => setModalStartVisible(false)}>
              {t('common:capitalize', { value: t('common:cancel') })}
            </Button>
            <Button variant='contained' onClick={() => handleStartAssessment(assessmentId)} autoFocus>
              {t('common:capitalize', { value: t('common:start') })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={modalNewPeriodVisible} onClose={() => setIsOpen(false)} maxWidth='xs'>
          <DialogTitle>
            {t('common:capitalize', {
              value: t('assessment:doYouReallyWantToCreateANewAssessmentPeriodForTheseSkills')
            })}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setModalNewPeriodVisible(false)}>
              {t('common:capitalize', { value: t('common:cancel') })}
            </Button>
            <Button variant='contained' onClick={() => handleCreateAssessmentNewPeriod(assessmentId)} autoFocus>
              {t('common:capitalize', { value: t('common:create') })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={modalDeleteVisible} onClose={() => setIsOpen(false)} maxWidth='xs'>
          <DialogTitle>{t('common:capitalize', { value: t('assessment:doYouWantToDeleteTheAssessment') })}</DialogTitle>
          <DialogActions>
            <Button onClick={() => setModalDeleteVisible(false)}>
              {t('common:capitalize', { value: t('common:cancel') })}
            </Button>
            <Button variant='contained' onClick={() => handleDeleteAssessment(assessmentId)} autoFocus>
              {t('common:capitalize', { value: t('common:delete') })}
            </Button>
          </DialogActions>
        </Dialog>
      </Menu>
    </>
  );
}
