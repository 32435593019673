import * as Yup from 'yup';
//routes
import { PATH_BACKOFFICE } from 'routes/paths';
//services
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSkillsContext } from './contexts';
import { useLocation, useNavigate, useParams } from 'react-router';
// components
import { DateRangePicker, LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  createFilterOptions,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useAssessmentSkillService } from 'services/AssessmentSkillService';
import { assessmentSkillMode } from 'pages/assessments/skills/AssessmentAppraisees/typings';
import {
  AssessmentStatusEnum,
  AssessmentTypeEnum,
  KeyValueModel,
  SkillAutoCompleteCriteria
} from 'apis/backoffice-api/data-contracts';
import { useAssessmentNodeService } from 'services/AssessmentNodeService';
import { KeyValue } from 'types/KeyValue';
import { useAssesmentAction } from 'redux/actions/assessmentAction';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import moment from 'moment';

export type AssessmentSkillFormValues = {
  assessmentId?: string;
  title?: string | null;
  description?: string | null;
  dateRange: RangeInput<Date | string>;
  status: AssessmentStatusEnum | undefined;
  skills: KeyValue[] | KeyValueModel[] | null | undefined;
};

export type Props = {
  handleIsEdit: (value: boolean) => void;
};

export default function AssessmentSkillsForm(props: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setStepperActiveStep, setLoading } = useSkillsContext();
  const navigate = useNavigate();
  const { assessmentSkillCreate, assessmentSkillUpdate, assessmentSkillGetModel } = useAssessmentSkillService();
  const { assessmentId } = useParams();
  const [DefaultValue, setDefaultValue] = useState<AssessmentSkillFormValues>({
    assessmentId: undefined,
    title: undefined,
    description: undefined,
    dateRange: [null, null],
    status: AssessmentStatusEnum.NotStarted,
    skills: []
  });

  useEffect(() => {
    setLoading(true);
    setStepperActiveStep(0);
    setLoading(false);
    if (assessmentId !== undefined) {
      assessmentSkillGetModel(assessmentId).then((data) => {
        setDefaultValue({
          assessmentId: assessmentId ?? undefined,
          title: data?.title,
          description: data?.description,
          dateRange: [data?.start, data?.end],
          status: data?.status,
          skills: data?.skills as KeyValue[]
        });
        props.handleIsEdit(true);
      });      
    }
  }, []);

  const AssessmentSkillFormSchema = Yup.object().shape({
    title: Yup.string().required(t('common:capitalize', { value: t('common:field') })),
    description: Yup.string().required(t('common:capitalize', { value: t('common:field') })),
    dateRange: Yup.array().of(
      Yup.date()
        .min(new Date(new Date().setDate(new Date().getDate() - 1)), t('validations:theMinimunDateIsToday'))
        .required(t('validations:required'))
        .nullable()
        .typeError(`${t('validations:dateTypeError')}`)
    ),
    skills: Yup.array().min(1, t('common:capitalize', { value: t('common:chooseAtLeastOneOption') }))
  });
  const formik = useFormik<AssessmentSkillFormValues>({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: DefaultValue,
    validationSchema: AssessmentSkillFormSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        if (values.assessmentId !== undefined && values.assessmentId !== null) {
          // update
          assessmentSkillUpdate({
            assessmentId: values.assessmentId,
            title: values.title,
            description: values.description,
            start: moment(values.dateRange[0]?.toString()).format('yyyy-MM-DD'),
            end: moment(values.dateRange[1]?.toString()).format('yyyy-MM-DD'),
            status: values.status,
            skills: [...(values.skills ?? [])]
          }).then((data) => {
            enqueueSnackbar(`${t('common:capitalize', { value: t('skill:skillUpdatedWithSuccess') })}`, {
              variant: 'success'
            });
            setTimeout(() => {
              if (values.status === AssessmentStatusEnum.NotStarted) {
                navigate(PATH_BACKOFFICE.assessments.skills.appraisees, {
                  state: {
                    assessmentId: data?.assessmentId,                    
                    mode: assessmentSkillMode.EDIT,
                    assessmentStatus: data?.status
                  }
                });
              } else {
                navigate(PATH_BACKOFFICE.assessments.skills.list);
              }
            }, 2000);
          }).finally(() => {
            setIsSubmitting(false);
          });
        } else {
          // create
          assessmentSkillCreate({
            title: values.title,
            description: values.description,
            start: moment(values.dateRange[0]?.toString()).format('yyyy-MM-DD'),
            end: moment(values.dateRange[1]?.toString()).format('yyyy-MM-DD'),
            status: AssessmentStatusEnum.NotStarted,
            skills: [...(values.skills ?? [])]
          }).then((data) => {
            enqueueSnackbar(`${t('common:capitalize', { value: t('skill:skillCreatedWithSuccess') })}`, {
              variant: 'success'
            });
            setTimeout(() => {
              navigate(PATH_BACKOFFICE.assessments.skills.appraisees, {
                state: {
                  assessmentId: data?.assessmentId,
                  mode: assessmentSkillMode.NEW,
                  assessmentStatus: data?.status
                }
              });
            }, 2000);
          }).finally(() => {
            setIsSubmitting(false);
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
  const { handleSubmit } = formik;
  const { values, touched, errors, setFieldValue, getFieldProps } = formik;
  const { assessmentSkillAutocomplete } = useAssessmentSkillService();
  const [autoComplateCriteria, setAutoComplateCriteria] = useState<SkillAutoCompleteCriteria>({ skillName: '' });
  const [skills, setSkills] = useState<KeyValue[] | KeyValueModel[] | null | undefined>([]);
  const filter = createFilterOptions<KeyValue>();
  const { assessmentNodesDetail } = useAssessmentNodeService();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const fetchSkills = async (name: string) => {
    setAutoComplateCriteria({ skillName: name });
    let data = await assessmentSkillAutocomplete(autoComplateCriteria);
    if (data !== undefined) {
    
      let selectedSkillNames = Array.from(values.skills as ArrayLike<KeyValueModel>, sk => sk.value);
      data = data?.filter(d => !selectedSkillNames.includes(d.value))
      setSkills(data);
    }
  };

  const { createAssesmentSkillAction } = useAssesmentAction();
  createAssesmentSkillAction(
    getFieldProps(`title`).value ?? '',
    getFieldProps(`description`).value ?? '',
    getFieldProps(`skills`).value ?? []
  );

  const onChangeAutoCompleteValue = async (value: any) => {
    await fetchSkills(value);
  };

  const goToSkillAppraiseesGroup = async (assessmentId: string) => {
    setLoading(true);
    const assessmentNodes = await assessmentNodesDetail(assessmentId);
    if (assessmentNodes) {
      navigate(PATH_BACKOFFICE.assessments.skills.appraiseesGroup, {
        state: {
          assessmentId: assessmentNodes[0]?.assessmentId ?? '',
          mode: assessmentSkillMode.EDITED,
          assessmentNodes: assessmentNodes
        }
      });
    }
  }

  const handleNext = () => {
    formik.submitForm();
  }

  return (
    <Stack spacing={5}>
      <Box>
        <Card sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={10} md={10}>
              <Typography variant='h4' paragraph>
                {`${t('common:capitalize', {
                  value: assessmentId !== undefined ? t('skill:editSkill') : t('skill:newSkill')
                })}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid sm={12}>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                  <Grid item xs={12} marginTop={3}>
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      helperText={errors.title}
                      value={values.title ?? ''}
                      onChange={(event) => setFieldValue('title', event.target.value)}
                      label={`${t('common:capitalize', { value: t('common:title') })}`}
                      fullWidth
                      disabled={DefaultValue.status !== AssessmentStatusEnum.NotStarted}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop={3}>
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      helperText={errors.description}
                      value={values.description ?? ''}
                      onChange={(event) => setFieldValue('description', event.target.value)}
                      label={`${t('common:capitalize', { value: t('common:description') })}`}
                      fullWidth
                      multiline
                      minRows={3}
                      disabled={DefaultValue.status !== AssessmentStatusEnum.NotStarted}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop={3}>
                    <DateRangePicker
                      startText={t('assessment:assessmentInputInitialDate')}
                      endText={t('assessment:assessmentInputFinalDate')}
                      value={values.dateRange}
                      inputFormat='dd/MM/yyyy'
                      disabled={DefaultValue.status !== AssessmentStatusEnum.NotStarted}
                      onChange={(newValue) => {
                        setFieldValue('dateRange', newValue);
                      }}
                      renderInput={(startProps, endProps) => (
                        <>
                          <TextField
                            fullWidth
                            {...startProps}
                            error={Boolean(touched.dateRange && errors.dateRange)}
                            helperText={Array.from(new Set(errors.dateRange)).sort().join('')}
                            style={{ marginRight: 10 }}
                          />
                          <TextField
                            fullWidth
                            {...endProps}
                            error={Boolean(touched.dateRange && errors.dateRange)}
                            helperText={Array.from(new Set(errors.dateRange)).sort().join('')}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} marginTop={3}>
                    <Autocomplete
                      disabled={DefaultValue.status !== AssessmentStatusEnum.NotStarted}
                      value={values.skills ?? undefined}
                      fullWidth
                      noOptionsText={t('common:capitalize', { value: t('common:noOptions') })}
                      options={skills as KeyValue[]}
                      multiple
                      onChange={(event, value) => setFieldValue('skills', value)}
                      getOptionLabel={(option) => option.value ?? ''}
                      filterOptions={(options, params) => {
                        const filtered = filter(options as KeyValue[], params);
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            key: '',
                            value: `${inputValue}`
                          });
                        }
                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label={`${t('common:capitalize', { value: t('skill:skills') })}`}
                          helperText={errors.skills}
                          onChange={(event) => onChangeAutoCompleteValue(event.target.value)}
                          placeholder={`${t('common:capitalize', { value: t('skill:skills') })}`}
                        />
                      )}
                    />
                  </Grid>
                  <Box>
                    <Grid container spacing={3} justifyContent={'end'} marginTop={3}>
                      <Grid item xs={3} textAlign='right'>
                        <Button
                          variant='outlined'
                          size='medium'
                          sx={{ width: '100%' }}
                          onClick={() => {
                            window.location.href = PATH_BACKOFFICE.assessments.skills.root;
                          }}>
                          {t('common:capitalize', { value: t('common:cancel') })}
                        </Button>
                      </Grid>
                      <Grid item xs={3} textAlign='right'>
                        {DefaultValue.status === AssessmentStatusEnum.NotStarted ? (
                          <LoadingButton
                            size='medium'
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={() => handleNext()}
                            sx={{ minWidth: '210px' }}
                            loading={isSubmitting}>
                            {t('common:capitalize', { value: t('common:next') })}
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            size='medium'
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={() => goToSkillAppraiseesGroup(DefaultValue.assessmentId ?? '')}
                            sx={{ minWidth: '210px' }}
                            type='button'
                            loading={isSubmitting}>
                            {t('common:capitalize', { value: t('common:viewAppraisees') })}
                          </LoadingButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              </FormikProvider>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Stack>
  );
}
