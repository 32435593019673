import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Card,
  Grid,
  Typography,
  Checkbox,
  Stack
} from '@mui/material';

import { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify/icons-eva/close-outline';

// types
import { ResourceEmployee } from 'types/ResourceEmployee';

// services
import { useEffect } from 'react';

// components
import { View } from 'components/View';
import {
  AssessmentEmailTemplateTypeEnum,
  AssessmentStatusEnum,
  ResourceInfoModel
} from '../../../../apis/backoffice-api/data-contracts';
import { Loading } from 'components/Loading';
import AppraiserListEmail from './AppraiserListEmail';
import { useAssessmentPerformanceService } from 'services/AssessmentPerformanceService';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

type EmailModalProps = {
  assessmentName?: string;
  assessmentId: string;
  assessmentType?: string | null;
  showModal?: boolean;
  setLoading: (data: boolean) => void;
  loading: boolean;
  onClose?: () => void;
  status?: AssessmentStatusEnum;
};

export function EmailModal(props: EmailModalProps) {
  const { assessmentId, assessmentName, assessmentType, showModal, onClose, setLoading, loading, status } = props;
  const [selectedResources, setSelectedResources] = useState<ResourceInfoModel[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalVisibleNotStarted, setModalVisibleNotStarted] = useState<boolean>(false);
  const { assessmentSendemailtoappraisersList } = useAssessmentPerformanceService();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSendEmail = async () => {
    setLoading(true);
    
    
    const assessmentEmailTemplateType: AssessmentEmailTemplateTypeEnum =
      assessmentType == 'Performance'
        ? AssessmentEmailTemplateTypeEnum.ReminderPerformance
        : AssessmentEmailTemplateTypeEnum.ReminderSkills;

    await assessmentSendemailtoappraisersList(selectedResources ?? [], {
      assessmentId,
      assessmentEmailTemplateType
    })
      .then((data) => {
        setLoading(false);
        const sentTotal = data.data;
        if (sentTotal == 1)
          enqueueSnackbar(`${sentTotal} ${t('sendEmail:emailSentSuccessfully')}`, { variant: 'success' });
        else enqueueSnackbar(`${sentTotal} ${t('sendEmail:emailsSentSuccessfully')}`, { variant: 'success' });
      })
      .catch(() => {});
  };

  const openSendModal = () => {
    if (status != AssessmentStatusEnum.NotStarted) {
      setModalVisible(true);
    } else {
      setModalVisibleNotStarted(true);
    }
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={'xl'} open={!!showModal} onClose={onClose}>
        <DialogTitle>
          <div style={{ margin: '25px', marginLeft: '30px' }}>
            {t('sendEmail:header')} {assessmentName}
          </div>
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 24,
              color: (theme) => theme.palette.grey[500]
            }}>
            <Icon icon={closeOutline} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              background: '#DFE3E8',
              borderRadius: '8px',
              width: '100%',
              overflow: 'hidden'
            }}>
            <AppraiserListEmail
              assessmentId={assessmentId}
              selectedResources={selectedResources}
              setSelectedResources={setSelectedResources}
              status={status}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t('common:cancel')}</Button>
          <Button variant='contained' onClick={openSendModal} disabled={selectedResources.length == 0} autoFocus>
            {t('common:send')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalVisible} maxWidth='xs'>
        <DialogTitle>{t('sendEmail:confirmSendEmail')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setModalVisible(false)}>{t('common:cancel')}</Button>
          <Button variant='contained' onClick={() => handleSendEmail()} autoFocus>
            {t('common:send')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalVisibleNotStarted} maxWidth='xs'>
        <DialogTitle>{t('sendEmail:confirmSendEmailNotStarted')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setModalVisibleNotStarted(false)}>{t('common:cancel')}</Button>
          <Button variant='contained' onClick={() => handleSendEmail()} autoFocus>
            {t('common:send')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
