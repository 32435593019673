import { useSelector } from "react-redux";

// config
import { backofficeApiConfig } from "config";

// redux
import { AuthReducerState } from "redux/reducers/authReducer";

// apis
import { AssessmentModel, AssessmentModelArrayResultHandler, AssessmentModelResultHandler, AssessmentSkillListItemModel, AssessmentSkillListItemModelPaginationResult, AssessmentStatusEnum, BooleanResultHandler, KeyValueModel, PaginationDirection, SkillAutoCompleteCriteria, SkillAverageModelArrayResultHandler } from "apis/backoffice-api/data-contracts";

// types
import { BackofficeApi } from "apis/backoffice-api";
import { KeyValue } from "types/KeyValue";


export const useAssessmentSkillService = () => {
  const { accessToken } = useSelector((state: { auth: AuthReducerState }) => state.auth);

  const backofficeApi = new BackofficeApi({
    baseURL: backofficeApiConfig.baseURL,
    headers: { "Authorization": `bearer ${accessToken}` }
  });

  return {
    assessmentSkillList: async (limit?: number, offSet?: number, field?: string, order?: PaginationDirection, title?: string, skillNames?: string[], statusSearch?: AssessmentStatusEnum): Promise<AssessmentSkillListItemModelPaginationResult | undefined> =>
      backofficeApi.assessmentSkillListCreate({
        limit: limit ?? 20,
        offset: offSet ?? 1,
        field: field,
        order: order,
        skillNames: skillNames,
        title: title,
        status: statusSearch
      }).then((resp) => resp.data.data),

    startAssessmentSkill: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentStartSkillDetail(assessmentId).then((resp) => {
        return resp.data;
      }),

    assessmentFinishVerify: async (assessmentId: string): Promise<BooleanResultHandler> =>
      backofficeApi.assessmentFinishVerifyDetail(assessmentId).then((resp) => {
        return resp.data;
      }),

    finishAssessmentSkill: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentFinishSkillDetail(assessmentId).then((resp) => {
        return resp.data;
      }),


    assessmentSkillDelete: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentSkillDelete(assessmentId).then((resp) => resp),


    assessmentSkillCreate: async (assessment: AssessmentSkillListItemModel): Promise<AssessmentModel | undefined> =>
      backofficeApi.assessmentSkillCreate(assessment).then((data: AssessmentModelResultHandler | any) => data.data.value.data),

    assessmentSkillCreateNewPeriod: async (assessmentId: string): Promise<AssessmentModel | undefined> =>
      backofficeApi.assessmentNewPeriodSkillDetail(assessmentId).then((data: AssessmentModelResultHandler | any) => data.data.data),

    assessmentSkillUpdate: async (assessment: AssessmentSkillListItemModel): Promise<AssessmentModel | undefined> =>
      backofficeApi.assessmentSkillUpdate(assessment).then((data: AssessmentModelResultHandler | any) => data.data.data),

    assessmentSkillGetModel: async (assessmentId: string): Promise<AssessmentSkillListItemModel | undefined> =>
      backofficeApi.assessmentSkillDetail(assessmentId).then((data: AssessmentModelResultHandler | any) => data.data.data),

    assessmentSkillAutocomplete: async (criteria: SkillAutoCompleteCriteria): Promise<KeyValue[] | null | undefined> =>
      backofficeApi.assessmentSkillAutocompleteCreate(criteria).then((data) => data.data.data as KeyValue[]),

    assessmentSkillAnswerDetails: async (assessmentId: string, resourceEmployeeId: string, withSelfAnswer: boolean): Promise<SkillAverageModelArrayResultHandler | undefined> =>
      backofficeApi.assessmentSkillAnswerByAssessmentIdAndResourceIdAsyncDetail(assessmentId, resourceEmployeeId, withSelfAnswer).then((resp) => resp.data),

    assessmentSkillAnswersDetail: async (resourceEmployeeId: string): Promise<AssessmentModelArrayResultHandler | undefined> =>
      backofficeApi.assessmentSkillAnswersDetail(resourceEmployeeId).then((resp) => resp.data),
  }
}
