import { useEffect, useState } from 'react';

// material
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Stack,
  Card,
  Typography,
  Avatar,
  Autocomplete,
  TextField,
  ListItemIcon,
  ListItemText
} from '@mui/material';

// components
import SearchNotFound from 'components/SearchNotFound/SearchNotFound';
import Scrollbar from 'components/Scrollbar';
import {
  AssessmentNodeModel,
  AssessmentNodeStatusEnum,
  AssessmentNodeTypeEnum,
  ResourceInfoModel
} from 'apis/backoffice-api/data-contracts';
import { getFirstCharacter, capitalCaseWords } from 'utils/firstCharacter';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '@iconify/react';
import trashOutlined from '@iconify/icons-eva/trash-outline';
import { useTranslation } from 'react-i18next';

export type Props = {
  relationshipName: string;
  setAssessmentNodes: (data: AssessmentNodeModel[]) => void;
  assessmentNodes?: AssessmentNodeModel[];
  resourceInfos: ResourceInfoModel[];
  assessmentId: string;
  resourceId: string;
  isReadOnly: boolean;
};

export default function AppraiserList({
  relationshipName,
  setAssessmentNodes,
  assessmentNodes,
  resourceInfos,
  assessmentId,
  resourceId,
  isReadOnly
}: Props) {
  const [offSet, setOffset] = useState<number>(1);
  const [limit] = useState<number>(5);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());
  const [filteredAssessmentNodes, setFilteredAssessmentNodes] = useState<AssessmentNodeModel[]>();
  const [resourceInfosFiltered, setResourceInfosFiltered] = useState<ResourceInfoModel[]>(resourceInfos);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const defaultProps = {
    options: resourceInfosFiltered,
    getOptionLabel: (option: ResourceInfoModel) => `${option.firstName} ${option.lastName} (${option.email})` ?? ''
  };

  const listAssessmentNodes = () => {
    let newFilteredAssessmentNodesSet =
      assessmentNodes?.filter((assessmentNode) => {
        return (
          assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId &&
          assessmentNode?.type == relationshipName
        );
      }) ?? [];
    setTotalCount(newFilteredAssessmentNodesSet?.length ?? 0);
    newFilteredAssessmentNodesSet = newFilteredAssessmentNodesSet?.slice((offSet - 1) * limit, offSet * limit) ?? [];
    setFilteredAssessmentNodes(newFilteredAssessmentNodesSet);
  };

  const addResourceInfoToassessmentNode = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ResourceInfoModel | null
  ) => {
    if (newValue?.resourceId == resourceId) {
      setAutoCompleteKey(uuidv4());
      return;
    }

    const hasAppraiserAssessmentNode = !!assessmentNodes?.find(
      (assessmentNode) =>
        assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId &&
        assessmentNode.appraiserAssessmentParticipant?.resourceId == newValue?.resourceId
    );
    const typedNodeTypeString = relationshipName as keyof typeof AssessmentNodeTypeEnum;

    if (!hasAppraiserAssessmentNode)
      assessmentNodes = assessmentNodes?.concat([
        {
          type: AssessmentNodeTypeEnum[typedNodeTypeString],
          status: AssessmentNodeStatusEnum.NotStarted,
          assessmentId: assessmentId,
          appraiseeAssessmentParticipant: { assessmentId: assessmentId, resourceId: resourceId },
          appraiserAssessmentParticipant: { assessmentId: assessmentId, resourceId: newValue?.resourceId }
        }
      ]);

    setAssessmentNodes(assessmentNodes ?? []);
    setAutoCompleteKey(uuidv4());
  };

  const deleteAssessmentNode = (appraiserResourceId: string) => {
    const newAssessmentNodes = assessmentNodes?.filter(
      (assessmentNode) =>
        assessmentNode.appraiseeAssessmentParticipant?.resourceId != resourceId ||
        (assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId &&
          assessmentNode.appraiserAssessmentParticipant?.resourceId != appraiserResourceId)
    );

    if (!newAssessmentNodes) return;

    setAssessmentNodes(newAssessmentNodes ?? []);
  };

  useEffect(() => {
    (async () => {
      await listAssessmentNodes();
    })();
  }, [limit, offSet, assessmentNodes]);

  useEffect(() => {
    (async () => {
      listAssessmentNodes();
      setResourceInfosFiltered(resourceInfos?.filter((resourceInfo) => resourceInfo?.resourceId != resourceId));
    })();
  }, []);

  return (
    <Card sx={{ pt: 3 }}>
      {!isReadOnly && (
        <Autocomplete
          {...defaultProps}
          id='resourceInfoSearch'
          key={autoCompleteKey}
          autoComplete
          includeInputInList
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label={t('common:capitalize', { value: t('common:name') })} variant='outlined' />
          )}
          onChange={addResourceInfoToassessmentNode}
        />
      )}
      <TableContainer sx={{ marginTop: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:name') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:email') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:role') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:department') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:team') })}</TableCell>
              <TableCell align='left'>{t('common:capitalize', { value: t('common:branch') })}</TableCell>
              {!isReadOnly && <TableCell align='left' />}
            </TableRow>
          </TableHead>
          {totalCount > 0 ? (
            <TableBody>
              {resourceInfosFiltered
                .filter((resourceInfo) => {
                  return filteredAssessmentNodes?.filter((assessmentNode) => {
                    return resourceInfo.resourceId == assessmentNode?.appraiserAssessmentParticipant?.resourceId;
                  }).length;
                })
                .map((resourceInfo) => {
                  return (
                    <TableRow hover key={resourceInfo.resourceId}>
                      <TableCell align='left'>
                        <Stack direction='row' alignItems='center' spacing={2}>
                          {!!resourceInfo.profileImageUrl ? (
                            <Avatar alt={resourceInfo.firstName ?? ''} src={resourceInfo.profileImageUrl} />
                          ) : (
                            <Avatar>{getFirstCharacter(resourceInfo.firstName ?? '')}</Avatar>
                          )}
                          <Typography variant='subtitle2' noWrap>
                            {`${capitalCaseWords(resourceInfo?.firstName as string)} ${capitalCaseWords(
                              resourceInfo?.lastName ?? ''
                            )}`.trim()}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align='left'>{resourceInfo.email ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.role ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.department ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.team ?? '--'}</TableCell>
                      <TableCell align='left'>{resourceInfo.branch ?? '--'}</TableCell>

                      {!isReadOnly && (
                        <TableCell align='right'>
                          <ListItemIcon
                            onClick={() => deleteAssessmentNode(resourceInfo.resourceId ?? '')}
                            sx={{ cursor: 'pointer' }}>
                            <Icon icon={trashOutlined} width={20} height={20} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('common:capitalize', { value: t('common:remove') })}
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {resourceInfosFiltered.filter((resourceInfo) => {
                return assessmentNodes?.filter((assessmentNode) => {
                  return (
                    assessmentNode?.type == relationshipName &&
                    resourceInfo.resourceId == assessmentNode?.appraiserAssessmentParticipant?.resourceId
                  );
                }).length;
              }).length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell align='center' colSpan={8}>
                  <SearchNotFound />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[limit]}
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={offSet - 1}
        labelRowsPerPage='Rows'
        labelDisplayedRows={({ from, to, count }) => {
          return '' + from + '-' + to + `${t('common:TextPagination')}` + count;
        }}
        onPageChange={(e, page) => setOffset(page + 1)}
      />
    </Card>
  );
}
