// routes
import { PATH_BACKOFFICE } from 'routes/paths';
// hooks
import useSettings from 'hooks/useSettings';
import { useTranslation } from 'react-i18next';
// components
import { Container, Step, StepLabel, Stepper } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { Loading } from 'components/Loading';
import { useEffect, useState } from 'react';
import Page from 'components/Page';
import AssessmentSkillsForm from 'pages/assessments/skills/AssessmentSkillsForm';
import { AssessmentSkillsContextProvider, useSkillsContext } from './contexts/index';

export default function AssessmentSkillNew() {
  const { t } = useTranslation();
  const { stepper, activeStep, loading, setStepper } = useSkillsContext();
  const { themeStretch } = useSettings();
  const [isEdit, setIsEdit] = useState<boolean>()

  useEffect(() => {
    setStepper([
      t('stepperInfo', { ns: 'Skills' }),
      t('stepperGroup', { ns: 'Skills' }),
      t('Skills', { ns: 'Skills' })
    ]);
  }, []);

  const handleIsEdit = (value: boolean) => {
    setIsEdit(value);
  } 

  return (
    <Loading loading={loading}>
      <Page title={`HR Metrics | ${t('common:capitalize', { value: isEdit ? t('skill:editSkill') : t('skill:newSkill') })}`}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepper &&
              stepper.map((label: {} | null | undefined) => (
                <Step /*key={label}*/>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
          </Stepper>
          <HeaderBreadcrumbs
            heading={`${t('common:capitalize', { value: isEdit ? t('skill:editSkill') : t('skill:newSkill') })}`}
            links={[
              {
                name: `${t('common:capitalize', { value: t('skill:skill') })}`,
                href: PATH_BACKOFFICE.assessments.skills.root
              },
              { name: `${t('common:capitalize', { value: isEdit ? t('skill:editSkill') : t('skill:newSkill') })}` }
            ]}
          />
          <AssessmentSkillsForm handleIsEdit={handleIsEdit}/>
        </Container>
      </Page>
    </Loading>
  );
}
