import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ptBRCommon from './locales/pt/common.json';
import ptBRnBox from './locales/pt/nBox.json';
import ptBRValidatons from './locales/pt/validations.json';
import ptBRsendEmail from './locales/pt/sendEmail.json';
import ptBRAssessment from './locales/pt/assessment.json';
import ptBREmployee from './locales/pt/employee.json';
import ptBRSkill from './locales/pt/skill.json';
import ptBRAccount from './locales/pt/account.json';

import enUSCommon from './locales/en/common.json';
import enUSnBox from './locales/en/nBox.json';
import enUSValidatons from './locales/en/validations.json';
import enUSsendEmail from './locales/en/sendEmail.json';
import enUSAssessment from './locales/en/assessment.json';
import enUSEmployee from './locales/en/employee.json';
import enUSSkill from './locales/en/skill.json';
import enUSAccount from './locales/en/account.json';

import esESCommon from './locales/es/common.json';
import esESnBox from './locales/es/nBox.json';
import esESValidatons from './locales/es/validations.json';
import esESsendEmail from './locales/es/sendEmail.json';
import enESAssessment from './locales/es/assessment.json';
import enESEmployee from './locales/es/employee.json';
import enESSkill from './locales/es/skill.json';
import enESAccount from './locales/es/account.json';



// // ----------------------------------------------------------------------


const resources = {
  enUS: {
    common: enUSCommon,
    nBox: enUSnBox,
    validations: enUSValidatons,
    sendEmail: enUSsendEmail,
    assessment: enUSAssessment,
    employee: enUSEmployee,
    skill: enUSSkill,
    account:enUSAccount
  },
  ptBR: {
    common: ptBRCommon,
    nBox: ptBRnBox,
    validations: ptBRValidatons,
    sendEmail: ptBRsendEmail,
    assessment: ptBRAssessment,
    employee: ptBREmployee,
    skill: ptBRSkill,
    account:ptBRAccount
  },
  esES: {
    common: esESCommon,
    nBox: esESnBox,
    validations: esESValidatons,
    sendEmail: esESsendEmail,
    assessment: enESAssessment,
    employee: enESEmployee,
    skill: enESSkill,
    account:enESAccount
  }
}

export const setLanguage = (language: 'enUS' | 'ptBR' | 'esES') => {
  localStorage.setItem('i18nextLng', language)
  i18n.changeLanguage(language);
}

const lng = localStorage.getItem('i18nextLng') || 'enUS';

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: resources,
    lng: lng,
    fallbackLng: 'enUS',
    debug: false,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    }
  });

