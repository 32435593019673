import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Stepper(theme: Theme) {
  return {
    MuiStepper: {
      styleOverrides: {
        root: {
          marginBottom: '2em'
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.divider
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.divider,
          width: '0.5em',
        },
        text: {
          display: 'none'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontStyle: 'italic'
        }
      }
    }
  };
}
