import { AssessmentNodeStatusEnum, AssessmentNodeTypeEnum, AssessmentStatusEnum } from "apis/backoffice-api/data-contracts";


export enum assessmentPerformanceMode {
  NEW = 'NEW',
  EDIT = 'EDIT',
  EDITED = 'EDITED'
}

export interface locationAssessmentAppraiseesState {
  mode?: assessmentPerformanceMode
  assessmentId: string
  assessmentStatus: AssessmentStatusEnum,
  selfAssessmentNodes: assessmentNodes[]
}

export interface assessmentNodes {
  assessmentNodeId: string
  assessmentId: string
  appraiserAssessmentParticipantId: string
  appraiserAssessmentParticipant: {
    assessmentParticipantId: string
    assessmentId: string
    resourceId: string
  }
  appraiseeAssessmentParticipantId: string
  appraiseeAssessmentParticipant: {
    assessmentParticipantId: string
    assessmentId: string
    resourceId: string
  },
  type: AssessmentNodeTypeEnum
  status: AssessmentNodeStatusEnum
}

export interface locationAssessmentNodesState {
  selfAssessmentNodes: assessmentNodes[]
}
