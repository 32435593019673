import { useDispatch } from "react-redux";
import { userReducerState } from "../reducers/userReducer";

export function useUserAction() {
  const dispatch = useDispatch();

  return {
    fetchUserData: (userData: userReducerState) => {
      localStorage.setItem('companyDataStudioUrl', userData?.companyDataStudioUrl ?? '');
      dispatch({
        type: "FETCH_USER_DATA",
        data: {
          resourceId: userData?.resourceId,
          resourceEmployeeLoggedId: userData?.resourceEmployeeLoggedId,
          firstName: userData?.firstName,
          lastName: userData?.lastName ?? "",
          fullName: (`${userData?.firstName} ${userData?.lastName}`).trim(),
          type: userData.type,
          profileImageUrl: userData?.profileImageUrl,
          company: userData?.company,
          companyDataStudioUrl: userData?.companyDataStudioUrl
        }
      })
    }
  }
}