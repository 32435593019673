import { useSelector } from "react-redux";

// config
import { backofficeApiConfig } from "config";

// redux
import { AuthReducerState } from "redux/reducers/authReducer";

// apis
import { AssessmentNodeModel, AssessmentNodeTypeEnum } from "apis/backoffice-api/data-contracts";

// types
import { BackofficeApi } from "apis/backoffice-api";

export const useAssessmentNodeService = () => {
  const { accessToken } = useSelector((state: { auth: AuthReducerState }) => state.auth);

  const backofficeApi = new BackofficeApi({
      baseURL: backofficeApiConfig.baseURL,
      headers: { "Authorization": `bearer ${accessToken}` }
  });

  return {
      assessmentNodeImport: async (assessmentId: string, data: { fileUpload?: File | undefined }) =>
      backofficeApi.assessmentNodeUploadCreate(assessmentId, data),

      assessmentNodeSkillImport: async (assessmentId: string, data: { fileUpload?: File | undefined }): Promise<AssessmentNodeModel[] | null | undefined> =>
      backofficeApi.assessmentNodeUploadSkillCreate(assessmentId, data).then((resp) => resp.data.data),

      assessmentNodesDetail: async (assessmentId: string): Promise<AssessmentNodeModel[] | null | undefined> =>
      backofficeApi.assessmentNodeAssessmentDetail(assessmentId).then((resp) => resp.data.data),

      assessmentNodeSelfsDetail: async (assessmentId: string): Promise<AssessmentNodeModel[] | null | undefined> =>
      backofficeApi.assessmentNodeSelfsDetail(assessmentId).then((resp) => resp.data.data),

      assessmentNodesDetailByAssessmentIdAndType: async (assessmentId: string, type: AssessmentNodeTypeEnum): Promise<AssessmentNodeModel[] | null | undefined> =>
      backofficeApi.assessmentNodeAssessmentNodeTypeDetail(assessmentId, type).then((resp) => resp.data.data),

      assessmentNodesDetailByAssessmentIdAndAppraiseeId: async (assessmentId: string, appraiseeId: string): Promise<AssessmentNodeModel[] | null | undefined> =>
      backofficeApi.assessmentNodeAppraisersDetail(assessmentId, appraiseeId).then((resp) => resp.data.data),

      assessmentNodeCreate: async (assessmentId: string, data: AssessmentNodeModel[]): Promise<any> =>
      backofficeApi.assessmentNodeAssessmentNodesCreate({ assessmentId: assessmentId }, data).then((resp) => resp),

      assessmentNodeSkillCreate: async (assessmentId: string, data: AssessmentNodeModel[]): Promise<any> =>
      backofficeApi.assessmentNodeSkillAssessmentNodesCreate({ assessmentId: assessmentId }, data).then((resp) => resp),

      assessmentNodeAppraisersUpdate: async (assessmentId: string, data: AssessmentNodeModel[]): Promise<AssessmentNodeModel[] | null | undefined> =>
      backofficeApi.assessmentNodeAppraisersUpdate({ assessmentId: assessmentId }, data).then((resp) => resp.data.data),

      assessmentNodesCount: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentNodeCountAssessmentNodesDetail(assessmentId).then((resp) => resp),
  }
}
