import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import { alpha } from '@mui/material/styles';

// routes
import { PATH_BACKOFFICE } from 'routes/paths';

// hooks
import useIsMountedRef from 'hooks/useIsMountedRef';

// components
import { MIconButton } from 'components/@material-extend';
import MenuPopover from 'components/MenuPopover';
import { useAuthService } from 'services/AuthService';
import { useSelector } from 'react-redux';
import { userReducerState } from 'redux/reducers/userReducer';
import { getFirstCharacter, getTwoFirstCharacters, getFirstandLastNames } from 'utils/firstCharacter';
import { Avatar, Box, Button, Divider, MenuItem, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';

export default function AccountPopover() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { profileImageUrl, fullName, type } = useSelector((state: { user: userReducerState }) => state?.user);

  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();

  const { logout } = useAuthService();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const MENU_OPTIONS = [
    {
      label: t('common:settings'),
      icon: settings2Fill,
      linkTo: PATH_BACKOFFICE.settings.password.root
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      logout();
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}>
           
        {profileImageUrl ? (
          <Avatar alt={fullName} src={profileImageUrl} />
        ) : (
          <Avatar >{getTwoFirstCharacters(fullName)}</Avatar>
        )}
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle1' noWrap>
          {getFirstandLastNames(fullName)}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {t('common:capitalize', {value: t(`common:${type?.toLowerCase()}`)})}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}>
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color='inherit' variant='outlined' onClick={handleLogout}>
            {t('common:logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
