import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
export type assessmentReducerState = {
    title?: string;
    description?: string;
    instruction?: string;
    dateRange?: RangeInput<Date | string>;
    createRankingQuestion?: string
};

const initialState: assessmentReducerState = {
    title: undefined,
    description: undefined,
    instruction: undefined,
    dateRange: undefined,
    createRankingQuestion: undefined

};

const assessmentReducer = (
    state: assessmentReducerState = initialState,
    action: any
): assessmentReducerState => {
    switch (action.type) {
        case "CREATE_ASSESMENT": {
            return {
                ...state,
                ...action.data,
                assessmentCreated: true,
            };
        }
        default: {
            return state;
        }
    }
};

export default assessmentReducer;
