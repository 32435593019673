import { useDispatch } from "react-redux";
import { DateTime } from "luxon";

export function useAuthAction() {
  const dispatch = useDispatch();

  return {
    loginAction: (accessToken: string, refreshToken: string, expiresAt: DateTime) => {
      dispatch({
        type: "LOGIN",
        data: {
          accessToken,
          refreshToken,
          expiresAt: expiresAt.toISO(),
        },
      });
    },
    logoutAction: () => {
      dispatch({ type: "LOGOUT" });
    },
  };
}
