import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      defaultProps: {
      },

      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          padding: '0 3px'
        }
      }
    }
  };
}
