//services
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
// components
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Theme, Toolbar, useTheme, MenuItem, Button, Autocomplete, TextField, Chip } from '@mui/material';
import { useSkillsContext } from '../contexts/index';
import { TextInput } from 'components/TextInput';
import { AssessmentStatusEnum, KeyValueModel, SkillAutoCompleteCriteria } from 'apis/backoffice-api/data-contracts';
import { useAssessmentSkillService } from 'services/AssessmentSkillService';
import { KeyValue } from 'types/KeyValue';

export type Props = {
  handleOnChangeValue: (value: string | KeyValue[] | KeyValueModel[], prop: string) => void;
  handleOnSearch: () => void;
  handleClearForm: () => void;
  titleSearch: string;
  skillsSearch: KeyValue[] | KeyValueModel[] | null | undefined;
  isSubmitting: boolean;
  statusSearch: AssessmentStatusEnum | undefined;
  statusInfo?: KeyValueModel[];
};

export default function AssessmentSkillListFilter(props: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setStepperActiveStep, setLoading } = useSkillsContext();
  const theme = useTheme();
  const styles = useStyles(theme);

  const [searchedSkills, setSearchedSkills] = useState<string[]>([]);
  const { assessmentSkillAutocomplete } = useAssessmentSkillService();
  const [autoComplateCriteria, setAutoComplateCriteria] = useState<SkillAutoCompleteCriteria>({ skillName: '' });
  const [skills, setSkills] = useState<KeyValue[] | KeyValueModel[] | null | undefined>([]);

  useEffect(() => {
    setLoading(true);
    setStepperActiveStep(0);
    setLoading(false);
  }, []);

  const translateAssessmentStatus = (status: AssessmentStatusEnum) => {
    switch (status) {
      case AssessmentStatusEnum.Started:
        return t('common:capitalize', { value: t('assessment:assessmentStatusStarted') });
      case AssessmentStatusEnum.NotStarted:
        return t('common:capitalize', { value: t('assessment:assessmentStatusNotStarted') });
      case AssessmentStatusEnum.Completed:
        return t('common:capitalize', { value: t('assessment:assessmentStatusCompleted') });
    }
  };

  const onChangeAutoCompleteValue = async (value: any) => {
    await fetchSkills(value);
  };

  const fetchSkills = async (name: string) => {
    setAutoComplateCriteria({ skillName: name });
    let data = await assessmentSkillAutocomplete(autoComplateCriteria);
    if (data !== undefined) {
    
      let selectedSkillNames = Array.from(props.skillsSearch as ArrayLike<KeyValueModel>, sk => sk.value);

      data = data?.filter(d => !selectedSkillNames.includes(d.value))
      setSkills(data);
    }
  };


  return (
    <Toolbar style={styles.toolbar}>
      <Grid container>
        <Grid item xs={12}>
          <Card style={styles.card}>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextInput
                      fullWidth
                      label={t('common:capitalize', { value: t('common:title') })}
                      value={props.titleSearch}
                      onChange={(event) => props.handleOnChangeValue(event.target.value, 'titleSearch')}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') props.handleOnSearch();
                      }}
                    />

                    <Autocomplete
                      defaultValue={props.skillsSearch ?? undefined}
                      value={props.skillsSearch ?? undefined}
                      fullWidth
                      noOptionsText={t('common:capitalize', { value: t('common:noOptions') })}
                      options={skills as KeyValue[]}
                      onChange={(event, value) => props.handleOnChangeValue(value, 'skillSearch')}
                      multiple
                      renderTags={(value, getTagProps) => {
                        const numTags = (value.length);
                        const limitTags = 1;
                        return (
                          <>
                            {value.slice(0, limitTags).map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                key={index}
                                label={option.value}
                              />
                            ))}
                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      getOptionLabel={(option) => option.value ?? ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label={`${t('common:capitalize', { value: t('skill:skills') })}`}
                          onChange={(event) => onChangeAutoCompleteValue(event.target.value)}
                          placeholder={`${t('common:capitalize', { value: t('skill:skills') })}`}
                        />
                      )}
                    />

                    {/* <Autocomplete
                      multiple
                      options={searchedSkills}
                      getOptionLabel={(option) => option}
                      filterSelectedOptions
                      fullWidth
                      renderInput={(params) => (
                        <TextInput
                          {...params}
                          variant='outlined'
                          label={`${t('common:capitalize', { value: t('skill:skills') })}`}
                          value={props.skillSearch}
                          // onChange={(event) => props.handleOnChangeValue(event.target.value, 'skillSearch')}
                          onChange={(event) => onChangeAutoCompleteValue(event.target.value)}
                          placeholder={`${t('common:capitalize', { value: t('skill:skills') })}`}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') props.handleOnSearch();
                          }}
                        />
                      )}
                    /> */}

                    <TextInput
                      onChange={(event) => props.handleOnChangeValue(event.target.value, 'statusSearch')}
                      value={props.statusSearch}
                      fullWidth
                      label={t('common:capitalize', { value: t('common:status') })}
                      select>
                      <MenuItem value='any' key='Any'>
                        <em>{t('common:capitalize', { value: t('common:any') })}</em>
                      </MenuItem>
                      {props.statusInfo?.map((option) => (
                        <MenuItem key={option.key ?? ''} value={option.value ?? ''}>
                          {translateAssessmentStatus(option.value as AssessmentStatusEnum)}
                        </MenuItem>
                      ))}
                    </TextInput>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ pt: 3 }} display='flex' alignItems='flex-end' justifyContent='flex-end'>
                <Box sx={{ pt: 3 }} display='flex' alignItems='flex-end' justifyContent='flex-end' paddingRight={1}>
                  <Button onClick={() => props.handleClearForm()} sx={{ minWidth: '150px' }} variant='outlined'>
                    {t('common:clear')}
                  </Button>
                </Box>
                <LoadingButton
                  type='submit'
                  sx={{ minWidth: '150px' }}
                  onClick={() => props.handleOnSearch()}
                  variant='contained'
                  loading={props.isSubmitting}>
                  {t('common:capitalize', { value: t('common:search') })}
                </LoadingButton>
              </Box>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

const useStyles = (theme: Theme) => ({
  toolbar: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    margin: theme.spacing(0, 0, 3, 0)
  },
  card: {
    padding: theme.spacing(3, 3, 3, 3)
  }
});
