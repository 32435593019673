import { capitalize } from "lodash";

export function getFirstCharacter(name?: string) {
  return capitalize(name && name.charAt(0));
}

export function getFirstandLastNames(FullName?: string) {
  const Capname = capitalCaseWords(FullName?.trim() ?? '');
  const splitOnSpace = Capname.split(' ');
  var num = splitOnSpace.length;
  
  return splitOnSpace[0] + " " + splitOnSpace[num-1];
}


export function getTwoFirstCharacters(name?: string) {
  const Capname = capitalCaseWords(name?.trim() ?? '');
  const splitOnSpace = Capname.split(' ');
  var num = splitOnSpace.length;
  
  return name && name.charAt(0) + splitOnSpace[num-1].charAt(0);
}


export function capitalCaseWords(str: string) {
  if(!str || str == "")
    return "";

  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
 }