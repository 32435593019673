import { useUserAction } from "redux/actions/userAction";
import { BooleanResultHandler, ChangePasswordCriteria } from "apis/backoffice-api/data-contracts";
import { backofficeApiConfig } from "config";
import { BackofficeApi } from "apis/backoffice-api";
import { useSelector } from "react-redux";
import { AuthReducerState } from "redux/reducers/authReducer";

export const useUserService = () => {
  const { accessToken } = useSelector((state: { auth: AuthReducerState }) => state.auth);
  const { fetchUserData } = useUserAction();

  const backofficeApi = new BackofficeApi({
    baseURL: backofficeApiConfig.baseURL,
    headers: { "Authorization": `bearer ${accessToken}` }
  });

  return {

    userMeList: async (): Promise<void> =>
      backofficeApi.userMeList().then((resp) => {

        fetchUserData({          
          resourceId: resp.data.data?.resourceId ?? "",
          resourceEmployeeLoggedId: resp.data.data?.resourceEmployeeId ?? "",
          firstName: resp.data.data?.personalInfo?.firstName ?? "",
            lastName: resp.data.data?.personalInfo?.lastName ?? "",
            fullName: (`${resp.data.data?.personalInfo?.firstName} ${resp.data.data?.personalInfo?.lastName}`).trim(),
            type: resp.data.data?.type,
            profileImageUrl: resp.data.data?.personalInfo?.profileImageUrl ?? '',
            company: resp.data.data?.company?.name ?? '',
            companyDataStudioUrl: resp.data.data?.company?.dataStudioUrl ?? ''
          }
        );

      }),

    userChangePasswordCreate: async (data: ChangePasswordCriteria): Promise<BooleanResultHandler> =>
      backofficeApi.userChangePasswordCreate(data).then((resp) => resp?.data),
  }
}
