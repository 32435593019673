import { useRef, useState } from 'react';
import clsx from 'clsx';
// material
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import PopoverMenu from '../../components/PopoverMenu';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'enUS',
    label: 'English',        
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 'esES',
    label: 'Español',
    icon: '/static/icons/ic_flag_es.svg'
  },
  {
    value: 'ptBR',
    label: 'Português',
    icon: '/static/icons/ic_flag_pt_br.svg'
  } 
];

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  btnLang: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    // @ts-ignore
    backgroundColor: theme.palette.background.selected
  }
}));

export default function LanguagePopover() {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage);

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then();
    setOpen(false);
  };
  // const anchorRef = useRef(null);
  // const [open, setOpen] = useState(false);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        className={clsx(classes.btnLang, { [classes.isSelected]: isOpen })}>
        <img
          // @ts-ignore
          src={currentLang?.icon}
          // @ts-ignore
          alt={currentLang?.label}
          style={{ height: '22px', width: '22px' }}
        />
      </MIconButton>

      <PopoverMenu width={200} open={isOpen} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === langStorage}
              onClick={() => handleChangeLanguage(option.value)}
              sx={{ py: 1, px: 2.5 }}>
              <ListItemIcon>
                <Box
                  component='img'
                  alt={t(`language.${option.value}`)}
                  src={option.icon}
                  style={{ height: '20px', width: '20px' }}
                />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </Box>
      </PopoverMenu>
    </>
    // <>
    //   <MIconButton
    //     ref={anchorRef}
    //     onClick={() => setOpen(true)}
    //     sx={{
    //       padding: 0,
    //       width: 44,
    //       height: 44,
    //       ...(open && { bgcolor: 'action.selected' })
    //     }}>
    //     <img src={LANGS[0].icon} alt={LANGS[0].label} />
    //   </MIconButton>

    //   <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
    //     <Box sx={{ py: 1 }}>
    //       {LANGS.map((option) => (
    //         <MenuItem
    //           key={option.value}
    //           selected={option.value === LANGS[0].value}
    //           onClick={() => setOpen(false)}
    //           sx={{ py: 1, px: 2.5 }}>
    //           <ListItemIcon>
    //             <Box component='img' alt={option.label} src={option.icon} />
    //           </ListItemIcon>
    //           <ListItemText primaryTypographyProps={{ variant: 'body2' }}>{option.label}</ListItemText>
    //         </MenuItem>
    //       ))}
    //     </Box>
    //   </MenuPopover>
    // </>
  );
}
