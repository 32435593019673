import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';

// guards
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';

// components
import LoadingScreen from 'components/LoadingScreen';
import AssessmentResourceRelationsListPerformance from 'pages/assessments/performance/AssessmentResource/AssessmentResourceRelationsList';
import AssessmentResourceRelationsListSkill from 'pages/assessments/skills/AssessmentResource/AssessmentResourceRelationsList';
import NBoxResourceRelationsList from 'pages/results/nbox/NBoxPeopleGroup/ResourceRelationsList';

import { NBoxContextProvider } from 'contexts/NBoxContext';
import NBoxCreate from 'pages/results/nbox/InfoForm';
import NBoxList from 'pages/results/nbox/List';
import NBoxQuadrants from 'pages/results/nbox/NBoxQuadrants';
import PositionDetail from 'pages/results/nbox/PositionDetails';
import { AssessmentContextProvider } from 'pages/assessments/performance/providers/ContextProvider';
import { AssessmentSkillsContextProvider } from 'pages/assessments/skills/providers/ContextProvider';
import AssessmentSkilList from 'pages/assessments/skills/AssessmentSkillList';
import AssessmentSkillNew from 'pages/assessments/skills/AssessmentSkillNew';
import { AppraiseesContextProvider } from 'pages/results/nbox/providers/ContextProvider';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Navigate to='/resources/employee' replace /> },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'new-password', element: <NewPassword /> },

      ]
    },

    {
      path: 'settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard >
      ),
      children: [
        {
          path: 'password',
          element:
            <UserAccount />
        },
      ]
    },

    // Resources Routes
    {
      path: 'resources',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'employee',
          children: [
            { path: '', element: <ResourceEmployeeList /> },
            { path: 'list', element: <ResourceEmployeeList /> },
            { path: 'new', element: <ResourceEmployeeCreate /> },
            { path: 'edit/:resourceEmployeeId', element: <ResourceEmployeeEdit /> }
          ]
        }
      ]
    },

    // Assessments Routes
    {
      path: 'assessments',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'performance',
          children: [
            { path: '', element: <AssessmentPerformanceList /> },
            { path: 'list', element: <AssessmentPerformanceList /> },
            { path: 'new', element: <AssessmentContextProvider><AssessmentPerformanceNew /></AssessmentContextProvider> },
            { path: 'edit/:resourceEmployeeId', element: <AssessmentContextProvider><AssessmentPerformanceEdit /></AssessmentContextProvider> },
            { path: 'appraisees', element: <AssessmentContextProvider><AssessmentAppraisees /></AssessmentContextProvider> },
            { path: "appraisees/group", element: <AssessmentResourceRelationsListPerformance /> },
            { path: "reports/:assessmentId", element: <ResultReportList /> }
          ]
        },
        {
          path: 'Skills',
          children: [
            { path: '', element: <AssessmentSkilList /> },
            { path: 'list', element: <AssessmentSkilList /> },
            { path: 'new', element: <AssessmentSkillNew /> },
            { path: 'edit/:assessmentId', element: <AssessmentSkillNew /> },
            { path: 'appraisees', element: <AssessmentSkillsContextProvider><AssessmentSkillAppraisees /></AssessmentSkillsContextProvider> },
            { path: "appraisees/group", element: <AssessmentResourceRelationsListSkill /> }
          ]
        },
        {
          path: 'profile',
          children: [
            { path: '', element: <Profile /> },
            { path: ':resourceEmployeeId', element: <Profile /> }
          ]
        },
      ]
    },

    // Results Routes
    {
      path: 'results',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'performance',
          children: [
            { path: '', element: <PerformanceDashboard /> },
            { path: 'performanceDashboard', element: <PerformanceDashboard /> },
           ]
        },
        // {
        //   path: 'nBox',
        //   children: [
        //     {
        //       path: '',
        //       element: (
        //         <NBoxContextProvider>
        //           <NBoxList />
        //         </NBoxContextProvider>
        //       )
        //     },
        //     { path: 'add-persons', element: <AppraiseesContextProvider><NBoxAddPeople /></AppraiseesContextProvider> },
        //     { path: "add-persons/group", element: <NBoxResourceRelationsList /> },
        //     {
        //       path: 'new',
        //       children: [
        //         {
        //           path: '',
        //           element: (
        //             <NBoxContextProvider>
        //               <NBoxCreate />
        //             </NBoxContextProvider>
        //           )
        //         },
        //       ]
        //     },
        //     {
        //       path: 'edit',
        //       children: [
        //         {
        //           path: ':profilingId',
        //           element: (
        //             <NBoxContextProvider>
        //               <NBoxCreate />
        //             </NBoxContextProvider>
        //           )
        //         }
        //       ]
        //     },
        //     {
        //       path: 'quadrant/:profilingId',
        //       element: (
        //         <NBoxContextProvider>
        //           <NBoxQuadrants />
        //         </NBoxContextProvider>
        //       )
        //     },
        //     {
        //       path: 'quadrant/position/:position',
        //       element: (
        //         <NBoxContextProvider>
        //           <PositionDetail />
        //         </NBoxContextProvider>
        //       )
        //     }
        //   ]
        // }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> }
      ]
    },
    { path: '*', element: <Navigate to='/404' replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const NewPassword = Loadable(lazy(() => import('../pages/authentication/NewPassword')));

// Resources
const ResourceEmployeeList = Loadable(lazy(() => import('../pages/resources/employees/ResourceEmployeeList')));
const ResourceEmployeeCreate = Loadable(lazy(() => import('pages/resources/employees/ResourceEmployeeCreate')));
const ResourceEmployeeEdit = Loadable(lazy(() => import('../pages/resources/employees/ResourceEmployeeEdit')));

//nbox
const ProfilingList = Loadable(lazy(() => import('../pages/results/nbox/List/index')));

// Assessments
const AssessmentPerformanceList = Loadable(
  lazy(() => import('../pages/assessments/performance/AssessmentPerformanceList'))
);
const AssessmentPerformanceNew = Loadable(
  lazy(() => import('../pages/assessments/performance/AssessmentPerformanceNew'))
);
const AssessmentPerformanceEdit = Loadable(
  lazy(() => import('../pages/assessments/performance/AssessmentPerformanceEdit'))
);
const AssessmentAppraisees = Loadable(
  lazy(() => import('../pages/assessments/performance/AssessmentAppraisees/AssessmentAppraisees'))
);

const AssessmentSkillAppraisees = Loadable(
  lazy(() => import('../pages/assessments/skills/AssessmentAppraisees/AssessmentAppraisees'))
);

const NBoxAddPeople = Loadable(
  lazy(() => import('../pages/results/nbox/NBoxAddPeople/Appraisees'))
);

//Result reports
const ResultReportList = Loadable(
  lazy(() => import('../pages/assessments/performance/reports/ResultReportList'))
);
const Profile = Loadable(lazy(() => import('../pages/assessments/profile/Profile')));

// Setting
const UserAccount = Loadable(lazy(() => import('../pages/settings/UserAccount')));
const PerformanceDashboard = Loadable(lazy(() => import('../pages/dashboard/PerformanceDashboard')));

// Maina
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
