import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// typings
import { assessmentSkillMode } from '../AssessmentAppraisees/typings';

// material
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

// routes
import { PATH_BACKOFFICE } from 'routes/paths';

// hooks
import useSettings from 'hooks/useSettings';

// services
import { useResourceEmployeeService } from 'services/ResourceEmployeeService';

// utils
import { getFirstCharacter, capitalCaseWords } from 'utils/firstCharacter';

// apis
import {
  AssessmentNodeModel,
  AssessmentNodeStatusEnum,
  AssessmentNodeTypeEnum,
  AssessmentPerformanceNextModel,
  AssessmentStatusEnum,
  KeyValueModel,
  ResourceEmployeeModel,
  ResourceInfoModel,
  ResourceModel,
  ResourceNodeTypeEnum,
  ResourceStatusEnum
} from 'apis/backoffice-api/data-contracts';

// components
import SearchNotFound from 'components/SearchNotFound/SearchNotFound';
import Page from 'components/Page';
import Label from 'components/Label';
import Scrollbar from 'components/Scrollbar';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { Loading } from 'components/Loading';
import { useResourceService } from 'services/Resource';
import {
  AssessmentResourceFilterForm,
  AssessmentResourceFilterFormInitialValues,
  AssessmentResourceFilterFormValues
} from 'pages/assessments/skills/AssessmentResource/AssessmentResouceFilterForm';
import { AssessmentResourceActionMenu } from 'pages/assessments/skills/AssessmentResource/components/AssessmentResourceActionMenu';
import { AssessmentRelationshipModal } from 'pages/assessments/skills/AssessmentResource/components/AsessmentResourceModal';
import { useAssessmentPerformanceService } from 'services/AssessmentPerformanceService';
import { LoadingButton } from '@mui/lab';

import { locationAssessmentNodesState } from '../AssessmentAppraisees/typings';
import { useTranslation } from 'react-i18next';
import { useAssessmentNodeService } from 'services/AssessmentNodeService';

export const assessmentNodesMock = [
  {
    assessmentNodeId: '',
    assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
    appraiserAssessmentParticipantId: '',
    appraiserAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRVzItHasDilp7tZlhgc4qi'
    },
    appraiseeAssessmentParticipantId: '',
    appraiseeAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRVzItHasDilp7tZlhgc4qi'
    },
    type: AssessmentNodeTypeEnum.Self,
    status: AssessmentNodeStatusEnum.NotStarted
  },
  {
    assessmentNodeId: '',
    assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
    appraiserAssessmentParticipantId: '',
    appraiserAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSS0TlQntgcuKvs9d47lGk8s'
    },
    appraiseeAssessmentParticipantId: '',
    appraiseeAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRVzItHasDilp7tZlhgc4qi'
    },
    type: AssessmentNodeTypeEnum.Peer,
    status: AssessmentNodeStatusEnum.NotStarted
  },
  {
    assessmentNodeId: '',
    assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
    appraiserAssessmentParticipantId: '',
    appraiserAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSS0TlQntgcuKvs9d47lGk8s'
    },
    appraiseeAssessmentParticipantId: '',
    appraiseeAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSS0TlQntgcuKvs9d47lGk8s'
    },
    type: AssessmentNodeTypeEnum.Self,
    status: AssessmentNodeStatusEnum.NotStarted
  },
  {
    assessmentNodeId: '',
    assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
    appraiserAssessmentParticipantId: '',
    appraiserAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSSLyrZUEF69Kn3I3qgVf46A'
    },
    appraiseeAssessmentParticipantId: '',
    appraiseeAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSSLyrZUEF69Kn3I3qgVf46A'
    },
    type: AssessmentNodeTypeEnum.Self,
    status: AssessmentNodeStatusEnum.NotStarted
  },
  {
    assessmentNodeId: '',
    assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
    appraiserAssessmentParticipantId: '',
    appraiserAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSQBDauewPJUhBU6ZLhmz7Td'
    },
    appraiseeAssessmentParticipantId: '',
    appraiseeAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSQBDauewPJUhBU6ZLhmz7Td'
    },
    type: AssessmentNodeTypeEnum.Self,
    status: AssessmentNodeStatusEnum.NotStarted
  },
  {
    assessmentNodeId: '',
    assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
    appraiserAssessmentParticipantId: '',
    appraiserAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRVzItHasDilp7tZlhgc4qi'
    },
    appraiseeAssessmentParticipantId: '',
    appraiseeAssessmentParticipant: {
      assessmentParticipantId: '',
      assessmentId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRz-PMOLXpKaSpLucnaRMjc',
      resourceId: 'Y3AhN2EhI1pGQ113SlpxJGNwITdhISNaRkNdd0pacSRVzItHasDilp7tZlhgc4qi'
    },
    type: AssessmentNodeTypeEnum.Self,
    status: AssessmentNodeStatusEnum.NotStarted
  }
];

export default function AssessmentResourceRelationsList() {
  const navigate = useNavigate();
  const locationState = useLocation().state as locationAssessmentNodesState;
  const { assessmentNodeSkillCreate } = useAssessmentNodeService();
  const { resourceResourceInfoAllList } = useResourceService();
  const { themeStretch } = useSettings();
  const [assessmentNodes, setAssessmentsNodes] = useState<AssessmentNodeModel[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [offSet, setOffset] = useState<number>(1);
  const [limit] = useState<number>(20);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [roles, setRoles] = useState<KeyValueModel[]>([]);
  const [department, setDepartment] = useState<KeyValueModel[]>([]);
  const [status, setStatus] = useState<KeyValueModel[]>([]);
  const [filter, setFilter] = useState<AssessmentResourceFilterFormValues>(AssessmentResourceFilterFormInitialValues);
  const [filtros, setFiltros] = useState<ResourceInfoModel[]>([]);
  const [showNodeModal, setShowModal] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [resourceInfos, setResourceInfos] = useState<ResourceInfoModel[]>([]);
  const [resourceInfosFiltered, setResourceInfosFiltered] = useState<ResourceInfoModel[]>([]);

  const [assessmentId, setAssessmentId] = useState<string>('');

  const { t } = useTranslation();

  const filterResourceInfos = () => {
    const listaResources: ResourceInfoModel[] = [];

    setResourceInfosFiltered(
      resourceInfos.filter((resourceInfo) => {
        return assessmentNodes?.filter((assessment) => {
          return resourceInfo.resourceId === assessment?.appraiseeAssessmentParticipant?.resourceId;
        }).length;
      })
    );
  };

  useEffect(() => {
    if (locationState.assessmentNodes) {
      setAssessmentId(locationState.assessmentNodes[0].assessmentId);
      setAssessmentsNodes(locationState.assessmentNodes);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await listAssessmentNodes();
    })();
  }, [limit, offSet, resourceInfosFiltered, filter]);

  useEffect(() => {
    (async () => {
      await filterResourceInfos();
    })();
  }, [assessmentNodes]);

  useEffect(() => {
    (async () => {
      filterResourceInfos();
    })();
  }, [resourceInfos]);

  const listAssessmentNodes = () => {
    let filteredList = resourceInfosFiltered.filter(
      (resourceInfoFiltered) =>
        resourceInfoFiltered?.firstName?.toLowerCase().includes(filter.name.toLowerCase()) ||
        resourceInfoFiltered.lastName?.toLowerCase().includes(filter.name.toLowerCase()) ||
        resourceInfoFiltered.branch?.toLowerCase().includes(filter.name.toLowerCase()) ||
        resourceInfoFiltered.department?.toLowerCase().includes(filter.name.toLowerCase()) ||
        resourceInfoFiltered.role?.toLowerCase().includes(filter.name.toLowerCase()) ||
        resourceInfoFiltered.team?.toLowerCase().includes(filter.name.toLowerCase()) ||
        resourceInfoFiltered.firstName
          ?.toLowerCase()
          .concat(' ' + resourceInfoFiltered.lastName?.toLowerCase())
          .includes(filter.name.toLowerCase())
    );
    setTotalCount(filteredList?.length ?? 0);
    filteredList = filteredList?.slice((offSet - 1) * limit, offSet * limit) ?? [];
    setFiltros(filteredList);
  };

  const fetchResourceInfo = async () => {
    await resourceResourceInfoAllList()
      .then((data) => {
        setResourceInfos(data ?? []);
      })
      .catch((errorValue) => {
        console.log(errorValue);
      });
  };

  const handleOpenRelatioshipModal = async (assementNodes?: AssessmentNodeModel[]) => {
    setAssessmentsNodes(assessmentNodes ?? []);
    setShowModal(true);
  };

  const handleCloseRelationshipModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchResourceInfo();
      setLoading(false);
    })();
  }, []);

  const finalizar = async () => {
    setIsSubmitting(true);
    await assessmentNodeSkillCreate(assessmentId, assessmentNodes)
      .then(() => navigate(PATH_BACKOFFICE.assessments.skills.root))
      .catch((erro) => console.log(erro));
    setIsSubmitting(false);
  };

  const EditAppraisees = async () => {
    navigate(PATH_BACKOFFICE.assessments.skills.appraisees, {
      state: {
        assessmentId: assessmentId ?? '',
        mode: assessmentSkillMode.EDITED,
        assessmentNodes: assessmentNodes,
        assessmentStatus:
          assessmentNodes.length > 0
            ? assessmentNodes[0].assessment !== undefined
              ? assessmentNodes[0].assessment.status
              : AssessmentStatusEnum.NotStarted
            : AssessmentStatusEnum.NotStarted
      }
    });
  };

  return (
    <Loading loading={loading}>
      <Page title='HR Metrics | Resources'>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={`${t('common:capitalize', { value: t('skill:assessmentGroup') })}`}
            links={[
              {
                name: t('common:capitalize', { value: t('skill:skill') }),
                href: PATH_BACKOFFICE.assessments.skills.root
              },
              {
                name: t('common:capitalize', { value: t('skill:editSkill') }),
                href: PATH_BACKOFFICE.assessments.skills.edit + '/' + (assessmentId ?? '')
              },
              { name: t('common:capitalize', { value: t('skill:addPeople') }) },
              { name: t('common:capitalize', { value: t('skill:assessmentGroup') }) }
            ]}
          />
          <Card sx={{ pt: 3 }}>
            <Scrollbar>
              <TableContainer>
                <AssessmentResourceFilterForm onSearch={setFilter} />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>{`${t('common:capitalize', { value: t('common:name') })}`}</TableCell>
                      <TableCell align='left'>{`${t('common:capitalize', { value: t('common:email') })}`}</TableCell>
                      <TableCell align='center'>{`${t('common:capitalize', {
                        value: t('common:directManager')
                      })}`}</TableCell>
                      <TableCell align='center'>{`${t('common:capitalize', {
                        value: t('common:indirectManager')
                      })}`}</TableCell>
                      <TableCell align='center'>{`${t('common:capitalize', {
                        value: t('common:subordinate')
                      })}`}</TableCell>
                      <TableCell align='center'>{`${t('common:capitalize', { value: t('common:peer') })}`}</TableCell>
                      <TableCell align='center'>{`${t('common:capitalize', {
                        value: t('common:internalClient')
                      })}`}</TableCell>
                      <TableCell align='center'>{`${t('common:capitalize', {
                        value: t('common:externalClient')
                      })}`}</TableCell>
                      <TableCell align='left' />
                    </TableRow>
                  </TableHead>
                  {assessmentNodes.length > 0 ? (
                    <TableBody>
                      {filtros.map((selectedResourceInfo) => {
                        let leaderCount = assessmentNodes?.filter((assessment) => {
                          return (
                            assessment.appraiseeAssessmentParticipant?.resourceId == selectedResourceInfo.resourceId &&
                            assessment.type == AssessmentNodeTypeEnum.Leader
                          );
                        }).length;

                        let leaderIndirectCount = assessmentNodes?.filter((assessment) => {
                          return (
                            assessment.appraiseeAssessmentParticipant?.resourceId == selectedResourceInfo.resourceId &&
                            assessment.type == AssessmentNodeTypeEnum.LeaderIndirect
                          );
                        }).length;

                        let staffCount = assessmentNodes?.filter((assessment) => {
                          return (
                            assessment.appraiseeAssessmentParticipant?.resourceId == selectedResourceInfo.resourceId &&
                            assessment.type == AssessmentNodeTypeEnum.Staff
                          );
                        }).length;

                        let peerCount = assessmentNodes?.filter((assessment) => {
                          return (
                            assessment.appraiseeAssessmentParticipant?.resourceId == selectedResourceInfo.resourceId &&
                            assessment.type == AssessmentNodeTypeEnum.Peer
                          );
                        }).length;

                        let clientInternalCount = assessmentNodes?.filter((assessment) => {
                          return (
                            assessment.appraiseeAssessmentParticipant?.resourceId == selectedResourceInfo.resourceId &&
                            assessment.type == AssessmentNodeTypeEnum.ClientInternal
                          );
                        }).length;

                        let clientExternalCount = assessmentNodes?.filter((assessment) => {
                          return (
                            assessment.appraiseeAssessmentParticipant?.resourceId == selectedResourceInfo.resourceId &&
                            assessment.type == AssessmentNodeTypeEnum.ClientExternal
                          );
                        }).length;

                        return (
                          !!selectedResourceInfo && (
                            <TableRow hover key={selectedResourceInfo.resourceId}>
                              <TableCell align='left'>
                                <Stack direction='row' alignItems='center' spacing={2}>
                                  {!!selectedResourceInfo?.profileImageUrl ? (
                                    <Avatar
                                      alt={selectedResourceInfo?.firstName ?? ''}
                                      src={selectedResourceInfo?.profileImageUrl}
                                    />
                                  ) : (
                                    <Avatar>{getFirstCharacter(selectedResourceInfo?.firstName ?? '')}</Avatar>
                                  )}
                                  <Typography variant='subtitle2' noWrap>
                                    {`${selectedResourceInfo?.firstName ?? ''} ${
                                      selectedResourceInfo?.lastName ?? ''
                                    }`.trim()}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align='left'>{selectedResourceInfo.email}</TableCell>
                              <TableCell align='center'>{'(' + leaderCount + ')'}</TableCell>
                              <TableCell align='center'>{'(' + leaderIndirectCount + ')'}</TableCell>
                              <TableCell align='center'>{'(' + staffCount + ')'}</TableCell>
                              <TableCell align='center'>{'(' + peerCount + ')'}</TableCell>
                              <TableCell align='center'>{'(' + clientInternalCount + ')'}</TableCell>
                              <TableCell align='center'>{'(' + clientExternalCount + ')'}</TableCell>
                              <TableCell align='right'>
                                <AssessmentResourceActionMenu
                                  resourceId={selectedResourceInfo.resourceId}
                                  assessmentNodes={assessmentNodes}
                                  setAssessmentNodes={setAssessmentsNodes}
                                  resourceName={`${capitalCaseWords(
                                    selectedResourceInfo?.firstName as string
                                  )} ${capitalCaseWords(selectedResourceInfo?.lastName ?? '')}`.trim()}
                                  assessmentStatus={
                                    assessmentNodes.length > 0
                                      ? assessmentNodes[0].assessment !== undefined
                                        ? assessmentNodes[0].assessment.status
                                        : AssessmentStatusEnum.NotStarted
                                      : AssessmentStatusEnum.NotStarted
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          )
                        );
                      })}
                      {assessmentNodes.length === 0 && (
                        <TableRow style={{ height: 53 }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' colSpan={12}>
                          <SearchNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[limit]}
              component='div'
              count={totalCount}
              rowsPerPage={limit}
              page={offSet - 1}
              labelRowsPerPage='Rows'
              labelDisplayedRows={({ from, to, count }) => {
                return '' + from + '-' + to + `${t('common:TextPagination')}` + count;
              }}
              onPageChange={(e, page) => setOffset(page + 1)}
            />
          </Card>
          {
            <AssessmentRelationshipModal
              onClose={handleCloseRelationshipModal}
              assessmentNodeModel={assessmentNodes}
              showModal={showNodeModal}
            />
          }

          <Box sx={{ pt: 3 }} display='flex' alignItems='flex-end' justifyContent='flex-end' marginBottom={5}>
            {assessmentNodes[0]?.assessment?.status === AssessmentStatusEnum.NotStarted ||
            assessmentNodes[0]?.assessment?.status === AssessmentStatusEnum.Started ||
            assessmentNodes[0]?.assessment?.status === undefined ? (
              <>
                <Button
                  variant='outlined'
                  color='primary'
                  style={{ width: '200px', marginRight: 10 }}
                  onClick={EditAppraisees}>
                  {t('common:capitalize', { value: t('common:edit') })}
                </Button>
                <LoadingButton
                  variant='contained'
                  style={{ width: '200px' }}
                  onClick={finalizar}
                  loading={isSubmitting}>
                  {t('common:capitalize', { value: t('common:save') })}
                </LoadingButton>
              </>
            ) : (
              <Button
                variant='outlined'
                size='medium'
                sx={{ width: '200px' }}
                onClick={() => {
                  window.location.href = PATH_BACKOFFICE.assessments.skills.root;
                }}>
                {t('common:capitalize', { value: t('common:back') })}
              </Button>
            )}
          </Box>
        </Container>
      </Page>
    </Loading>
  );
}

const styles = {
  labelButton: {
    backgroundColor: 'rgba(153, 153, 153, 0.16)',
    color: '#373737',
    padding: '16px 0',
    minWidth: '102px',
    cursor: 'pointer'
  }
};
