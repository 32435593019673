/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AssessmentAutoCompleteModel {
  id?: string | null;
  name?: string | null;
  type?: AssessmentTypeEnum;
}

export interface AssessmentAutoCompleteModelArrayResultHandler {
  succeeded?: boolean;
  data?: AssessmentAutoCompleteModel[] | null;
  message?: string | null;
}

export enum AssessmentEmailTemplateLanguageEnum {
  PtBR = 'PtBR',
  EnUS = 'EnUS',
  EsAR = 'EsAR'
}

export interface AssessmentEmailTemplateModel {
  assessmentEmailTemplateId?: string | null;
  assessmentId?: string | null;
  assessment?: AssessmentModel;
  companyId?: string | null;
  company?: CompanyModel;
  body?: string | null;
  subject?: string | null;
  from?: string | null;
  bcc?: string | null;
  templateType?: AssessmentEmailTemplateTypeEnum;
  templateLanguageType?: AssessmentEmailTemplateLanguageEnum;
}

export interface AssessmentEmailTemplateModelResultHandler {
  succeeded?: boolean;
  data?: AssessmentEmailTemplateModel;
  message?: string | null;
}

export enum AssessmentEmailTemplateTypeEnum {
  WelcomePerformance = 'WelcomePerformance',
  ReminderPerformance = 'ReminderPerformance',
  WelcomeSkills = 'WelcomeSkills',
  ReminderSkills = 'ReminderSkills',
  ThanksParticipation = 'ThanksParticipation'
}

export enum AssessmentImportParticipantsStatusEnum {
  NotStarted = 'NotStarted',
  Started = 'Started',
  Completed = 'Completed',
  Error = 'Error'
}

export interface AssessmentModel {
  assessmentId?: string | null;
  hasAssessmentNodes?: boolean;
  name?: string | null;

  /** @format date-time */
  start?: string | null;

  /** @format date-time */
  end?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  status?: AssessmentStatusEnum;
  importParticipantsStatus?: AssessmentImportParticipantsStatusEnum;
  importParticipantsErrorMessage?: string | null;
  type?: AssessmentTypeEnum;
  importedUrl?: string | null;

  /** @format date-time */
  importedDate?: string | null;
  importedName?: string | null;
  questionnaireId?: string | null;
  questionnaire?: QuestionnaireModel;

  /** @format int32 */
  maxAlternativeValue?: number;
}

export interface AssessmentModelArrayResultHandler {
  succeeded?: boolean;
  data?: AssessmentModel[] | null;
  message?: string | null;
}

export interface AssessmentModelPaginationResult {
  pagination?: PaginationDetail;
  result?: AssessmentModel[] | null;
}

export interface AssessmentModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: AssessmentModelPaginationResult;
  message?: string | null;
}

export interface AssessmentModelResultHandler {
  succeeded?: boolean;
  data?: AssessmentModel;
  message?: string | null;
}

export enum AssessmentNodeConditionEnum {
  Added = 'Added',
  NotModified = 'NotModified',
  Deleted = 'Deleted'
}

export interface AssessmentNodeModel {
  assessmentNodeId?: string | null;
  assessmentId?: string | null;
  assessment?: AssessmentModel;
  appraiserAssessmentParticipantId?: string | null;
  appraiserAssessmentParticipant?: AssessmentParticipantModel;
  appraiseeAssessmentParticipantId?: string | null;
  appraiseeAssessmentParticipant?: AssessmentParticipantModel;
  type?: AssessmentNodeTypeEnum;
  status?: AssessmentNodeStatusEnum;
  condition?: AssessmentNodeConditionEnum;
}

export interface AssessmentNodeModelArrayResultHandler {
  succeeded?: boolean;
  data?: AssessmentNodeModel[] | null;
  message?: string | null;
}

export enum AssessmentNodeStatusEnum {
  NotStarted = 'NotStarted',
  Started = 'Started',
  Completed = 'Completed',
  Scheduled = 'Scheduled'
}

export enum AssessmentNodeTypeEnum {
  Peer = 'Peer',
  Leader = 'Leader',
  LeaderIndirect = 'LeaderIndirect',
  Staff = 'Staff',
  ClientExternal = 'ClientExternal',
  ClientInternal = 'ClientInternal',
  Self = 'Self'
}

export interface AssessmentParticipantModel {
  assessmentParticipantId?: string | null;
  assessmentId?: string | null;
  assessment?: AssessmentModel;
  resourceId?: string | null;
  appraisedRoleId?: string | null;
  attributeKey?: string | null;
  attributeValue?: string | null;
  resource?: ResourceModel;
}

export interface AssessmentPerformanceModel {
  assessmentId?: string | null;
  name?: string | null;
  description?: string | null;
  instructions?: string | null;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string;
  questions?: QuestionPerformanceModel[] | null;
}

export interface AssessmentPerformanceModelResultHandler {
  succeeded?: boolean;
  data?: AssessmentPerformanceModel;
  message?: string | null;
}

export interface AssessmentPerformanceNextModel {
  assessmentId?: string | null;
  hasAssessmentNodes?: boolean;
  name?: string | null;
  description?: MultiLanguageText;
  instructions?: MultiLanguageText;
  status?: AssessmentStatusEnum;
  type?: AssessmentTypeEnum;
  importParticipantsStatus?: AssessmentImportParticipantsStatusEnum;
  importParticipantsErrorMessage?: string | null;
  questionAlternative?: QuestionAlternativesTypeEnum;

  /** @format date-time */
  start?: string | null;

  /** @format date-time */
  end?: string | null;
  createRankingQuestion?: boolean;
  competences?: QuestionPerformanceNextModel[] | null;
}

export interface AssessmentPerformanceNextModelPaginationResult {
  pagination?: PaginationDetail;
  result?: AssessmentPerformanceNextModel[] | null;
}

export interface AssessmentPerformanceNextModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: AssessmentPerformanceNextModelPaginationResult;
  message?: string | null;
}

export interface AssessmentPerformanceNextModelResultHandler {
  succeeded?: boolean;
  data?: AssessmentPerformanceNextModel;
  message?: string | null;
}

export interface AssessmentReportModel {
  assessmentReportId?: string | null;
  appraiseeId?: string | null;
  assessmentId?: string | null;
  assessmentName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  role?: string | null;
  department?: string | null;
  team?: string | null;
  branch?: string | null;

  /** @format date-time */
  register?: string;
  reportUrl?: string | null;
  status?: string | null;
  profileImageUrl?: string | null;
}

export interface AssessmentReportModelPaginationResult {
  pagination?: PaginationDetail;
  result?: AssessmentReportModel[] | null;
}

export interface AssessmentReportModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: AssessmentReportModelPaginationResult;
  message?: string | null;
}

export interface AssessmentSkillCriteria {
  /** @format int32 */
  limit?: number;

  /** @format int32 */
  offset?: number;
  field?: string | null;
  order?: PaginationDirection;
  title?: string | null;
  skillNames?: string[] | null;
  status?: AssessmentStatusEnum;
}

export interface AssessmentSkillListItemModel {
  assessmentId?: string | null;
  title?: string | null;
  description?: string | null;
  hasAssessmentNodes?: boolean;
  skills?: KeyValueModel[] | null;
  status?: AssessmentStatusEnum;
  type?: AssessmentTypeEnum;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string;
  assessmentParentId?: string | null;
}

export interface AssessmentSkillListItemModelPaginationResult {
  pagination?: PaginationDetail;
  result?: AssessmentSkillListItemModel[] | null;
}

export interface AssessmentSkillListItemModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: AssessmentSkillListItemModelPaginationResult;
  message?: string | null;
}

export interface AssessmentSkillListItemModelResultHandler {
  succeeded?: boolean;
  data?: AssessmentSkillListItemModel;
  message?: string | null;
}

export enum AssessmentStatusEnum {
  NotStarted = 'NotStarted',
  Started = 'Started',
  Completed = 'Completed'
}

export enum AssessmentTypeEnum {
  Performance = 'Performance',
  Skill = 'Skill',
  Imported = 'Imported'
}

export interface BooleanResultHandler {
  succeeded?: boolean;
  data?: boolean;
  message?: string | null;
}

export interface BusinessInfoModel {
  businessInfoId?: string | null;
  legalName?: string | null;
  tradeName?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
}

export interface ChangePasswordCriteria {
  oldPassword?: string | null;
  newPassword?: string | null;
}

export interface ChartDatasets {
  label?: string | null;
  backgroundColor?: string | null;
  borderColor?: string | null;
  pointBackgroundColor?: string | null;

  /** @format int32 */
  borderWidth?: number;
  data?: number[] | null;
  notes?: string[] | null;
}

export interface ChartModel {
  labels?: string[] | null;
  datasets?: ChartDatasets[] | null;

  /** @format double */
  average?: number;
}

export interface ChartModelResultHandler {
  succeeded?: boolean;
  data?: ChartModel;
  message?: string | null;
}

export interface CompanyModel {
  companyId?: string | null;
  name?: string | null;
  domain?: string | null;
  logoUrl?: string | null;
  dataStudioUrl?: string | null;
  type?: CompanyTypeEnum;
  status?: CompanyStatusEnum;
  language?: LanguageTypeEnum;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
}

export interface CompanyModelResultHandler {
  succeeded?: boolean;
  data?: CompanyModel;
  message?: string | null;
}

export enum CompanyStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Blocked = 'Blocked'
}

export enum CompanyTypeEnum {
  Standard = 'Standard'
}

export interface CsvErrorModel {
  message?: string | null;

  /** @format int32 */
  line?: number | null;
}

export interface CsvErrorModelResultHandler {
  succeeded?: boolean;
  data?: CsvErrorModel;
  message?: string | null;
}

export interface EmailModel {
  emailId?: string | null;
  address?: string | null;
  confirmed?: boolean;
  main?: boolean;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  status?: EmailStatusEnum;
  type?: EmailTypeEnum;
}

export enum EmailStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum EmailTypeEnum {
  Personal = 'Personal',
  Professional = 'Professional'
}

export interface ImportedResourceEmployeeCsvResultModel {
  importedWithSuccess?: ResourceEmployeeModel[] | null;
  importedWithoutSuccess?: string[] | null;
}

export interface ImportedResourceEmployeeCsvResultModelResultHandler {
  succeeded?: boolean;
  data?: ImportedResourceEmployeeCsvResultModel;
  message?: string | null;
}

export interface Int32Int32DictionaryResultHandler {
  succeeded?: boolean;
  data?: Record<string, number | null>;
  message?: string | null;
}

export interface Int32ProfilingResourcePointInfoModelArrayDictionaryResultHandler {
  succeeded?: boolean;
  data?: Record<string, ProfilingResourcePointInfoModel[] | null>;
  message?: string | null;
}

export interface KeyValueModel {
  key?: string | null;
  value?: string | null;
}

export interface KeyValueModelArrayResultHandler {
  succeeded?: boolean;
  data?: KeyValueModel[] | null;
  message?: string | null;
}

export enum LanguageTypeEnum {
  PtBR = 'ptBR',
  EnUS = 'enUS',
  EsAR = 'esAR'
}

export enum LibraryQuestionTypeEnum {
  System = 'System',
  Company = 'Company',
  Any = 'Any'
}

export interface MultiLanguageText {
  ptBR?: string | null;
  esAR?: string | null;
  enUS?: string | null;
}

export interface PaginationDetail {
  /** @format int32 */
  limit?: number;

  /** @format int32 */
  offset?: number;

  /** @format int32 */
  totalCount?: number;

  /** @format int32 */
  totalPages?: number;
}

export enum PaginationDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

export interface PersonalInfoModel {
  personalInfoId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  profileImageUrl?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
}

export interface ProfilingAxisDivisionModel {
  profilingAxisDivisionId?: string | null;
  profilingAxisId?: string | null;

  /** @format int32 */
  divisionPosition?: number;

  /** @format double */
  percentage?: number;
}

export interface ProfilingAxisModel {
  profilingAxisId?: string | null;
  name?: string | null;

  /** @format int32 */
  division?: number;
  profilingId?: string | null;
  type?: ProfilingAxisTypeEnum;
  profilingAxesWeight?: ProfilingAxisWeightModel[] | null;
  profilingAxisDivisions?: ProfilingAxisDivisionModel[] | null;
}

export enum ProfilingAxisTypeEnum {
  X = 'X',
  Y = 'Y'
}

export interface ProfilingAxisWeightModel {
  profilingAxisWeightId?: string | null;

  /** @format double */
  weight?: number;
  profilingAxisId?: string | null;
  assessmentId?: string | null;
  assessmentName?: string | null;
}

export interface ProfilingModel {
  profilingId?: string | null;
  name?: string | null;
  description?: string | null;

  /** @format date-time */
  register?: string | null;

  /** @format date-time */
  lastUpdate?: string | null;

  /** @format date-time */
  start?: string;

  /** @format date-time */
  end?: string;
  hasAllResourcesInAllAssessments?: boolean;
  hasProfilingResourcePoints?: boolean;
  assessmentIds?: string[] | null;
  language?: LanguageTypeEnum;
  status?: ProfilingStatusEnum;
  profilingAxes?: ProfilingAxisModel[] | null;
  profilingResourcePoints?: ProfilingResourcePointModel[] | null;
}

export interface ProfilingModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ProfilingModel[] | null;
}

export interface ProfilingModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ProfilingModelPaginationResult;
  message?: string | null;
}

export interface ProfilingModelResultHandler {
  succeeded?: boolean;
  data?: ProfilingModel;
  message?: string | null;
}

export interface ProfilingPayload {
  profilingId?: string | null;
  name?: string | null;
  description?: string | null;

  /** @format date-time */
  start?: string | null;

  /** @format date-time */
  end?: string | null;
  language?: LanguageTypeEnum;
  status?: ProfilingStatusEnum;
  profilingAxes?: ProfilingAxisModel[] | null;
}

export interface ProfilingResourcePayload {
  profilingResourcePointId?: string | null;
  resourceId?: string | null;
  condition?: ProfilingResourcePointConditionEnum;
}

export interface ProfilingResourcePayloadArrayResultHandler {
  succeeded?: boolean;
  data?: ProfilingResourcePayload[] | null;
  message?: string | null;
}

export enum ProfilingResourcePointConditionEnum {
  Added = 'Added',
  NotModified = 'NotModified',
  Deleted = 'Deleted'
}

export interface ProfilingResourcePointInfoCriteria {
  profilingResourcePointInfoSearchType?: ProfilingResourcePointInfoSearchTypeEnum;
  search?: string | null;
  searchExactlyText?: boolean;
  profilingId?: string | null;

  /** @format int32 */
  position?: number;

  /** @format int32 */
  limit?: number;

  /** @format int32 */
  offset?: number;
  assessmentIdList?: string[] | null;
}

export interface ProfilingResourcePointInfoModel {
  profilingResourcePointId?: string | null;
  resourceId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  employeeExternalId?: string | null;
  role?: string | null;
  department?: string | null;
  team?: string | null;
  branch?: string | null;
  language?: LanguageTypeEnum;
  profileImageUrl?: string | null;
  status?: ProfilingResourcePointStatusEnum;

  /** @format int32 */
  position?: number;

  /** @format int32 */
  originalPosition?: number;

  /** @format double */
  valueX?: number | null;

  /** @format double */
  valueY?: number | null;
  employeeIsNotInAssessmentsOrHasNoAnswers?: string[] | null;
}

export interface ProfilingResourcePointInfoModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ProfilingResourcePointInfoModel[] | null;
}

export interface ProfilingResourcePointInfoModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ProfilingResourcePointInfoModelPaginationResult;
  message?: string | null;
}

export enum ProfilingResourcePointInfoSearchTypeEnum {
  Name = 'Name',
  Team = 'Team',
  Branch = 'Branch',
  Role = 'Role',
  Department = 'Department',
  EmployeeExternalId = 'EmployeeExternalId'
}

export interface ProfilingResourcePointModel {
  profilingResourcePointId?: string | null;
  profilingId?: string | null;
  resourceId?: string | null;
  resource?: ResourceModel;

  /** @format int32 */
  position?: number;

  /** @format int32 */
  originalPosition?: number;
  status?: ProfilingResourcePointStatusEnum;

  /** @format double */
  valueX?: number | null;

  /** @format double */
  valueY?: number | null;
  attributeValue?: string | null;
  attributeKey?: string | null;
  resourcesHasAnswerInAllAssessments?: boolean;
}

export interface ProfilingResourcePointModelResultHandler {
  succeeded?: boolean;
  data?: ProfilingResourcePointModel;
  message?: string | null;
}

export enum ProfilingResourcePointStatusEnum {
  Automatic = 'Automatic',
  Manual = 'Manual'
}

export enum ProfilingStatusEnum {
  NotStarted = 'NotStarted',
  Processing = 'Processing',
  Processed = 'Processed'
}

export interface QuestionAlternativeInfoModel {
  questionAlternativeId?: string | null;

  /** @format int32 */
  order?: number | null;
  label?: string | null;

  /** @format int32 */
  value?: number | null;
}

export interface QuestionAlternativePerformanceModel {
  label?: string | null;

  /** @format int32 */
  value?: number | null;
}

export enum QuestionAlternativesTypeEnum {
  Numeric1At5 = 'Numeric1At5',
  Numeric1At7 = 'Numeric1At7',
  Labeled1At5 = 'Labeled1At5',
  Labeled1At7 = 'labeled1At7'
}

export interface QuestionAnswerInfoModel {
  assessmentQuestionAnswerId?: string | null;

  /** @format int32 */
  answerValue?: number | null;
  answerText?: string | null;
  answerLabel?: string | null;
  answerAlternativeId?: string | null;
}

export interface QuestionInfoModel {
  questionId?: string | null;
  title?: string | null;
  subtitle?: string | null;
  required?: boolean;

  /** @format int32 */
  order?: number | null;
  questionnaireType?: QuestionnaireTypeEnum;
  type?: QuestionTypeEnum;
  questions?: QuestionInfoModel[] | null;
  alternatives?: QuestionAlternativeInfoModel[] | null;
  answers?: QuestionAnswerInfoModel[] | null;
}

export interface QuestionInfoModelPaginationResult {
  pagination?: PaginationDetail;
  result?: QuestionInfoModel[] | null;
}

export interface QuestionInfoModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: QuestionInfoModelPaginationResult;
  message?: string | null;
}

export interface QuestionPerformanceModel {
  title?: string | null;

  /** @format int32 */
  score?: number | null;
  type?: QuestionTypeEnum;
  alternatives?: QuestionAlternativePerformanceModel[] | null;
}

export interface QuestionPerformanceNextEvidence {
  title?: MultiLanguageText;
  description?: MultiLanguageText;
  questionType?: QuestionTypeEnum;
  referenceQuestionId?: string | null;
  required?: boolean;
}

export interface QuestionPerformanceNextModel {
  title?: MultiLanguageText;
  description?: MultiLanguageText;
  questionType?: QuestionTypeEnum;
  referenceQuestionId?: string | null;
  required?: boolean;
  evidences?: QuestionPerformanceNextEvidence[] | null;
}

export enum QuestionTypeEnum {
  Label = 'Label',
  Boolean = 'Boolean',
  Alternative = 'Alternative',
  Text = 'Text',
  Rank = 'Rank'
}

export interface QuestionnaireModel {
  questionnaireId?: string | null;
  name?: string | null;
  title?: string | null;
  description?: string | null;
  instruction?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  status?: QuestionnaireStatusEnum;
  type?: QuestionnaireTypeEnum;
  schema?: QuestionnaireSchemaEnum;
}

export enum QuestionnaireSchemaEnum {
  Generic = 'Generic',
  Performance = 'Performance'
}

export enum QuestionnaireStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
  ReadOnly = 'ReadOnly'
}

export enum QuestionnaireTypeEnum {
  Standard = 'Standard',
  SystemLibrary = 'SystemLibrary',
  CompanyLibrary = 'CompanyLibrary'
}

export interface ResourceCandidateModel {
  resourceId?: string | null;
}

export interface ResourceClientModel {
  resourceClientId?: string | null;
  resource?: ResourceModel;
}

export interface ResourceClientModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ResourceClientModel[] | null;
}

export interface ResourceClientModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ResourceClientModelPaginationResult;
  message?: string | null;
}

export interface ResourceClientModelResultHandler {
  succeeded?: boolean;
  data?: ResourceClientModel;
  message?: string | null;
}

export interface ResourceClientPayload {
  firstName?: string | null;
  lastName?: string | null;
  profileImageBase64?: string | null;
  emailAddresses?: string[] | null;
}

export interface ResourceEmployeeAttributeModel {
  resourceEmployeeAttributeId?: string | null;
  key?: string | null;
  value?: string | null;
}

export interface ResourceEmployeeGoalModel {
  resourceEmployeeGoalId?: string | null;

  /** @format int32 */
  value?: number;
  description?: string | null;

  /** @format date-time */
  dueDate?: string | null;
  dueDateAlias?: string | null;
}

export interface ResourceEmployeeGoalModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ResourceEmployeeGoalModel[] | null;
}

export interface ResourceEmployeeGoalModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ResourceEmployeeGoalModelPaginationResult;
  message?: string | null;
}

export interface ResourceEmployeeGoalModelResultHandler {
  succeeded?: boolean;
  data?: ResourceEmployeeGoalModel;
  message?: string | null;
}

export interface ResourceEmployeeListItemModel {
  resourceId?: string | null;
  resourceEmployeeId?: string | null;
  completeName?: string | null;
  employeeExternalId?: string | null;
  email?: string | null;
  department?: string | null;
  role?: string | null;
  branch?: string | null;
  team?: string | null;
  status?: ResourceStatusEnum;
  profileImageUrl?: string | null;
}

export interface ResourceEmployeeListItemModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ResourceEmployeeListItemModel[] | null;
}

export interface ResourceEmployeeListItemModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ResourceEmployeeListItemModelPaginationResult;
  message?: string | null;
}

export interface ResourceEmployeeModel {
  resourceEmployeeId?: string | null;
  attributes?: ResourceEmployeeAttributeModel[] | null;
  resource?: ResourceModel;
}

export interface ResourceEmployeeModelResultHandler {
  succeeded?: boolean;
  data?: ResourceEmployeeModel;
  message?: string | null;
}

export interface ResourceEmployeePayload {
  firstName?: string | null;
  lastName?: string | null;
  emailAddresses?: string[] | null;
  profileImageBase64?: string | null;
  employeeExternalId?: string | null;
  department?: string | null;
  role?: string | null;
  branch?: string | null;
  team?: string | null;
  language?: LanguageTypeEnum;
  leaders?: string[] | null;
  leadersIndirect?: string[] | null;
  staff?: string[] | null;
  peer?: string[] | null;
  clientsExternal?: string[] | null;
  clientsInternal?: string[] | null;
}

export interface ResourceFeedbackModel {
  resourceFeedbackId?: string | null;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string;
  message?: string | null;
  fromResourceId?: string | null;
  toResourceId?: string | null;
  type?: ResourceFeedbackTypeEnum;
  personalInfo?: PersonalInfoModel;
}

export interface ResourceFeedbackModelArrayResultHandler {
  succeeded?: boolean;
  data?: ResourceFeedbackModel[] | null;
  message?: string | null;
}

export interface ResourceFeedbackModelResultHandler {
  succeeded?: boolean;
  data?: ResourceFeedbackModel;
  message?: string | null;
}

export interface ResourceFeedbackPayload {
  message?: string | null;
  fromResourceEmployeeId?: string | null;
  toResourceEmployeeId?: string | null;
  type?: ResourceFeedbackTypeEnum;
}

export enum ResourceFeedbackTypeEnum {
  Public = 'Public',
  Private = 'Private'
}

export interface ResourceInfoCriteria {
  resourceInfoSearchType?: ResourceInfoSearchTypeEnum;
  search?: string | null;
  searchExactlyText?: boolean;
  resourceIdList?: string[] | null;
  assessmentId?: string | null;

  /** @format int32 */
  limit?: number;

  /** @format int32 */
  offset?: number;
}

export interface ResourceInfoModel {
  resourceId?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  role?: string | null;
  department?: string | null;
  team?: string | null;
  branch?: string | null;
  email?: string | null;

  /** @format int32 */
  leaderCount?: number | null;

  /** @format int32 */
  leaderIndirectCount?: number | null;

  /** @format int32 */
  staffCount?: number | null;

  /** @format int32 */
  peerCount?: number | null;

  /** @format int32 */
  clientInternalCount?: number | null;

  /** @format int32 */
  clientExternalCount?: number | null;
  language?: LanguageTypeEnum;
  profileImageUrl?: string | null;
  hasInAllAssessments?: boolean;
  statusInAssessment?: string | null;
  employeeIsNotInAssessmentsOrHasNoAnswers?: string[] | null;
}

export interface ResourceInfoModelArrayResultHandler {
  succeeded?: boolean;
  data?: ResourceInfoModel[] | null;
  message?: string | null;
}

export interface ResourceInfoModelPaginationResult {
  pagination?: PaginationDetail;
  result?: ResourceInfoModel[] | null;
}

export interface ResourceInfoModelPaginationResultResultHandler {
  succeeded?: boolean;
  data?: ResourceInfoModelPaginationResult;
  message?: string | null;
}

export interface ResourceInfoProfilingCriteria {
  resourceInfoSearchType?: ResourceInfoSearchTypeEnum;
  search?: string | null;
  searchExactlyText?: boolean;
  resourceIdList?: string[] | null;
  assessmentIdList?: string[] | null;

  /** @format int32 */
  limit?: number;

  /** @format int32 */
  offset?: number;
}

export enum ResourceInfoSearchTypeEnum {
  Name = 'Name',
  Team = 'Team',
  Branch = 'Branch',
  Role = 'Role',
  Department = 'Department'
}

export interface ResourceModel {
  resourceId?: string | null;
  type?: ResourceTypeEnum;
  status?: ResourceStatusEnum;
  language?: LanguageTypeEnum;
  businessInfo?: BusinessInfoModel;
  personalInfo?: PersonalInfoModel;
  employeeExternalId?: string | null;
  company?: CompanyModel;

  /** @format date-time */
  register?: string;

  /** @format date-time */
  lastUpdate?: string | null;
  emails?: EmailModel[] | null;
  resourceCandidate?: ResourceCandidateModel;
  resourceClient?: ResourceClientModel;
  resourceEmployeeId?: string | null;
  resourceEmployee?: ResourceEmployeeModel;
  nodes?: ResourceNodeModel[] | null;
}

export interface ResourceModelArrayResultHandler {
  succeeded?: boolean;
  data?: ResourceModel[] | null;
  message?: string | null;
}

export interface ResourceModelResultHandler {
  succeeded?: boolean;
  data?: ResourceModel;
  message?: string | null;
}

export interface ResourceNodeModel {
  type?: ResourceNodeTypeEnum;
  resource?: ResourceModel;
}

export enum ResourceNodeTypeEnum {
  Leader = 'Leader',
  LeaderIndirect = 'LeaderIndirect',
  Staff = 'Staff',
  ClientExternal = 'ClientExternal',
  ClientInternal = 'ClientInternal',
  Peer = 'Peer'
}

export enum ResourceStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted'
}

export enum ResourceTypeEnum {
  Employee = 'Employee',
  Candidate = 'Candidate',
  Client = 'Client'
}

export interface ResultHandler {
  succeeded?: boolean;
}

export interface SkillAutoCompleteCriteria {
  skillName?: string | null;
}

export interface SkillAverageModel {
  /** @format int32 */
  skillId?: number;
  skillName?: string | null;

  /** @format int32 */
  value?: number;
  skillSelfAverage?: SkillAverageModel;
}

export interface SkillAverageModelArrayResultHandler {
  succeeded?: boolean;
  data?: SkillAverageModel[] | null;
  message?: string | null;
}

export interface StringArrayResultHandler {
  succeeded?: boolean;
  data?: string[] | null;
  message?: string | null;
}

export interface StringResultHandler {
  succeeded?: boolean;
  data?: string | null;
  message?: string | null;
}

export interface StringStringDictionaryResultHandler {
  succeeded?: boolean;
  data?: Record<string, string | null>;
  message?: string | null;
}

export interface AssessmentExistNameListParams {
  /** Name of the assessment. */
  assessmentName?: string;
}

export interface AssessmentPerformanceListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface AssessmentPerformanceNextListParams {
  Name?: string;

  /** @format date-time */
  Start?: string;

  /** @format date-time */
  End?: string;
  AssessmentStatusEnum?: AssessmentStatusEnum;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface AssessmentPerformanceNextAssessmentStatusListParams {
  assessmentStatus?: AssessmentStatusEnum;
}

export interface AssessmentSkillListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Field?: string;
  Order?: PaginationDirection;
  Title?: string;
  SkillNames?: string[];
  Status?: AssessmentStatusEnum;
}

export interface AssessmentSendemailtoappraisersDetailParams {
  assessmentEmailTemplateType?: AssessmentEmailTemplateTypeEnum;

  /** The assessment id. */
  assessmentId: string;
}

export interface AssessmentSendemailtoappraisersListCreateParams {
  assessmentId?: string;
  assessmentEmailTemplateType?: AssessmentEmailTemplateTypeEnum;
}

export interface AssessmentSendfirstwelcomeemailCreateParams {
  /** The force assessment email template type identifier. */
  forceAssessmentEmailTemplateTypeId?: AssessmentEmailTemplateTypeEnum;

  /** The assessment identifier. */
  assessmentId: string;
}

export interface AssessmentSendEmailToAppraisersNotInitiatedDetailParams {
  assessmentEmailTemplateType?: AssessmentEmailTemplateTypeEnum;

  /** The assessment id. */
  assessmentId: string;
}

export interface AssessmentNodeSkillAssessmentNodesCreateParams {
  /** The assessment id. */
  assessmentId?: string;
}

export interface AssessmentNodeAssessmentNodesCreateParams {
  /** The assessment id. */
  assessmentId?: string;
}

export interface AssessmentNodeAppraisersUpdateParams {
  /** The assessment identifier. */
  assessmentId?: string;
}

export interface ProfilingListParams {
  Name?: string;
  Description?: string;

  /** @format date-time */
  Start?: string;

  /** @format date-time */
  End?: string;
  AssessmentIds?: string[];
  Status?: ProfilingStatusEnum;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ProfilingExternalCreateParams {
  /** Name of the assessment. */
  assessmentName?: string;

  /**
   * The imported date.
   * @format date-time
   */
  importedDate?: string;
}

export interface ProfilingProfilingResourcePointPositionUpdateParams {
  /** The identifier of the resource. */
  resourceId?: string;

  /** The identifier of the profiling. */
  profilingId?: string;

  /**
   * The value of the position.
   * @format int32
   */
  position?: number;
}

export interface ProfilingProfilingResourcePointFirstThreeListParams {
  ProfilingResourcePointInfoSearchType?: ProfilingResourcePointInfoSearchTypeEnum;
  Search?: string;
  SearchExactlyText?: boolean;
  ProfilingId?: string;

  /** @format int32 */
  Position?: number;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  AssessmentIdList?: string[];
}

export interface QuestionnaireCompetenceLibraryListParams {
  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
  Name?: string;
  LanguageType?: LanguageTypeEnum;
  LibraryQuestionType?: LibraryQuestionTypeEnum;
}

export interface ReportGenerateBulkBackgroundCreateParams {
  /** The assessment identifier. */
  assessmentId?: string;

  /** if set to `true` [use company language]. */
  useCompanyLanguage?: boolean;
}

export interface ReportResetStatusCreateParams {
  /** The assessment identifier. */
  assessmentId?: string;
}

export interface ReportPerformanceListParams {
  AssessmentId?: string;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceAutocompleteListParams {
  /** The name. */
  name?: string;

  /**
   * The limit.
   * @format int32
   */
  limit?: number;
}

export interface ResourceNodeDetailParams {
  /** Type of the node. */
  nodeType?: ResourceNodeTypeEnum;

  /** The resource identifier. */
  resourceId: string;
}

export interface ResourceNodeCreateParams {
  /** The child identifier. */
  relatedId?: string;
  resourceId: string;

  /** Type of the node. */
  nodeType?: ResourceNodeTypeEnum;
}

export interface ResourceNodeDeleteParams {
  /** The child identifier. */
  relatedId?: string;
  resourceId: string;

  /** Type of the node. */
  nodeType?: ResourceNodeTypeEnum;
}

export interface ResourceFeedbackListParams {
  ToResourceEmployeeId?: string;
  FromResourceEmployeeId?: string;
}

export interface ResourceEmployeeGoalListParams {
  ResourceEmployeeId?: string;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceResourceInfoListParams {
  ResourceInfoSearchType?: ResourceInfoSearchTypeEnum;
  Search?: string;
  SearchExactlyText?: boolean;
  ResourceIdList?: string[];
  AssessmentId?: string;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceResourceInfoAllFilteredListParams {
  ResourceInfoSearchType?: ResourceInfoSearchTypeEnum;
  Search?: string;
  SearchExactlyText?: boolean;
  ResourceIdList?: string[];
  AssessmentId?: string;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceResourceInfoIdsListParams {
  ResourceInfoSearchType?: ResourceInfoSearchTypeEnum;
  Search?: string;
  SearchExactlyText?: boolean;
  ResourceIdList?: string[];
  AssessmentId?: string;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceClientListParams {
  Name?: string;
  Email?: string;
  Status?: ResourceStatusEnum;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceEmployeeListParams {
  Name?: string;
  Email?: string;
  Leader?: string;
  Role?: string;
  Department?: string;
  Team?: string;
  Status?: ResourceStatusEnum;

  /** @format int32 */
  Limit?: number;

  /** @format int32 */
  Offset?: number;
}

export interface ResourceEmployeeVerifyEmailExistanceCreateParams {
  /** The email for searching. */
  email?: string;
}
