import { useState } from 'react';
// @mui
import { Button, Dialog, DialogTitle, DialogContent, DialogProps, IconButton } from '@mui/material';
import closeOutline from '@iconify/icons-eva/close-outline';
import { AssessmentNodeModel, ResourceInfoModel } from 'apis/backoffice-api/data-contracts';
import AppraiseeEdit from './AppraiseeEdit';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export type FormDialogProps = {
  setAssessmentNodes: (data: AssessmentNodeModel[]) => void;
  assessmentNodes?: AssessmentNodeModel[];
  setAppraiserModalOpen: (data: boolean) => void;
  appraiserModalOpen: boolean;
  resourceId: string;
  resourceName: string;
  isReadOnly: boolean;
};

export function AppraiserDialog({
  appraiserModalOpen,
  setAppraiserModalOpen,
  setAssessmentNodes,
  assessmentNodes,
  resourceId,
  resourceName,
  isReadOnly
}: FormDialogProps) {
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('lg');
  const [assessmentId, setAssessmentId] = useState<string>(
    assessmentNodes ? assessmentNodes[0]?.assessmentId ?? '' : ''
  );

  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={appraiserModalOpen} fullWidth maxWidth={maxWidth} onClose={() => setAppraiserModalOpen(false)}>
        <DialogTitle
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}>
          {t('common:capitalize', { value: t('assessment:appraisers') })} {t('common:of')}: {resourceName}
          <IconButton
            aria-label='close'
            onClick={() => setAppraiserModalOpen(false)}
            sx={{
              position: 'absolute',
              right: 24,
              top: 24,
              color: (theme) => theme.palette.grey[500]
            }}>
            <Icon icon={closeOutline} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AppraiseeEdit
            assessmentId={assessmentId}
            resourceId={resourceId}
            setAssessmentNodes={setAssessmentNodes}
            assessmentNodes={assessmentNodes}
            isReadOnly={isReadOnly}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
