import { ResourceInfoModel } from 'apis/backoffice-api/data-contracts';
import { createContext, ReactNode, useState } from 'react';

interface ResourceInfo extends ResourceInfoModel {
    name?: string
  }

type AssessmentContextProps = {
    children: ReactNode;
};

type AssessementContextType = {
    showResourcesList: boolean;
    setShowResourcesList: (newState: boolean) => void;
    savedResources : ResourceInfo[];
    setSavedResources : (newState: ResourceInfo[]) => void;
    selectedResources : string[]; 
    setSelectedResources : (newState : string[]) => void;
}

const initialValue = {
    showResourcesList: true,
    setShowResourcesList: () => { },
    savedResources : [], 
    setSavedResources : () => {},
    selectedResources : [], 
    setSelectedResources : () => {},
};

export const AssessmentContext = createContext<AssessementContextType>(initialValue);

export const AssessmentSkillsContextProvider = ({ children }: AssessmentContextProps) => {        
    const [showResourcesList, setShowResourcesList] = useState(initialValue.showResourcesList);
    const [savedResources, setSavedResources] = useState<ResourceInfo[]>([]);
    const [selectedResources, setSelectedResources] = useState<string[]>([]);
    return (
        <AssessmentContext.Provider value={{ showResourcesList, setShowResourcesList , savedResources, setSavedResources, selectedResources, setSelectedResources }}>
            {children}
        </AssessmentContext.Provider>
    );

};