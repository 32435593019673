import { useEffect, useState } from 'react';
// @mui
import { Button, Dialog, DialogTitle, DialogContent, DialogProps, IconButton, DialogActions } from '@mui/material';
import closeOutline from '@iconify/icons-eva/close-outline';
import { AssessmentNodeConditionEnum, AssessmentNodeModel, AssessmentNodeTypeEnum, ResourceInfoModel } from 'apis/backoffice-api/data-contracts';
import AppraiseeEdit from './AppraiseeEdit';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { useAssessmentNodeService } from 'services/AssessmentNodeService';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export type FormDialogProps = {
  setSelfAssessmentNodes: (data: AssessmentNodeModel[]) => void;
  selfAssessmentNodes?: AssessmentNodeModel[];
  setAppraiserModalOpen: (data: boolean) => void;
  appraiserModalOpen: boolean;
  resourceId: string;
  resourceName: string;
  isReadOnly: boolean;
};

export function AppraiserDialog({
  appraiserModalOpen,
  setAppraiserModalOpen,
  setSelfAssessmentNodes,
  selfAssessmentNodes,
  resourceId,
  resourceName,
  isReadOnly,
}: FormDialogProps) {
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('lg');
  const [loading, setLoading] = useState<boolean>(false);
  const [appraiserAssessmentNodeModelsToEdit, setAppraiserAssessmentNodeModelsToEdit] = useState<AssessmentNodeModel[]>([]);
  const [assessmentId, setAssessmentId] = useState<string>(
    selfAssessmentNodes ? selfAssessmentNodes[0]?.assessmentId ?? '' : ''
  );
  const { assessmentNodeAppraisersUpdate } = useAssessmentNodeService();

  const { t } = useTranslation();

  const handleUpdateAppraiseeAssessmentNodes = () =>{
    setLoading(true);
    (async () => {
      await assessmentNodeAppraisersUpdate(assessmentId, appraiserAssessmentNodeModelsToEdit)
        .then((data) => {
          let assessmentNodesToUpdate = [...selfAssessmentNodes ?? []];
          var updatedSelfIndex = data?.findIndex((assessmentNode) => assessmentNode.type == AssessmentNodeTypeEnum.Self) ?? -1;
          let selfIndex = assessmentNodesToUpdate.findIndex((assessmentNode) => assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId);

          if(!!data && selfIndex >= 0 && updatedSelfIndex >= 0){
            assessmentNodesToUpdate[selfIndex].appraiseeAssessmentParticipantId = data[updatedSelfIndex].appraiseeAssessmentParticipantId;
            assessmentNodesToUpdate[selfIndex].appraiserAssessmentParticipantId = data[updatedSelfIndex].appraiseeAssessmentParticipantId;
            assessmentNodesToUpdate[selfIndex].assessmentNodeId = data[updatedSelfIndex].assessmentNodeId;

            let appraiserAssessmentParticipant = assessmentNodesToUpdate[selfIndex].appraiserAssessmentParticipant;
            let appraiseeAssessmentParticipant = assessmentNodesToUpdate[selfIndex].appraiseeAssessmentParticipant;
            if(!!appraiserAssessmentParticipant && !!appraiseeAssessmentParticipant){
              appraiserAssessmentParticipant.assessmentParticipantId = data[updatedSelfIndex].appraiserAssessmentParticipant?.assessmentParticipantId;
              appraiseeAssessmentParticipant.assessmentParticipantId = data[updatedSelfIndex].appraiseeAssessmentParticipant?.assessmentParticipantId;
            }
            
            assessmentNodesToUpdate[selfIndex].appraiseeAssessmentParticipant = appraiseeAssessmentParticipant;
            assessmentNodesToUpdate[selfIndex].appraiserAssessmentParticipant = appraiserAssessmentParticipant;
            assessmentNodesToUpdate[selfIndex].condition = AssessmentNodeConditionEnum.NotModified;
          }

          setSelfAssessmentNodes(assessmentNodesToUpdate);
          setLoading(false);
          setAppraiserModalOpen(false);
        })
        .catch((errorValue) => {
          console.log(errorValue);
          setLoading(false);
        });
    })();
  }
  
  return (
    <div>
      <Dialog
        open={appraiserModalOpen}
        fullWidth
        maxWidth={maxWidth}
        onClose={() => setAppraiserModalOpen(false)}
      >         
        <DialogTitle sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}>
         {t('common:capitalize', {value: t('assessment:appraisers')})} {t('common:of')}: {resourceName}
          <IconButton
            aria-label="close"
            onClick={() => setAppraiserModalOpen(false)}
            sx={{
              position: 'absolute',
              right: 24,
              top: 24,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Icon icon={closeOutline} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AppraiseeEdit
            assessmentId={assessmentId}
            resourceId={resourceId}
            setSelfAssessmentNodes={setSelfAssessmentNodes}
            selfAssessmentNodes={selfAssessmentNodes}
            isReadOnly={isReadOnly}
            appraiserAssessmentNodeModelsToEdit={appraiserAssessmentNodeModelsToEdit}
            setAppraiserAssessmentNodeModelsToEdit={setAppraiserAssessmentNodeModelsToEdit}
          />
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => {setAppraiserModalOpen(false); setSelfAssessmentNodes(selfAssessmentNodes ?? [])}}>
            {t('common:capitalize', { value: t('common:cancel') })}
          </Button>
          <LoadingButton loading={loading} variant='contained' onClick={handleUpdateAppraiseeAssessmentNodes} autoFocus>
            {t('common:capitalize', { value: t('common:save') })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
