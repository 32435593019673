import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// material
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Stack,
  Card,
  Container,
  Button,
  IconButton,
  CircularProgress
} from '@mui/material';
import Label from 'components/Label';
// hooks
import useSettings from 'hooks/useSettings';
// components
import SearchNotFound from 'components/SearchNotFound/SearchNotFound';
import Scrollbar from 'components/Scrollbar';
import { Loading } from 'components/Loading';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import { PATH_BACKOFFICE } from 'routes/paths';
import SkillsForm from 'pages/assessments/skills/components/AssessmentSkillListFilter';
import {
  AssessmentSkillListItemModel,
  AssessmentStatusEnum,
  KeyValueModel,
  PaginationDirection
} from 'apis/backoffice-api/data-contracts';
import { useAssessmentSkillService } from 'services/AssessmentSkillService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { AssessmentSkillListActionMenu } from './components/AssessmentSkillListActionMenu';
//components
import { useTranslation } from 'react-i18next';
import AssessmentSkillListFilter from 'pages/assessments/skills/components/AssessmentSkillListFilter';
import { useAssessmentPerformanceService } from 'services/AssessmentPerformanceService';
import moment from 'moment';
import { KeyValue } from 'types/KeyValue';

export default function AssessmentSkillList() {
  //CONFIG
  const { themeStretch } = useSettings();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { assessmentSkillList } = useAssessmentSkillService();
  //PAGINATION
  const [offSet, setOffset] = useState<number>(1);
  const [limit] = useState<number>(15);
  const [field] = useState<string>('title');
  const [order, setOrder] = useState<PaginationDirection | undefined>(PaginationDirection.Asc);
  const [title, setTitle] = useState<string>('');
  const [skillNames, setSkillNames] = useState<KeyValue[] | KeyValueModel[] | undefined>([]);
  const [statusSearch, setStatusSearch] = useState<AssessmentStatusEnum | undefined>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [skills, setSkills] = useState<AssessmentSkillListItemModel[]>([]);
  const [status, setStatus] = useState<KeyValueModel[]>([]);
  const { assessmentPerformanceNextStatusList } = useAssessmentPerformanceService();
  const { t } = useTranslation();
  const [pageIsLoadingFirstTime, setPageIsLoadingFirstTime] = useState<boolean>(true);

  //FETCH
  const fetchSkillsList = async () => {
    await assessmentSkillList(
      limit ?? 20,
      offSet ?? 1,
      field,
      order,
      title,
      skillNames ? (skillNames.map((sk) => sk.value) as string[]) ?? undefined : undefined,
      statusSearch
    ).then((data) => {
      setSkills(data?.result ?? []);
      setTotalCount(data?.pagination?.totalCount ?? 0);
    });
  };

  const fetchStatus = async () => {
    await assessmentPerformanceNextStatusList()
      .then((data) => {
        !!data && setStatus(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    (async () => {
      if (pageIsLoadingFirstTime) {
        setLoading(true);
        await fetchStatus();
        await fetchSkillsList();
        setLoading(false);
        setPageIsLoadingFirstTime(false);
      } else {
        setIsSubmitting(true);
        await fetchStatus();
        await fetchSkillsList();
        setIsSubmitting(false);
      }
    })();
  }, [limit, offSet, order]);

  const handleOnChangeValue = (value: string | KeyValue[] | KeyValueModel[] | AssessmentStatusEnum, prop: string) => {
    if (prop === 'titleSearch') {
      setTitle(value as string);
    }
    if (prop === 'statusSearch') {
      if (value !== 'any') setStatusSearch(value as AssessmentStatusEnum);
      else setStatusSearch(undefined);
    }
    if (prop === 'skillSearch') {
      setSkillNames(value as KeyValue[]);
    }
  };

  const handleOnSearch = async () => {
    setIsSubmitting(true);
    await fetchSkillsList();
    setOffset(1);
    setIsSubmitting(false);
  };

  const handleOnChangeDirection = async () => {
    setOrder(order === PaginationDirection.Asc ? PaginationDirection.Desc : PaginationDirection.Asc);
  };

  const fmtDescription = (value: string | null | undefined) => {
    if (value !== undefined && value !== null) {
      if (value.length >= 30) {
        return value ? `${value.substring(0, 30)}...` : '';
      }
    }
    return value;
  };

  const fmtSkills = (value: KeyValueModel[] | null | undefined) => {
    let fmtText = '';
    value?.forEach((s) => {
      if (fmtText !== '') fmtText = `${fmtText}, ${s.value}`;
      else fmtText = `${s.value}`;
    });
    return fmtText;
  };

  const translateAssessmentStatus = (status: AssessmentStatusEnum) => {
    switch (status) {
      case AssessmentStatusEnum.Started:
        return t('common:capitalize', { value: t('assessment:assessmentStatusStarted') });
      case AssessmentStatusEnum.NotStarted:
        return t('common:capitalize', { value: t('assessment:assessmentStatusNotStarted') });
      case AssessmentStatusEnum.Completed:
        return t('common:capitalize', { value: t('assessment:assessmentStatusCompleted') });
    }
  };

  const handleClearSearchForm = () => {
    setSkillNames([]);
    setTitle('');
    setStatusSearch(undefined);
  };

  return (
    <Loading loading={loading}>
      <Page title={`HR Metrics | ${t('common:capitalize', { value: t('skill:skills') })}`}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={t('common:capitalize', { value: t('skill:skills') })}
            links={[{ name: `${t('common:capitalize', { value: t('skill:skill') })}` }]}
            action={
              <Button variant='contained' component={Link} to={PATH_BACKOFFICE.assessments.skills.new}>
                {t('common:capitalize', { value: t('skill:newSkill') })}
              </Button>
            }
          />
          <AssessmentSkillListFilter
            handleOnChangeValue={(value: string | KeyValue[] | KeyValueModel[], prop: string) =>
              handleOnChangeValue(value, prop)
            }
            handleOnSearch={handleOnSearch}
            skillsSearch={skillNames}
            titleSearch={title}
            statusSearch={statusSearch}
            statusInfo={status}
            handleClearForm={handleClearSearchForm}
            isSubmitting={isSubmitting}
          />
          <Stack spacing={5}>
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, mt: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left' width={230}>
                          <IconButton
                            aria-label='ordernation'
                            style={{ fontSize: '15px', padding: '0', margin: '0', borderRadius: '2px' }}
                            onClick={() => handleOnChangeDirection()}>
                            {t('common:capitalize', { value: t('common:title') })}
                            {order === PaginationDirection.Asc ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                          </IconButton>
                        </TableCell>
                        {/* <TableCell align='left' width={200}>
                          {t('common:capitalize', { value: t('common:description') })}
                        </TableCell> */}
                        <TableCell align='left' width={400}>
                          {t('common:capitalize', { value: t('skill:skills') })}
                        </TableCell>
                        <TableCell align='left' width={170}>
                          {t('common:capitalize', { value: t('common:period') })}
                        </TableCell>
                        <TableCell align='center' width={125}>
                          {t('common:capitalize', { value: t('common:status') })}
                        </TableCell>
                        <TableCell align='left' width={80} />
                      </TableRow>
                    </TableHead>
                    {totalCount > 0 && !isSubmitting ? (
                      <TableBody>
                        {skills?.map((row) => (
                          <TableRow key={row.assessmentId} hover>
                            <TableCell align='left'>{row.title}</TableCell>
                            {/* <TableCell title={row.description ?? ''} align='left'>{fmtDescription(row.description)}</TableCell> */}
                            <TableCell align='left'>{fmtSkills(row.skills)}</TableCell>
                            <TableCell align='left'>
                              {moment(row.start).format('DD/MM/YY')} - {moment(row.end).format('DD/MM/YY')}
                            </TableCell>
                            <TableCell align='center'>
                              <Label
                                variant='ghost'
                                color={
                                  row.status === 'Started'
                                    ? 'success'
                                    : row.status === 'NotStarted'
                                    ? 'warning'
                                    : 'error'
                                }
                                sx={{
                                  padding: '16px 0',
                                  minWidth: '100px'
                                }}>
                                {!!row.status && translateAssessmentStatus(row.status)}
                              </Label>
                            </TableCell>
                            <TableCell align='left'>
                              <AssessmentSkillListActionMenu
                                assessmentId={row.assessmentId}
                                assessmentType={row.type}
                                fetchAssessmentSkills={fetchSkillsList}
                                setLoading={setLoading}
                                loading={loading}
                                assessmentName={row.title}
                                status={row.status}
                                hasAssessmentNodes={!!row.hasAssessmentNodes}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align='center' colSpan={6}>
                            {isSubmitting ? <CircularProgress /> : <SearchNotFound />}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[limit]}
                component='div'
                count={totalCount}
                rowsPerPage={limit}
                page={offSet - 1}
                labelRowsPerPage='Rows'
                labelDisplayedRows={({ from, to, count }) => {
                  return '' + from + '-' + to + `${t('common:TextPagination')}` + count;
                }}
                onPageChange={(e, page) => setOffset(page + 1)}
              />
            </Card>
          </Stack>
        </Container>
      </Page>
    </Loading>
  );
}
