import { useSelector } from "react-redux";

// config
import { backofficeApiConfig } from "config";

// redux
import { AuthReducerState } from "redux/reducers/authReducer";

// apis
import { AssessmentModelArrayResultHandler, AssessmentModelPaginationResult, AssessmentPerformanceModel, AssessmentPerformanceNextListParams, AssessmentPerformanceNextModel, AssessmentSendfirstwelcomeemailCreateParams, AssessmentSendemailtoappraisersDetailParams, AssessmentSendemailtoappraisersListCreateParams, BooleanResultHandler, KeyValueModel, ResourceFeedbackListParams, ResourceFeedbackModel, ResourceFeedbackPayload, ResourceInfoModel, StringStringDictionaryResultHandler, AssessmentAutoCompleteModel, AssessmentExistNameListParams } from "apis/backoffice-api/data-contracts";

// types
import { Question } from "types/Question";
import { AssessmentPerformance } from "types/AssessmentPerformance";
import { BackofficeApi } from "apis/backoffice-api";
import { RequestParams } from "apis/backoffice-api/http-client";

export const useAssessmentPerformanceService = () => {
  const { accessToken } = useSelector((state: { auth: AuthReducerState }) => state.auth);

  const backofficeApi = new BackofficeApi({
    baseURL: backofficeApiConfig.baseURL,
    headers: { "Authorization": `bearer ${accessToken}` }
  });

  return {


    startAssessment: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentStartDetail(assessmentId).then((resp) => {
        return resp.data;
      }),


    finishAssessment: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentFinishDetail(assessmentId).then((resp) => {
        return resp.data;
      }),

    assessmentFinishVerify: async (assessmentId: string): Promise<BooleanResultHandler> =>
      backofficeApi.assessmentFinishVerifyDetail(assessmentId).then((resp) => {
        return resp.data;
      }),

    assessmentPerformanceNextStatusList: async (): Promise<KeyValueModel[] | undefined | null> =>
      backofficeApi.assessmentPerformanceNextStatusList().then((resp) => resp.data.data),

    assessmentPerformanceList: async (limit?: number, offSet?: number): Promise<AssessmentModelPaginationResult | undefined> =>

      backofficeApi.assessmentPerformanceList({
        Limit: limit ?? 20,
        Offset: offSet ?? 1
      }).then((resp) => resp.data.data),

    assessmentPerformanceCreate: async (assessment: AssessmentPerformanceModel): Promise<AssessmentPerformanceModel | undefined> =>
      backofficeApi.assessmentPerformanceCreate(assessment).then((data) => data.data.data),

    assessmentPerformanceNextUpdate: async (assessment: AssessmentPerformanceNextModel | any): Promise<AssessmentPerformanceNextModel | undefined> =>
      backofficeApi.assessmentPerformanceNextUpdate(assessment).then((data) => data.data.data),

    assessmentPerformanceNextList: async (params: AssessmentPerformanceNextListParams): Promise<AssessmentModelPaginationResult | undefined> =>
      backofficeApi.assessmentPerformanceNextList(params).then((resp) => resp.data.data),

    assessmentPerformanceDetailNext: async (assessmentId: string): Promise<AssessmentPerformanceNextModel | undefined> =>
      backofficeApi.assessmentPerformanceNextDetail(assessmentId).then((data) => data.data.data),

    assessmentPerformanceCreateNext: async (assessment: AssessmentPerformanceNextModel | any): Promise<any | undefined> =>//type problem TODO
      backofficeApi.assessmentPerformanceNextCreate(assessment).then((data) => data.data.data),

    assessmentPerformanceRemoveNext: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentDelete(assessmentId).then((resp) => resp),

    assessmentChartModelList: async (resourceEmployeeId: string, assessementId: string): Promise<any> =>
      backofficeApi.assessmentChartModelDetail(resourceEmployeeId, assessementId).then((resp) => resp),

    assessmentsForchartDetail: async (resourceEmployeeId: string): Promise<AssessmentModelArrayResultHandler | undefined> =>
      backofficeApi.assessmentAssessmentsForchartDetail(resourceEmployeeId).then((resp) => resp.data),

    assessmentSendEmail: async (params: AssessmentSendemailtoappraisersDetailParams): Promise<any> =>
      backofficeApi.assessmentSendemailtoappraisersDetail(params).then((resp) => resp),

    sendEmailToNotInitiated: async (params: AssessmentSendemailtoappraisersDetailParams): Promise<any> =>
      backofficeApi.assessmentSendEmailToAppraisersNotInitiatedDetail(params).then((resp) => resp),

    assessmentSendemailtoappraisersList: async (selectedResources: ResourceInfoModel[], params: AssessmentSendemailtoappraisersListCreateParams): Promise<any> =>
      backofficeApi.assessmentSendemailtoappraisersListCreate(params, selectedResources).then((resp) => resp),

    assessmentSendFirstWelcomeEmailCreate: async (params: AssessmentSendfirstwelcomeemailCreateParams): Promise<any> =>
      backofficeApi.assessmentSendfirstwelcomeemailCreate(params).then((resp) => resp),

    assessmentAppraisersInAssessmentDetail: async (assessmentId: string): Promise<ResourceInfoModel[] | undefined | null> =>
      backofficeApi.assessmentAppraisersInAssessmentDetail(assessmentId).then((resp) => resp.data.data),


    assessmentPerformanceDetail: async (assessmentId: string): Promise<any> =>
      backofficeApi.assessmentPerformanceDetail(assessmentId).then((resp) => {
        const assessmentApi: any = {
          title: resp.data.data?.name,
          dateRange: [resp.data.data?.start, resp.data.data?.end],
          description: resp.data.data?.description,
          instructions: resp.data.data?.instructions,
          questions: resp.data.data?.questions?.map((q: any) => {
            switch (q.type) {
              case 'Label':
                return { title: q.title, type: 'section', active: true };
              case 'Alternative':
                return { title: q.title, type: 'multiple-choice', active: false, options: q.alternatives?.map((a: any) => ({ key: a.label, value: a.value.toString() })) };
              case "Text":
                return { title: q.title, type: 'text', active: true };
              default:
                throw Error("Question type not accept")
            }
          })
        };
        return assessmentApi;
      }),

    assessmentPerformanceUpdate: async (data: AssessmentPerformance): Promise<any> => {
      const questions: any = data.questions.map((q: Question) => {
        switch (q.type) {
          case "section":
            return { title: q.title, type: 'Label' };
          case "multiple-choice":
            return { title: q.title, type: 'Alternative', alternatives: q.options?.map((a) => ({ label: a.key, value: +a.value })) };
          case "text":
            return { title: q.title, type: 'Text' };
          default:
            throw Error("Question type not accept")
        }
      })
      const assessmentApi: AssessmentPerformanceModel = {
        assessmentId: data.assessmentId,
        name: data.name,
        start: data?.start?.toString(),
        end: data?.end?.toString(),
        description: data.description,
        instructions: data?.instruction,
        questions,
      };
      backofficeApi.assessmentPerformanceUpdate(assessmentApi).then((resp) => resp.data.data)
    },

    resourceFeedbackList: async (criteriaFeedback: ResourceFeedbackListParams): Promise<ResourceFeedbackModel[]> =>
      backofficeApi.resourceFeedbackList(criteriaFeedback).then((data) => data.data.data ?? []),

    resourceFeedbackCreate: async (data: ResourceFeedbackPayload): Promise<ResourceFeedbackModel | undefined> => {
      return backofficeApi.resourceFeedbackCreate(data).then((resp) => resp.data.data);
    },

    assessmentPerformanceNextAutocompleteList: async (params?: RequestParams): Promise<StringStringDictionaryResultHandler | undefined> =>
      backofficeApi.assessmentPerformanceNextAutocompleteList(params).then((resp) => resp.data.data),

    assessmentPerformanceImportedAutocompleteList: async (params?: RequestParams): Promise<AssessmentAutoCompleteModel[] | null | undefined> =>
      backofficeApi.assessmentPerformanceImportedAutocompleteList(params).then((resp) => resp.data.data),

    assessmentExistByName: async (assesmentName: string): Promise<boolean | undefined> =>
      backofficeApi.assessmentExistNameList({assessmentName: assesmentName}).then((resp) => resp.data.data),
  }
}
