export type userReducerState = {
  resourceId?: string;
  resourceEmployeeLoggedId?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  type?: string;
  profileImageUrl?: string;
  company?:string;
  companyDataStudioUrl?:string;
};

const initialState: userReducerState = {
  firstName: undefined,
  lastName: undefined,
  fullName: undefined,
  type: undefined,
  profileImageUrl: undefined
};

const userReducer = (
  state: userReducerState = initialState,
  action: any
): userReducerState => {
  switch (action.type) {
    case "FETCH_USER_DATA": {
      return {
        ...state,
        ...action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
