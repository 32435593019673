import { useEffect, useState } from 'react';

// material
import { Box, Stack, Tab } from '@mui/material';

// components
import Scrollbar from 'components/Scrollbar';
import { Loading } from 'components/Loading';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { AssessmentNodeModel, AssessmentNodeTypeEnum, ResourceInfoModel } from 'apis/backoffice-api/data-contracts';
import AppraiserList from './AppraiserList';
import { useResourceService } from 'services/Resource';
import { useTranslation } from 'react-i18next';

export type Props = {
  setAssessmentNodes: (data: AssessmentNodeModel[]) => void;
  assessmentNodes?: AssessmentNodeModel[];
  assessmentId: string;
  resourceId: string;
  isReadOnly: boolean;
};
export default function AppraiseeEdit({
  setAssessmentNodes,
  assessmentNodes,
  assessmentId,
  resourceId,
  isReadOnly
}: Props) {
  const { resourceResourceInfoAllList } = useResourceService();
  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('1');
  const [resourceInfos, setResourceInfos] = useState<ResourceInfoModel[]>([]);
  const { t } = useTranslation();

  const fetchResourceInfo = async () => {
    await resourceResourceInfoAllList()
      .then((data) => {
        setResourceInfos(data || []);
      })
      .catch(() => {});
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchResourceInfo();
      setLoading(false);
    })();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Loading loading={loading}>
      <Stack pt={2} spacing={2}>
        <Scrollbar>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label={t('common:capitalize', { value: t('assessment:relationshipWithEvaluators') })}>
                <Tab label={t('common:capitalize', { value: t('common:directManager') })} value='1' />
                <Tab label={t('common:capitalize', { value: t('common:indirectManager') })} value='2' />
                <Tab label={t('common:capitalize', { value: t('common:subordinate') })} value='3' />
                <Tab label={t('common:capitalize', { value: t('common:peer') })} value='4' />
                <Tab label={t('common:capitalize', { value: t('common:internalClient') })} value='5' />
                <Tab label={t('common:capitalize', { value: t('common:externalClient') })} value='6' />
              </TabList>
            </Box>

            <TabPanel value='1'>
              <AppraiserList
                relationshipName={'Leader'}
                assessmentId={assessmentId}
                resourceId={resourceId}
                setAssessmentNodes={setAssessmentNodes}
                assessmentNodes={assessmentNodes}
                resourceInfos={resourceInfos}
                isReadOnly={isReadOnly}></AppraiserList>
            </TabPanel>

            <TabPanel value='2'>
              <AppraiserList
                relationshipName={'LeaderIndirect'}
                assessmentId={assessmentId}
                resourceId={resourceId}
                setAssessmentNodes={setAssessmentNodes}
                assessmentNodes={assessmentNodes}
                resourceInfos={resourceInfos}
                isReadOnly={isReadOnly}></AppraiserList>
            </TabPanel>

            <TabPanel value='3'>
              <AppraiserList
                relationshipName={'Staff'}
                assessmentId={assessmentId}
                resourceId={resourceId}
                setAssessmentNodes={setAssessmentNodes}
                assessmentNodes={assessmentNodes}
                resourceInfos={resourceInfos}
                isReadOnly={isReadOnly}></AppraiserList>
            </TabPanel>

            <TabPanel value='4'>
              <AppraiserList
                relationshipName={'Peer'}
                assessmentId={assessmentId}
                resourceId={resourceId}
                setAssessmentNodes={setAssessmentNodes}
                assessmentNodes={assessmentNodes}
                resourceInfos={resourceInfos}
                isReadOnly={isReadOnly}></AppraiserList>
            </TabPanel>

            <TabPanel value='5'>
              <AppraiserList
                relationshipName={'ClientInternal'}
                assessmentId={assessmentId}
                resourceId={resourceId}
                setAssessmentNodes={setAssessmentNodes}
                assessmentNodes={assessmentNodes}
                resourceInfos={resourceInfos}
                isReadOnly={isReadOnly}></AppraiserList>
            </TabPanel>

            <TabPanel value='6'>
              <AppraiserList
                relationshipName={'ClientExternal'}
                assessmentId={assessmentId}
                resourceId={resourceId}
                setAssessmentNodes={setAssessmentNodes}
                assessmentNodes={assessmentNodes}
                resourceInfos={resourceInfos}
                isReadOnly={isReadOnly}></AppraiserList>
            </TabPanel>
          </TabContext>
        </Scrollbar>
      </Stack>
    </Loading>
  );
}
