import { MultiLanguageText, QuestionTypeEnum, ResourceInfoModel } from 'apis/backoffice-api/data-contracts';
import { createContext, ReactNode, useState } from 'react';
import { QuestionData } from '../AssessmentPerformanceEdit';

interface ResourceInfo extends ResourceInfoModel {
    name?: string
  }

type AssessmentPerformanceContextProps = {
    children: ReactNode;
};

type AssessementPerformanceContextType = {
    showResourcesList: boolean;
    setShowResourcesList: (newState: boolean) => void;
    savedResources : ResourceInfo[];
    setSavedResources : (newState: ResourceInfo[]) => void;
    selectedResourceIds : string[]; 
    setSelectedResourceIds : (newState : string[]) => void;
    currentResourcesSelection : ResourceInfo[]; 
    setCurrentResourcesSelection : (newState : ResourceInfo[]) => void;
    allFilteredResourceInfoId : string[]; 
    setAllFilteredResourceInfoId : (newState : string[]) => void;
    questions: QuestionData[]; 
    setQuestions: React.Dispatch<React.SetStateAction<QuestionData[]>>;
    title : string;
    setTitle : React.Dispatch<React.SetStateAction<string>>;
    description: MultiLanguageText;
    setDescription: React.Dispatch<React.SetStateAction<MultiLanguageText>>;
    instructions: MultiLanguageText;
    setInstructions: React.Dispatch<React.SetStateAction<MultiLanguageText>>;
    createRankingQuestion: boolean;
    setCreateRankingQuestion: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialValue = {
    showResourcesList: true,
    setShowResourcesList: () => {},
    savedResources : [], 
    setSavedResources : () => {},
    selectedResourceIds : [], 
    setSelectedResourceIds : () => {},
    currentResourcesSelection: [], 
    setCurrentResourcesSelection : () => {},
    allFilteredResourceInfoId: [], 
    setAllFilteredResourceInfoId : () => {},
    title : "", 
    setTitle : () => {},
    description : {
      ptBR: '',
      esAR: '',
      enUS: ''
    }, 
    setDescription : () => {},
    instructions : {
      ptBR: '',
      esAR: '',
      enUS: ''
    }, 
    setInstructions : () => {},
    questions : [
        {
          title: {
            ptBR: '',
            esAR: '',
            enUS: ''
          },
          description: {
            ptBR: '',
            esAR: '',
            enUS: ''
          },
          questionType: QuestionTypeEnum.Label,
          evidence: [
            {
              title: {
                ptBR: '',
                esAR: '',
                enUS: ''
              },
              description: {
                ptBR: '',
                esAR: '',
                enUS: ''
              },
              questionType: QuestionTypeEnum.Alternative
            }
          ]
        },
      ],
    setQuestions : () => {},
    createRankingQuestion: false,
    setCreateRankingQuestion : () => {},
};

export const AssessmentContext = createContext<AssessementPerformanceContextType>(initialValue);

export const AssessmentContextProvider = ({ children }: AssessmentPerformanceContextProps) => {        
    const [showResourcesList, setShowResourcesList] = useState(initialValue.showResourcesList);
    const [savedResources, setSavedResources] = useState<ResourceInfo[]>([]);
    const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>([]);
    const [currentResourcesSelection, setCurrentResourcesSelection] = useState<ResourceInfo[]>([]);
    const [allFilteredResourceInfoId, setAllFilteredResourceInfoId] = useState<string[]>([]);
    const [questions, setQuestions] = useState<QuestionData[]>(initialValue.questions);
    const [title, setTitle] = useState<string>(initialValue.title);
    const [createRankingQuestion, setCreateRankingQuestion] = useState<boolean>(initialValue.createRankingQuestion);
    const [description, setDescription] = useState<MultiLanguageText>(initialValue.description);
    const [instructions, setInstructions] = useState<MultiLanguageText>(initialValue.instructions);
    return (
        <AssessmentContext.Provider 
                value={{ 
                    showResourcesList,
                    savedResources,
                    selectedResourceIds: selectedResourceIds,
                    currentResourcesSelection: currentResourcesSelection,
                    allFilteredResourceInfoId: allFilteredResourceInfoId,
                    questions: questions,
                    title : title,
                    description: description,
                    instructions: instructions,
                    createRankingQuestion: createRankingQuestion,
                    setShowResourcesList,
                    setSavedResources,
                    setSelectedResourceIds: setSelectedResourceIds,
                    setCurrentResourcesSelection: setCurrentResourcesSelection,
                    setAllFilteredResourceInfoId: setAllFilteredResourceInfoId,
                    setQuestions: setQuestions,
                    setTitle: setTitle,
                    setDescription: setDescription,
                    setInstructions: setInstructions,
                    setCreateRankingQuestion: setCreateRankingQuestion,
                    }}>
            {children}
        </AssessmentContext.Provider>
    );

};