import { createContext, useContext, useState } from 'react';

//types
import { IStepper } from 'types/IStepper';

interface SkillsState {
  loading?: boolean;
  stepper?: IStepper;
  activeStep?: number;
  setLoading: (active?: boolean) => void;
  setStepper: (steps: string[]) => void;
  setStepperActiveStep: (activeStep?: number) => void;
}

export const AssessmentSkillsContext = createContext<SkillsState>({
  loading: false,
  stepper: [],
  activeStep: 0,
  setLoading: () => undefined,
  setStepper: () => undefined,
  setStepperActiveStep: () => undefined
});

export const AssessmentSkillsContextProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>();
  const [stepper, setStepper] = useState<string[]>();
  const [activeStep, setStepperActiveStep] = useState<number>();

  return (
    <AssessmentSkillsContext.Provider
      value={{
        loading,
        stepper,
        activeStep,
        setLoading,
        setStepper,
        setStepperActiveStep
      }}>
      {children}
    </AssessmentSkillsContext.Provider>
  );
};

export const useSkillsContext = () => useContext(AssessmentSkillsContext);
