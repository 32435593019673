// routes
import { PATH_BACKOFFICE } from 'routes/paths';

// components
import { Icon } from '@iconify/react';
import SvgIconStyle from 'components/SvgIconStyle';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  colaboradores: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig: {
  subheader: string;
  items: {
    title: string;
    path: string;
    icon: JSX.Element;
    onClick?: any;
  }[];
}[] = [
  // RESOURCES
  {
    subheader: 'common.resources',
    items: [
      {
        title: 'common.employee',
        path: PATH_BACKOFFICE.resources.employee.root,
        icon: ICONS.user
      }
    ]
  },
  // ASSESSMENTS
  {
    subheader: 'assessment.assessments',
    items: [
      {
        title: 'assessment.performance',
        path: PATH_BACKOFFICE.assessments.performance.root,
        icon: <Icon icon='healthicons:i-schedule-school-date-time' style={{ fontSize: '24px' }} />
      },
      {
        title: 'skill.skills',
        path: PATH_BACKOFFICE.assessments.skills.root,
        icon: ICONS.kanban
      }
    ]
  },
  // DATA STUDIO
  {
    subheader: 'common.results',
    items: [
      // {
      //   title: 'nBox.nBox',
      //   path: PATH_BACKOFFICE.results.nbox.root,
      //   icon: <Icon icon='heroicons-solid:document-report' style={{ fontSize: '24px' }} />
      // },
      {
        title: 'common.dataStudio',
        path: '',
        icon: ICONS.analytics,
        onClick: (e: any) => {
          if (localStorage.getItem('companyDataStudioUrl') != '') {
            window.open(localStorage.getItem('companyDataStudioUrl') ?? '', '_blank');
          } else {
            const language = localStorage.getItem('i18nextLng');
            switch (language) {
              case 'ptBR':
                alert('Data Studio não encontrado');
                break;
              case 'enUS':
                alert('Data Studio not found');
                break;
              case 'esES':
                alert('Data Studio no encontrado');
                break;
            }
          }
        }
      }
    ]
  }
];

export default sidebarConfig;
