/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AssessmentAutoCompleteModelArrayResultHandler,
  AssessmentEmailTemplateModel,
  AssessmentEmailTemplateModelResultHandler,
  AssessmentExistNameListParams,
  AssessmentModelArrayResultHandler,
  AssessmentModelPaginationResultResultHandler,
  AssessmentModelResultHandler,
  AssessmentNodeAppraisersUpdateParams,
  AssessmentNodeAssessmentNodesCreateParams,
  AssessmentNodeModel,
  AssessmentNodeModelArrayResultHandler,
  AssessmentNodeSkillAssessmentNodesCreateParams,
  AssessmentNodeTypeEnum,
  AssessmentPerformanceListParams,
  AssessmentPerformanceModel,
  AssessmentPerformanceModelResultHandler,
  AssessmentPerformanceNextAssessmentStatusListParams,
  AssessmentPerformanceNextListParams,
  AssessmentPerformanceNextModel,
  AssessmentPerformanceNextModelPaginationResultResultHandler,
  AssessmentPerformanceNextModelResultHandler,
  AssessmentReportModelPaginationResultResultHandler,
  AssessmentSendemailtoappraisersDetailParams,
  AssessmentSendemailtoappraisersListCreateParams,
  AssessmentSendEmailToAppraisersNotInitiatedDetailParams,
  AssessmentSendfirstwelcomeemailCreateParams,
  AssessmentSkillCriteria,
  AssessmentSkillListItemModel,
  AssessmentSkillListItemModelPaginationResultResultHandler,
  AssessmentSkillListItemModelResultHandler,
  AssessmentSkillListParams,
  BooleanResultHandler,
  ChangePasswordCriteria,
  ChartModelResultHandler,
  CompanyModelResultHandler,
  CsvErrorModelResultHandler,
  ImportedResourceEmployeeCsvResultModelResultHandler,
  Int32Int32DictionaryResultHandler,
  Int32ProfilingResourcePointInfoModelArrayDictionaryResultHandler,
  KeyValueModelArrayResultHandler,
  ProfilingExternalCreateParams,
  ProfilingListParams,
  ProfilingModelPaginationResultResultHandler,
  ProfilingModelResultHandler,
  ProfilingPayload,
  ProfilingProfilingResourcePointFirstThreeListParams,
  ProfilingProfilingResourcePointPositionUpdateParams,
  ProfilingResourcePayload,
  ProfilingResourcePayloadArrayResultHandler,
  ProfilingResourcePointInfoCriteria,
  ProfilingResourcePointInfoModelPaginationResultResultHandler,
  ProfilingResourcePointModelResultHandler,
  QuestionInfoModelPaginationResultResultHandler,
  QuestionnaireCompetenceLibraryListParams,
  ReportGenerateBulkBackgroundCreateParams,
  ReportPerformanceListParams,
  ReportResetStatusCreateParams,
  ResourceAutocompleteListParams,
  ResourceClientListParams,
  ResourceClientModelPaginationResultResultHandler,
  ResourceClientModelResultHandler,
  ResourceClientPayload,
  ResourceEmployeeGoalListParams,
  ResourceEmployeeGoalModel,
  ResourceEmployeeGoalModelPaginationResultResultHandler,
  ResourceEmployeeGoalModelResultHandler,
  ResourceEmployeeListItemModelPaginationResultResultHandler,
  ResourceEmployeeListParams,
  ResourceEmployeeModelResultHandler,
  ResourceEmployeePayload,
  ResourceEmployeeVerifyEmailExistanceCreateParams,
  ResourceFeedbackListParams,
  ResourceFeedbackModelArrayResultHandler,
  ResourceFeedbackModelResultHandler,
  ResourceFeedbackPayload,
  ResourceInfoCriteria,
  ResourceInfoModel,
  ResourceInfoModelArrayResultHandler,
  ResourceInfoModelPaginationResultResultHandler,
  ResourceInfoProfilingCriteria,
  ResourceModelArrayResultHandler,
  ResourceModelResultHandler,
  ResourceNodeCreateParams,
  ResourceNodeDeleteParams,
  ResourceNodeDetailParams,
  ResourceResourceInfoAllFilteredListParams,
  ResourceResourceInfoIdsListParams,
  ResourceResourceInfoListParams,
  ResultHandler,
  SkillAutoCompleteCriteria,
  SkillAverageModelArrayResultHandler,
  StringArrayResultHandler,
  StringResultHandler,
  StringStringDictionaryResultHandler
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class BackofficeApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentStartDetail
   * @summary Start assessment.
   * @request GET:/api/assessment/start/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentStartDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/assessment/start/${assessmentId}`,
      method: 'GET',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentFinishVerifyDetail
   * @summary Finish assessment.
   * @request GET:/api/assessment/finish/verify/{assessmentId}
   * @response `200` `BooleanResultHandler` Success
   */
  assessmentFinishVerifyDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<BooleanResultHandler, any>({
      path: `/api/assessment/finish/verify/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentFinishDetail
   * @summary Finish assessment.
   * @request GET:/api/assessment/finish/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentFinishDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/assessment/finish/${assessmentId}`,
      method: 'GET',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentExistNameList
   * @summary Existses the name of the assessment by.
   * @request GET:/api/assessment/exist/name
   * @response `200` `BooleanResultHandler` Success
   */
  assessmentExistNameList = (query: AssessmentExistNameListParams, params: RequestParams = {}) =>
    this.request<BooleanResultHandler, any>({
      path: `/api/assessment/exist/name`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentAppraisersInAssessmentDetail
   * @summary Get all appraisers into a assessment asynchronous.
   * @request GET:/api/assessment/appraisersInAssessment/{assessmentId}
   * @response `200` `ResourceInfoModelArrayResultHandler` Success
   */
  assessmentAppraisersInAssessmentDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<ResourceInfoModelArrayResultHandler, any>({
      path: `/api/assessment/appraisersInAssessment/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceList
   * @summary Gets the assessments performance.
   * @request GET:/api/assessment/performance
   * @response `200` `AssessmentModelPaginationResultResultHandler` Success
   */
  assessmentPerformanceList = (query: AssessmentPerformanceListParams, params: RequestParams = {}) =>
    this.request<AssessmentModelPaginationResultResultHandler, any>({
      path: `/api/assessment/performance`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceCreate
   * @summary Adds the assessment performance.
   * @request POST:/api/assessment/performance
   * @response `200` `AssessmentPerformanceModelResultHandler` Success
   */
  assessmentPerformanceCreate = (data: AssessmentPerformanceModel, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceModelResultHandler, any>({
      path: `/api/assessment/performance`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceUpdate
   * @summary Adds the assessment performance.
   * @request PUT:/api/assessment/performance
   * @response `200` `AssessmentPerformanceModelResultHandler` Success
   */
  assessmentPerformanceUpdate = (data: AssessmentPerformanceModel, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceModelResultHandler, any>({
      path: `/api/assessment/performance`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceDetail
   * @summary Gets the assessment performance.
   * @request GET:/api/assessment/performance/{assessmentId}
   * @response `200` `AssessmentPerformanceModelResultHandler` Success
   */
  assessmentPerformanceDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceModelResultHandler, any>({
      path: `/api/assessment/performance/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceNextDetail
   * @summary Gets the assessment performance.
   * @request GET:/api/assessment/performance/next/{assessmentId}
   * @response `200` `AssessmentPerformanceNextModelResultHandler` Success
   */
  assessmentPerformanceNextDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceNextModelResultHandler, any>({
      path: `/api/assessment/performance/next/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceNextList
   * @summary Gets the assessment performance.
   * @request GET:/api/assessment/performance/next
   * @response `200` `AssessmentPerformanceNextModelPaginationResultResultHandler` Success
   */
  assessmentPerformanceNextList = (query: AssessmentPerformanceNextListParams, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceNextModelPaginationResultResultHandler, any>({
      path: `/api/assessment/performance/next`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceNextCreate
   * @summary Adds the assessment performance.
   * @request POST:/api/assessment/performance/next
   * @response `200` `AssessmentPerformanceNextModelResultHandler` Success
   */
  assessmentPerformanceNextCreate = (data: AssessmentPerformanceNextModel, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceNextModelResultHandler, any>({
      path: `/api/assessment/performance/next`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceNextUpdate
   * @summary Gets the assessment performance.
   * @request PUT:/api/assessment/performance/next
   * @response `200` `AssessmentPerformanceNextModelResultHandler` Success
   */
  assessmentPerformanceNextUpdate = (data: AssessmentPerformanceNextModel, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceNextModelResultHandler, any>({
      path: `/api/assessment/performance/next`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceNextAutocompleteList
   * @summary Gets the assessment performance.
   * @request GET:/api/assessment/performance/next/autocomplete
   * @response `200` `StringStringDictionaryResultHandler` Success
   */
  assessmentPerformanceNextAutocompleteList = (params: RequestParams = {}) =>
    this.request<StringStringDictionaryResultHandler, any>({
      path: `/api/assessment/performance/next/autocomplete`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceNextStatusList
   * @summary Get the assessment status list.
   * @request GET:/api/assessment/performance/next/status
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  assessmentPerformanceNextStatusList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/assessment/performance/next/status`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceNextAssessmentStatusList
   * @summary Gets the assessment by status.
   * @request GET:/api/assessment/performance/next/assessment/status
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  assessmentPerformanceNextAssessmentStatusList = (
    query: AssessmentPerformanceNextAssessmentStatusListParams,
    params: RequestParams = {}
  ) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/assessment/performance/next/assessment/status`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentPerformanceImportedAutocompleteList
   * @summary Gets the assessment performance.
   * @request GET:/api/assessment/performance/imported/autocomplete
   * @response `200` `AssessmentAutoCompleteModelArrayResultHandler` Success
   */
  assessmentPerformanceImportedAutocompleteList = (params: RequestParams = {}) =>
    this.request<AssessmentAutoCompleteModelArrayResultHandler, any>({
      path: `/api/assessment/performance/imported/autocomplete`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillList
   * @summary Gets the assessment skill.
   * @request GET:/api/assessment/skill
   * @response `200` `AssessmentSkillListItemModelPaginationResultResultHandler` Success
   */
  assessmentSkillList = (query: AssessmentSkillListParams, params: RequestParams = {}) =>
    this.request<AssessmentSkillListItemModelPaginationResultResultHandler, any>({
      path: `/api/assessment/skill`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillCreate
   * @summary Add the assessment skill.
   * @request POST:/api/assessment/skill
   * @response `200` `AssessmentModelResultHandler` Success
   */
  assessmentSkillCreate = (data: AssessmentSkillListItemModel, params: RequestParams = {}) =>
    this.request<AssessmentModelResultHandler, any>({
      path: `/api/assessment/skill`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillUpdate
   * @summary updates the assessment skill.
   * @request PUT:/api/assessment/skill
   * @response `200` `AssessmentModelResultHandler` Success
   */
  assessmentSkillUpdate = (data: AssessmentSkillListItemModel, params: RequestParams = {}) =>
    this.request<AssessmentModelResultHandler, any>({
      path: `/api/assessment/skill`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillDetail
   * @summary Gets the assessment skill model.
   * @request GET:/api/assessment/skill/{assessmentId}
   * @response `200` `AssessmentSkillListItemModelResultHandler` Success
   */
  assessmentSkillDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<AssessmentSkillListItemModelResultHandler, any>({
      path: `/api/assessment/skill/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillDelete
   * @summary Delete the assessment skill.
   * @request DELETE:/api/assessment/skill/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentSkillDelete = (assessmentId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/assessment/skill/${assessmentId}`,
      method: 'DELETE',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentStartSkillDetail
   * @summary Start assessment skill.
   * @request GET:/api/assessment/start/skill/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentStartSkillDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/assessment/start/skill/${assessmentId}`,
      method: 'GET',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentNewPeriodSkillDetail
   * @summary Create new period for the assessment skill.
   * @request GET:/api/assessment/new-period/skill/{assessmentId}
   * @response `200` `AssessmentModelResultHandler` Success
   */
  assessmentNewPeriodSkillDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<AssessmentModelResultHandler, any>({
      path: `/api/assessment/new-period/skill/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentFinishSkillDetail
   * @summary Finish assessment skill.
   * @request GET:/api/assessment/finish/skill/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentFinishSkillDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/assessment/finish/skill/${assessmentId}`,
      method: 'GET',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillAnswerByAssessmentIdAndResourceIdAsyncDetail
   * @summary Gets the assessment skill answer by assessment identifier and resource identifier asynchronous.
   * @request GET:/api/assessment/skillAnswerByAssessmentIdAndResourceIdAsync/{assessmentId}/{resourceEmployeeId}/{selfAverageAnswer}
   * @response `200` `SkillAverageModelArrayResultHandler` Success
   */
  assessmentSkillAnswerByAssessmentIdAndResourceIdAsyncDetail = (
    assessmentId: string,
    resourceEmployeeId: string,
    selfAverageAnswer: boolean,
    params: RequestParams = {}
  ) =>
    this.request<SkillAverageModelArrayResultHandler, any>({
      path: `/api/assessment/skillAnswerByAssessmentIdAndResourceIdAsync/${assessmentId}/${resourceEmployeeId}/${selfAverageAnswer}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentChartModelDetail
   * @summary Gets the chart model.
   * @request GET:/api/assessment/chartModel/{resourceEmployeeId}/{assessmentId}
   * @response `200` `ChartModelResultHandler` Success
   */
  assessmentChartModelDetail = (resourceEmployeeId: string, assessmentId: string, params: RequestParams = {}) =>
    this.request<ChartModelResultHandler, any>({
      path: `/api/assessment/chartModel/${resourceEmployeeId}/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentAssessmentsForchartDetail
   * @summary Get assessments by ResourceId for ChartRadar
   * @request GET:/api/assessment/assessmentsForchart/{resourceEmployeeId}
   * @response `200` `AssessmentModelArrayResultHandler` Success
   */
  assessmentAssessmentsForchartDetail = (resourceEmployeeId: string, params: RequestParams = {}) =>
    this.request<AssessmentModelArrayResultHandler, any>({
      path: `/api/assessment/assessmentsForchart/${resourceEmployeeId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillAnswersDetail
   * @summary Gets the assessment skill answers asynchronous.
   * @request GET:/api/assessment/skillAnswers/{resourceEmployeeId}
   * @response `200` `AssessmentModelArrayResultHandler` Success
   */
  assessmentSkillAnswersDetail = (resourceEmployeeId: string, params: RequestParams = {}) =>
    this.request<AssessmentModelArrayResultHandler, any>({
      path: `/api/assessment/skillAnswers/${resourceEmployeeId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSendemailtoappraisersDetail
   * @summary Sends email to Appraisers an assessment.
   * @request GET:/api/assessment/sendemailtoappraisers/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentSendemailtoappraisersDetail = (
    { assessmentId, ...query }: AssessmentSendemailtoappraisersDetailParams,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/assessment/sendemailtoappraisers/${assessmentId}`,
      method: 'GET',
      query: query,
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSendemailtoappraisersListCreate
   * @request POST:/api/assessment/sendemailtoappraisersList
   * @response `200` `void` Success
   */
  assessmentSendemailtoappraisersListCreate = (
    query: AssessmentSendemailtoappraisersListCreateParams,
    data: ResourceInfoModel[],
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/assessment/sendemailtoappraisersList`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSendfirstwelcomeemailCreate
   * @summary Sends the first welcome email.
   * @request POST:/api/assessment/sendfirstwelcomeemail/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentSendfirstwelcomeemailCreate = (
    { assessmentId, ...query }: AssessmentSendfirstwelcomeemailCreateParams,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/assessment/sendfirstwelcomeemail/${assessmentId}`,
      method: 'POST',
      query: query,
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSendEmailToAppraisersNotInitiatedDetail
   * @summary Sends email to "Not Initiated" Appraisers an assessment.
   * @request GET:/api/assessment/SendEmailToAppraisersNotInitiated/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentSendEmailToAppraisersNotInitiatedDetail = (
    { assessmentId, ...query }: AssessmentSendEmailToAppraisersNotInitiatedDetailParams,
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/assessment/SendEmailToAppraisersNotInitiated/${assessmentId}`,
      method: 'GET',
      query: query,
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentEmailtemplateList
   * @summary Gets the assessment email template.
   * @request GET:/api/assessment/emailtemplate
   * @response `200` `AssessmentEmailTemplateModelResultHandler` Success
   */
  assessmentEmailtemplateList = (params: RequestParams = {}) =>
    this.request<AssessmentEmailTemplateModelResultHandler, any>({
      path: `/api/assessment/emailtemplate`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentEmailtemplateCreate
   * @summary Adds the assessment email template.
   * @request POST:/api/assessment/emailtemplate
   * @response `200` `AssessmentPerformanceModelResultHandler` Success
   */
  assessmentEmailtemplateCreate = (data: AssessmentEmailTemplateModel, params: RequestParams = {}) =>
    this.request<AssessmentPerformanceModelResultHandler, any>({
      path: `/api/assessment/emailtemplate`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillListCreate
   * @summary Gets the assessment skill.
   * @request POST:/api/assessment/skill/list
   * @response `200` `AssessmentSkillListItemModelPaginationResultResultHandler` Success
   */
  assessmentSkillListCreate = (data: AssessmentSkillCriteria, params: RequestParams = {}) =>
    this.request<AssessmentSkillListItemModelPaginationResultResultHandler, any>({
      path: `/api/assessment/skill/list`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentSkillAutocompleteCreate
   * @summary Autocomplete the specified data.
   * @request POST:/api/assessment/skill/autocomplete
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  assessmentSkillAutocompleteCreate = (data: SkillAutoCompleteCriteria, params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/assessment/skill/autocomplete`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Assessment
   * @name AssessmentDelete
   * @summary Delete the assessment not started.
   * @request DELETE:/api/assessment/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentDelete = (assessmentId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/assessment/${assessmentId}`,
      method: 'DELETE',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeSkillAssessmentNodesCreate
   * @summary Adds the assessment resource relationships.
   * @request POST:/api/AssessmentNode/skill/assessmentNodes
   * @response `200` `AssessmentNodeModelArrayResultHandler` Success
   */
  assessmentNodeSkillAssessmentNodesCreate = (
    query: AssessmentNodeSkillAssessmentNodesCreateParams,
    data: AssessmentNodeModel[],
    params: RequestParams = {}
  ) =>
    this.request<AssessmentNodeModelArrayResultHandler, any>({
      path: `/api/AssessmentNode/skill/assessmentNodes`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeAssessmentNodesCreate
   * @summary Adds the assessment resource relationships.
   * @request POST:/api/AssessmentNode/assessmentNodes
   * @response `200` `void` Success
   */
  assessmentNodeAssessmentNodesCreate = (
    query: AssessmentNodeAssessmentNodesCreateParams,
    data: AssessmentNodeModel[],
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/AssessmentNode/assessmentNodes`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeAppraisersUpdate
   * @summary Updates the appraisers in AssessmentNodes.
   * @request PUT:/api/AssessmentNode/appraisers
   * @response `200` `AssessmentNodeModelArrayResultHandler` Success
   */
  assessmentNodeAppraisersUpdate = (
    query: AssessmentNodeAppraisersUpdateParams,
    data: AssessmentNodeModel[],
    params: RequestParams = {}
  ) =>
    this.request<AssessmentNodeModelArrayResultHandler, any>({
      path: `/api/AssessmentNode/appraisers`,
      method: 'PUT',
      query: query,
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeAssessmentDetail
   * @summary Gets the assessmentNodes by assessmentId .
   * @request GET:/api/AssessmentNode/assessment/{assessmentId}
   * @response `200` `AssessmentNodeModelArrayResultHandler` Success
   */
  assessmentNodeAssessmentDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<AssessmentNodeModelArrayResultHandler, any>({
      path: `/api/AssessmentNode/assessment/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeSelfsDetail
   * @summary Gets the assessmentNodes by assessmentId .
   * @request GET:/api/AssessmentNode/selfs/{assessmentId}
   * @response `200` `AssessmentNodeModelArrayResultHandler` Success
   */
  assessmentNodeSelfsDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<AssessmentNodeModelArrayResultHandler, any>({
      path: `/api/AssessmentNode/selfs/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeAppraisersDetail
   * @summary Gets the assessment node by appraisee identifier.
   * @request GET:/api/AssessmentNode/appraisers/{assessmentId}/{appraiseeId}
   * @response `200` `AssessmentNodeModelArrayResultHandler` Success
   */
  assessmentNodeAppraisersDetail = (assessmentId: string, appraiseeId: string, params: RequestParams = {}) =>
    this.request<AssessmentNodeModelArrayResultHandler, any>({
      path: `/api/AssessmentNode/appraisers/${assessmentId}/${appraiseeId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeAssessmentNodeTypeDetail
   * @summary Gets the type of the assessment node by assessment identifier and.
   * @request GET:/api/AssessmentNode/assessment/nodeType/{assessmentId}/{type}
   * @response `200` `AssessmentNodeModelArrayResultHandler` Success
   */
  assessmentNodeAssessmentNodeTypeDetail = (
    assessmentId: string,
    type: AssessmentNodeTypeEnum,
    params: RequestParams = {}
  ) =>
    this.request<AssessmentNodeModelArrayResultHandler, any>({
      path: `/api/AssessmentNode/assessment/nodeType/${assessmentId}/${type}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeCountAssessmentNodesDetail
   * @summary Get count participants on the assessmentNodes by assessmentId .
   * @request GET:/api/AssessmentNode/countAssessmentNodes/{assessmentId}
   * @response `200` `StringResultHandler` Success
   */
  assessmentNodeCountAssessmentNodesDetail = (assessmentId: string, params: RequestParams = {}) =>
    this.request<StringResultHandler, any>({
      path: `/api/AssessmentNode/countAssessmentNodes/${assessmentId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeUploadCreate
   * @summary Receive a .xlsx file to generate the AssessmentNodeModel[] assessment participants.
   * @request POST:/api/AssessmentNode/upload/{assessmentId}
   * @response `200` `void` Success
   */
  assessmentNodeUploadCreate = (assessmentId: string, data: { fileUpload?: File }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/AssessmentNode/upload/${assessmentId}`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      ...params
    });
  /**
   * No description
   *
   * @tags AssessmentNode
   * @name AssessmentNodeUploadSkillCreate
   * @summary Receive a .xlsx file to generate the AssessmentNodeModel[] assessment participants.
   * @request POST:/api/AssessmentNode/upload/skill/{assessmentId}
   * @response `200` `AssessmentNodeModelArrayResultHandler` Success
   */
  assessmentNodeUploadSkillCreate = (assessmentId: string, data: { fileUpload?: File }, params: RequestParams = {}) =>
    this.request<AssessmentNodeModelArrayResultHandler, any>({
      path: `/api/AssessmentNode/upload/skill/${assessmentId}`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Company
   * @name CompanyList
   * @summary Gets the company by identifier.
   * @request GET:/api/company
   * @response `200` `CompanyModelResultHandler` Success
   */
  companyList = (params: RequestParams = {}) =>
    this.request<CompanyModelResultHandler, any>({
      path: `/api/company`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Company
   * @name CompanyInfoList
   * @summary Gets the company by identifier.
   * @request GET:/api/company/info
   * @response `200` `CompanyModelResultHandler` Success
   */
  companyInfoList = (params: RequestParams = {}) =>
    this.request<CompanyModelResultHandler, any>({
      path: `/api/company/info`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingList
   * @summary Gets profiling.
   * @request GET:/api/profiling
   * @response `200` `ProfilingModelPaginationResultResultHandler` Success
   */
  profilingList = (query: ProfilingListParams, params: RequestParams = {}) =>
    this.request<ProfilingModelPaginationResultResultHandler, any>({
      path: `/api/profiling`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingCreate
   * @summary Adds the profiling asynchronous.
   * @request POST:/api/profiling
   * @response `200` `ProfilingModelResultHandler` Success
   */
  profilingCreate = (data: ProfilingPayload, params: RequestParams = {}) =>
    this.request<ProfilingModelResultHandler, any>({
      path: `/api/profiling`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingUpdate
   * @summary Updates profiling asynchronous.
   * @request PUT:/api/profiling
   * @response `200` `ProfilingModelResultHandler` Success
   */
  profilingUpdate = (data: ProfilingPayload, params: RequestParams = {}) =>
    this.request<ProfilingModelResultHandler, any>({
      path: `/api/profiling`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingDetail
   * @summary Get profiling.
   * @request GET:/api/profiling/{profilingId}
   * @response `200` `ProfilingModelResultHandler` Success
   */
  profilingDetail = (profilingId: string, params: RequestParams = {}) =>
    this.request<ProfilingModelResultHandler, any>({
      path: `/api/profiling/${profilingId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingDelete
   * @summary Remove the profiling async.
   * @request DELETE:/api/profiling/{profilingId}
   * @response `200` `ProfilingModelResultHandler` Success
   */
  profilingDelete = (profilingId: string, params: RequestParams = {}) =>
    this.request<ProfilingModelResultHandler, any>({
      path: `/api/profiling/${profilingId}`,
      method: 'DELETE',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingExternalCreate
   * @summary Creates the external variable asynchronous.
   * @request POST:/api/profiling/external
   * @response `200` `CsvErrorModelResultHandler` Success
   */
  profilingExternalCreate = (query: ProfilingExternalCreateParams, data: { file?: File }, params: RequestParams = {}) =>
    this.request<CsvErrorModelResultHandler, any>({
      path: `/api/profiling/external`,
      method: 'POST',
      query: query,
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingStatusList
   * @summary Gets the profiling status.
   * @request GET:/api/profiling/Status
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  profilingStatusList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/profiling/Status`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointCreate
   * @summary Creates the profiling resource point.
   * @request POST:/api/profiling/profilingResourcePoint/{profilingId}
   * @response `200` `void` Success
   */
  profilingProfilingResourcePointCreate = (
    profilingId: string,
    data: ProfilingResourcePayload[],
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/profiling/profilingResourcePoint/${profilingId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointUpdate
   * @summary Updates the profiling resource point.
   * @request PUT:/api/profiling/profilingResourcePoint/{profilingId}
   * @response `200` `void` Success
   */
  profilingProfilingResourcePointUpdate = (
    profilingId: string,
    data: ProfilingResourcePayload[],
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/profiling/profilingResourcePoint/${profilingId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointDetail
   * @summary Gets the resource point by profiling asynchronous.
   * @request GET:/api/profiling/profilingResourcePoint/{profilingId}
   * @response `200` `ProfilingResourcePayloadArrayResultHandler` Success
   */
  profilingProfilingResourcePointDetail = (profilingId: string, params: RequestParams = {}) =>
    this.request<ProfilingResourcePayloadArrayResultHandler, any>({
      path: `/api/profiling/profilingResourcePoint/${profilingId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointAssessmentIdsCreate
   * @summary Creates the profiling resource point by assessment ids.
   * @request POST:/api/profiling/profilingResourcePoint/assessmentIds/{profilingId}
   * @response `200` `void` Success
   */
  profilingProfilingResourcePointAssessmentIdsCreate = (
    profilingId: string,
    data: string[],
    params: RequestParams = {}
  ) =>
    this.request<void, any>({
      path: `/api/profiling/profilingResourcePoint/assessmentIds/${profilingId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointPositionUpdate
   * @summary Updates Profiling Resource Position asynchronous.
   * @request PUT:/api/profiling/profilingResourcePoint/position
   * @response `200` `ProfilingResourcePointModelResultHandler` Success
   */
  profilingProfilingResourcePointPositionUpdate = (
    query: ProfilingProfilingResourcePointPositionUpdateParams,
    params: RequestParams = {}
  ) =>
    this.request<ProfilingResourcePointModelResultHandler, any>({
      path: `/api/profiling/profilingResourcePoint/position`,
      method: 'PUT',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointPositionResourcesCreate
   * @summary Gets the resource employee byposition and profiling asynchronous.
   * @request POST:/api/profiling/profilingResourcePoint/position/resources
   * @response `200` `ProfilingResourcePointInfoModelPaginationResultResultHandler` Success
   */
  profilingProfilingResourcePointPositionResourcesCreate = (
    data: ProfilingResourcePointInfoCriteria,
    params: RequestParams = {}
  ) =>
    this.request<ProfilingResourcePointInfoModelPaginationResultResultHandler, any>({
      path: `/api/profiling/profilingResourcePoint/position/resources`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointFirstThreeList
   * @summary Gets the first three for each position last modified by profiling identifier asynchronous.
   * @request GET:/api/profiling/profilingResourcePoint/first/three
   * @response `200` `Int32ProfilingResourcePointInfoModelArrayDictionaryResultHandler` Success
   */
  profilingProfilingResourcePointFirstThreeList = (
    query: ProfilingProfilingResourcePointFirstThreeListParams,
    params: RequestParams = {}
  ) =>
    this.request<Int32ProfilingResourcePointInfoModelArrayDictionaryResultHandler, any>({
      path: `/api/profiling/profilingResourcePoint/first/three`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointCountPositionsDetail
   * @summary Counts for each position last modified by profiling identifier asynchronous.
   * @request GET:/api/profiling/profilingResourcePoint/count/positions/{profilingId}
   * @response `200` `Int32Int32DictionaryResultHandler` Success
   */
  profilingProfilingResourcePointCountPositionsDetail = (profilingId: string, params: RequestParams = {}) =>
    this.request<Int32Int32DictionaryResultHandler, any>({
      path: `/api/profiling/profilingResourcePoint/count/positions/${profilingId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Profiling
   * @name ProfilingProfilingResourcePointPaginatedNotInAllAssessmentsCreate
   * @request POST:/api/profiling/profilingResourcePoint/paginated/notInAllAssessments
   * @response `200` `ProfilingResourcePointInfoModelPaginationResultResultHandler` Success
   */
  profilingProfilingResourcePointPaginatedNotInAllAssessmentsCreate = (
    data: ProfilingResourcePointInfoCriteria,
    params: RequestParams = {}
  ) =>
    this.request<ProfilingResourcePointInfoModelPaginationResultResultHandler, any>({
      path: `/api/profiling/profilingResourcePoint/paginated/notInAllAssessments`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Questionnaire
   * @name QuestionnaireCompetenceLibraryList
   * @summary Delete the assessment not started.
   * @request GET:/api/questionnaire/CompetenceLibrary
   * @response `200` `QuestionInfoModelPaginationResultResultHandler` Success
   */
  questionnaireCompetenceLibraryList = (query: QuestionnaireCompetenceLibraryListParams, params: RequestParams = {}) =>
    this.request<QuestionInfoModelPaginationResultResultHandler, any>({
      path: `/api/questionnaire/CompetenceLibrary`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Questionnaire
   * @name QuestionnaireAlternativesTypeList
   * @summary Gets the alternatives type.
   * @request GET:/api/questionnaire/AlternativesType
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  questionnaireAlternativesTypeList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/questionnaire/AlternativesType`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Report
   * @name ReportDetail
   * @summary Generates the report performance asynchronous.
   * @request GET:/api/report/{appraiseeId}
   * @response `200` `void` Success
   */
  reportDetail = (appraiseeId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/report/${appraiseeId}`,
      method: 'GET',
      ...params
    });
  /**
   * No description
   *
   * @tags Report
   * @name ReportGenerateBulkCreate
   * @summary Generates the report performance bulk asynchronous.
   * @request POST:/api/report/generateBulk
   * @response `200` `void` Success
   */
  reportGenerateBulkCreate = (data: number[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/report/generateBulk`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags Report
   * @name ReportGenerateBulkBackgroundCreate
   * @summary Generates the bulk background.
   * @request POST:/api/report/generateBulkBackground
   * @response `200` `void` Success
   */
  reportGenerateBulkBackgroundCreate = (query: ReportGenerateBulkBackgroundCreateParams, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/report/generateBulkBackground`,
      method: 'POST',
      query: query,
      ...params
    });
  /**
   * No description
   *
   * @tags Report
   * @name ReportResetStatusCreate
   * @summary Resets all status by assessment identifier.
   * @request POST:/api/report/reset/status
   * @response `200` `void` Success
   */
  reportResetStatusCreate = (query: ReportResetStatusCreateParams, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/report/reset/status`,
      method: 'POST',
      query: query,
      ...params
    });
  /**
   * No description
   *
   * @tags Report
   * @name ReportPerformanceList
   * @summary Get performance reports the assessment
   * @request GET:/api/report/performance
   * @response `200` `AssessmentReportModelPaginationResultResultHandler` Success
   */
  reportPerformanceList = (query: ReportPerformanceListParams, params: RequestParams = {}) =>
    this.request<AssessmentReportModelPaginationResultResultHandler, any>({
      path: `/api/report/performance`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceStatusList
   * @summary Gets the status.
   * @request GET:/api/resource/status
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  resourceStatusList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/resource/status`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourcesList
   * @summary Gets the resources.
   * @request GET:/api/resource/resources
   * @response `200` `void` Success
   */
  resourceResourcesList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/resource/resources`,
      method: 'GET',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceAutocompleteList
   * @summary Gets resource employee by name.
   * @request GET:/api/resource/autocomplete
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  resourceAutocompleteList = (query: ResourceAutocompleteListParams, params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/resource/autocomplete`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceNodeDetail
   * @summary Gets the resource nodes.
   * @request GET:/api/resource/{resourceId}/node
   * @response `200` `ResourceModelArrayResultHandler` Success
   */
  resourceNodeDetail = ({ resourceId, ...query }: ResourceNodeDetailParams, params: RequestParams = {}) =>
    this.request<ResourceModelArrayResultHandler, any>({
      path: `/api/resource/${resourceId}/node`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceNodeCreate
   * @summary Adds the resource node.
   * @request POST:/api/resource/{resourceId}/node
   * @response `200` `ResultHandler` Success
   */
  resourceNodeCreate = ({ resourceId, ...query }: ResourceNodeCreateParams, params: RequestParams = {}) =>
    this.request<ResultHandler, any>({
      path: `/api/resource/${resourceId}/node`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceNodeDelete
   * @summary Removes the resource node.
   * @request DELETE:/api/resource/{resourceId}/node
   * @response `200` `ResultHandler` Success
   */
  resourceNodeDelete = ({ resourceId, ...query }: ResourceNodeDeleteParams, params: RequestParams = {}) =>
    this.request<ResultHandler, any>({
      path: `/api/resource/${resourceId}/node`,
      method: 'DELETE',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceDeletePartialUpdate
   * @summary Deletes the resource employee.
   * @request PATCH:/api/resource/{resourceEmployeeId}/delete
   * @response `200` `ResultHandler` Success
   */
  resourceDeletePartialUpdate = (resourceEmployeeId: string, params: RequestParams = {}) =>
    this.request<ResultHandler, any>({
      path: `/api/resource/${resourceEmployeeId}/delete`,
      method: 'PATCH',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceInactivePartialUpdate
   * @summary Inactive the resource employee.
   * @request PATCH:/api/resource/{resourceEmployeeId}/inactive
   * @response `200` `ResultHandler` Success
   */
  resourceInactivePartialUpdate = (resourceEmployeeId: string, params: RequestParams = {}) =>
    this.request<ResultHandler, any>({
      path: `/api/resource/${resourceEmployeeId}/inactive`,
      method: 'PATCH',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceActivePartialUpdate
   * @summary Actives the resource employee.
   * @request PATCH:/api/resource/{resourceEmployeeId}/active
   * @response `200` `ResultHandler` Success
   */
  resourceActivePartialUpdate = (resourceEmployeeId: string, params: RequestParams = {}) =>
    this.request<ResultHandler, any>({
      path: `/api/resource/${resourceEmployeeId}/active`,
      method: 'PATCH',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceFeedbackList
   * @summary Gets the resource feedback.
   * @request GET:/api/resource/feedback
   * @response `200` `ResourceFeedbackModelArrayResultHandler` Success
   */
  resourceFeedbackList = (query: ResourceFeedbackListParams, params: RequestParams = {}) =>
    this.request<ResourceFeedbackModelArrayResultHandler, any>({
      path: `/api/resource/feedback`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceFeedbackCreate
   * @summary Creates the resource feedback.
   * @request POST:/api/resource/feedback
   * @response `200` `ResourceFeedbackModelResultHandler` Success
   */
  resourceFeedbackCreate = (data: ResourceFeedbackPayload, params: RequestParams = {}) =>
    this.request<ResourceFeedbackModelResultHandler, any>({
      path: `/api/resource/feedback`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceFeedbackDelete
   * @summary Removes the resource feedback.
   * @request DELETE:/api/resource/feedback/{resourceFeedbackId}
   * @response `200` `ResultHandler` Success
   */
  resourceFeedbackDelete = (resourceFeedbackId: string, params: RequestParams = {}) =>
    this.request<ResultHandler, any>({
      path: `/api/resource/feedback/${resourceFeedbackId}`,
      method: 'DELETE',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceEmployeeGoalDetail
   * @summary Gets the resource employee Goal.
   * @request GET:/api/resource/Employee/Goal/{resourceEmployeeGoalId}
   * @response `200` `ResourceEmployeeGoalModelResultHandler` Success
   */
  resourceEmployeeGoalDetail = (resourceEmployeeGoalId: string, params: RequestParams = {}) =>
    this.request<ResourceEmployeeGoalModelResultHandler, any>({
      path: `/api/resource/Employee/Goal/${resourceEmployeeGoalId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceEmployeeGoalDelete
   * @summary Creates the resource employee goal.
   * @request DELETE:/api/resource/Employee/Goal/{resourceEmployeeGoalId}
   * @response `200` `ResourceEmployeeGoalModelResultHandler` Success
   */
  resourceEmployeeGoalDelete = (resourceEmployeeGoalId: string, params: RequestParams = {}) =>
    this.request<ResourceEmployeeGoalModelResultHandler, any>({
      path: `/api/resource/Employee/Goal/${resourceEmployeeGoalId}`,
      method: 'DELETE',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceEmployeeGoalList
   * @summary Gets the resource employee Goals list.
   * @request GET:/api/resource/Employee/Goal
   * @response `200` `ResourceEmployeeGoalModelPaginationResultResultHandler` Success
   */
  resourceEmployeeGoalList = (query: ResourceEmployeeGoalListParams, params: RequestParams = {}) =>
    this.request<ResourceEmployeeGoalModelPaginationResultResultHandler, any>({
      path: `/api/resource/Employee/Goal`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceEmployeeGoalUpdate
   * @summary Updates the resource employee goal.
   * @request PUT:/api/resource/Employee/Goal
   * @response `200` `ResourceEmployeeGoalModelResultHandler` Success
   */
  resourceEmployeeGoalUpdate = (data: ResourceEmployeeGoalModel, params: RequestParams = {}) =>
    this.request<ResourceEmployeeGoalModelResultHandler, any>({
      path: `/api/resource/Employee/Goal`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceEmployeeGoalCreate
   * @summary Creates the resource employee goal.
   * @request POST:/api/resource/Employee/Goal/{resourceEmployeeId}
   * @response `200` `ResourceEmployeeGoalModelResultHandler` Success
   */
  resourceEmployeeGoalCreate = (
    resourceEmployeeId: string,
    data: ResourceEmployeeGoalModel,
    params: RequestParams = {}
  ) =>
    this.request<ResourceEmployeeGoalModelResultHandler, any>({
      path: `/api/resource/Employee/Goal/${resourceEmployeeId}`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoAllList
   * @summary Get all resources into a assessment list asynchronous.
   * @request GET:/api/resource/resourceInfo/all
   * @response `200` `ResourceInfoModelArrayResultHandler` Success
   */
  resourceResourceInfoAllList = (params: RequestParams = {}) =>
    this.request<ResourceInfoModelArrayResultHandler, any>({
      path: `/api/resource/resourceInfo/all`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoList
   * @summary Get all resources info paginated from a criteria asynchronous.
   * @request GET:/api/resource/resourceInfo
   * @response `200` `ResourceInfoModelPaginationResultResultHandler` Success
   */
  resourceResourceInfoList = (query: ResourceResourceInfoListParams, params: RequestParams = {}) =>
    this.request<ResourceInfoModelPaginationResultResultHandler, any>({
      path: `/api/resource/resourceInfo`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoAllFilteredList
   * @summary Get all resources info from a criteria asynchronous.
   * @request GET:/api/resource/resourceInfo/all/filtered
   * @response `200` `ResourceInfoModelArrayResultHandler` Success
   */
  resourceResourceInfoAllFilteredList = (
    query: ResourceResourceInfoAllFilteredListParams,
    params: RequestParams = {}
  ) =>
    this.request<ResourceInfoModelArrayResultHandler, any>({
      path: `/api/resource/resourceInfo/all/filtered`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoIdsList
   * @summary Get all resources info ids from criteria asynchronous.
   * @request GET:/api/resource/resourceInfo/ids
   * @response `200` `StringArrayResultHandler` Success
   */
  resourceResourceInfoIdsList = (query: ResourceResourceInfoIdsListParams, params: RequestParams = {}) =>
    this.request<StringArrayResultHandler, any>({
      path: `/api/resource/resourceInfo/ids`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoPaginatedResourceIdsCreate
   * @summary Get all resources info from a resource list and filter asynchronous
   * @request POST:/api/resource/resourceInfo/paginated/resourceIds
   * @response `200` `ResourceInfoModelPaginationResultResultHandler` Success
   */
  resourceResourceInfoPaginatedResourceIdsCreate = (data: ResourceInfoCriteria, params: RequestParams = {}) =>
    this.request<ResourceInfoModelPaginationResultResultHandler, any>({
      path: `/api/resource/resourceInfo/paginated/resourceIds`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoPaginatedResourceIdsAssessmentIdsCreate
   * @summary Gets the resource information by resource identifier list asynchronous.
   * @request POST:/api/resource/resourceInfo/paginated/resourceIds/assessmentIds
   * @response `200` `ResourceInfoModelPaginationResultResultHandler` Success
   */
  resourceResourceInfoPaginatedResourceIdsAssessmentIdsCreate = (
    data: ResourceInfoProfilingCriteria,
    params: RequestParams = {}
  ) =>
    this.request<ResourceInfoModelPaginationResultResultHandler, any>({
      path: `/api/resource/resourceInfo/paginated/resourceIds/assessmentIds`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoPaginatedResourceIdsCountAppraisersCreate
   * @summary Get all resources info from a resource list and filter asynchronous
   * @request POST:/api/resource/resourceInfo/paginated/resourceIds/countAppraisers
   * @response `200` `ResourceInfoModelPaginationResultResultHandler` Success
   */
  resourceResourceInfoPaginatedResourceIdsCountAppraisersCreate = (
    data: ResourceInfoCriteria,
    params: RequestParams = {}
  ) =>
    this.request<ResourceInfoModelPaginationResultResultHandler, any>({
      path: `/api/resource/resourceInfo/paginated/resourceIds/countAppraisers`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoAllFilteredResourceIdsCreate
   * @summary Get all resources info from a resource list and filter asynchronous
   * @request POST:/api/resource/resourceInfo/all/filtered/resourceIds
   * @response `200` `ResourceInfoModelArrayResultHandler` Success
   */
  resourceResourceInfoAllFilteredResourceIdsCreate = (data: ResourceInfoCriteria, params: RequestParams = {}) =>
    this.request<ResourceInfoModelArrayResultHandler, any>({
      path: `/api/resource/resourceInfo/all/filtered/resourceIds`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoAllFilteredResourceIdsAssessmentIdsCreate
   * @summary Gets all resource information by resource identifier list asynchronous.
   * @request POST:/api/resource/resourceInfo/all/filtered/resourceIds/assessmentIds
   * @response `200` `ResourceInfoModelArrayResultHandler` Success
   */
  resourceResourceInfoAllFilteredResourceIdsAssessmentIdsCreate = (
    data: ResourceInfoProfilingCriteria,
    params: RequestParams = {}
  ) =>
    this.request<ResourceInfoModelArrayResultHandler, any>({
      path: `/api/resource/resourceInfo/all/filtered/resourceIds/assessmentIds`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoPaginatedAssessmentListCreate
   * @summary Gets the by assessment identifier list asynchronous.
   * @request POST:/api/resource/resourceInfo/paginated/assessmentList
   * @response `200` `ResourceInfoModelPaginationResultResultHandler` Success
   */
  resourceResourceInfoPaginatedAssessmentListCreate = (
    data: ResourceInfoProfilingCriteria,
    params: RequestParams = {}
  ) =>
    this.request<ResourceInfoModelPaginationResultResultHandler, any>({
      path: `/api/resource/resourceInfo/paginated/assessmentList`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoAssessmentListAnyCreate
   * @summary Exists the resource by assessment identifier list asynchronous.
   * @request POST:/api/resource/resourceInfo/assessmentList/Any
   * @response `200` `BooleanResultHandler` Success
   */
  resourceResourceInfoAssessmentListAnyCreate = (data: string[], params: RequestParams = {}) =>
    this.request<BooleanResultHandler, any>({
      path: `/api/resource/resourceInfo/assessmentList/Any`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags Resource
   * @name ResourceResourceInfoAssessmentListResourceListIdAnyCreate
   * @summary Exists any resource with answer in all assessment ids by resource ids asynchronous.
   * @request POST:/api/resource/resourceInfo/assessmentList/ResourceListId/Any
   * @response `200` `BooleanResultHandler` Success
   */
  resourceResourceInfoAssessmentListResourceListIdAnyCreate = (
    data: ResourceInfoProfilingCriteria,
    params: RequestParams = {}
  ) =>
    this.request<BooleanResultHandler, any>({
      path: `/api/resource/resourceInfo/assessmentList/ResourceListId/Any`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceClient
   * @name ResourceClientList
   * @summary Gets the resource clients.
   * @request GET:/api/resource/client
   * @response `200` `ResourceClientModelPaginationResultResultHandler` Success
   */
  resourceClientList = (query: ResourceClientListParams, params: RequestParams = {}) =>
    this.request<ResourceClientModelPaginationResultResultHandler, any>({
      path: `/api/resource/client`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceClient
   * @name ResourceClientCreate
   * @summary Creates the resource client.
   * @request POST:/api/resource/client
   * @response `200` `ResourceClientModelResultHandler` Success
   */
  resourceClientCreate = (data: ResourceClientPayload, params: RequestParams = {}) =>
    this.request<ResourceClientModelResultHandler, any>({
      path: `/api/resource/client`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceClient
   * @name ResourceClientDetail
   * @summary Gets the resource client.
   * @request GET:/api/resource/client/{resourceClientId}
   * @response `200` `ResourceClientModelResultHandler` Success
   */
  resourceClientDetail = (resourceClientId: string, params: RequestParams = {}) =>
    this.request<ResourceClientModelResultHandler, any>({
      path: `/api/resource/client/${resourceClientId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceClient
   * @name ResourceClientUpdate
   * @summary Edits the resource client.
   * @request PUT:/api/resource/client/{resourceClientId}
   * @response `200` `ResourceClientModelResultHandler` Success
   */
  resourceClientUpdate = (resourceClientId: string, data: ResourceClientPayload, params: RequestParams = {}) =>
    this.request<ResourceClientModelResultHandler, any>({
      path: `/api/resource/client/${resourceClientId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeList
   * @summary Gets the resource employees by resource.
   * @request GET:/api/resource/employee
   * @response `200` `ResourceEmployeeListItemModelPaginationResultResultHandler` Success
   */
  resourceEmployeeList = (query: ResourceEmployeeListParams, params: RequestParams = {}) =>
    this.request<ResourceEmployeeListItemModelPaginationResultResultHandler, any>({
      path: `/api/resource/employee`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeCreate
   * @summary Creates the resource employee.
   * @request POST:/api/resource/employee
   * @response `200` `ResourceEmployeeModelResultHandler` Success
   */
  resourceEmployeeCreate = (data: ResourceEmployeePayload, params: RequestParams = {}) =>
    this.request<ResourceEmployeeModelResultHandler, any>({
      path: `/api/resource/employee`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeDetail
   * @summary Gets the resource employee.
   * @request GET:/api/resource/employee/{resourceEmployeeId}
   * @response `200` `ResourceEmployeeModelResultHandler` Success
   */
  resourceEmployeeDetail = (resourceEmployeeId: string, params: RequestParams = {}) =>
    this.request<ResourceEmployeeModelResultHandler, any>({
      path: `/api/resource/employee/${resourceEmployeeId}`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeUpdate
   * @summary Edits the resource employee.
   * @request PUT:/api/resource/employee/{resourceEmployeeId}
   * @response `200` `ResourceEmployeeModelResultHandler` Success
   */
  resourceEmployeeUpdate = (resourceEmployeeId: string, data: ResourceEmployeePayload, params: RequestParams = {}) =>
    this.request<ResourceEmployeeModelResultHandler, any>({
      path: `/api/resource/employee/${resourceEmployeeId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeRoleList
   * @summary Gets the roles.
   * @request GET:/api/resource/employee/role
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  resourceEmployeeRoleList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/resource/employee/role`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeDepartmentList
   * @summary Gets the departments.
   * @request GET:/api/resource/employee/department
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  resourceEmployeeDepartmentList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/resource/employee/department`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeBranchList
   * @summary Gets the branches.
   * @request GET:/api/resource/employee/branch
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  resourceEmployeeBranchList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/resource/employee/branch`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeTeamList
   * @summary Gets the branches.
   * @request GET:/api/resource/employee/team
   * @response `200` `KeyValueModelArrayResultHandler` Success
   */
  resourceEmployeeTeamList = (params: RequestParams = {}) =>
    this.request<KeyValueModelArrayResultHandler, any>({
      path: `/api/resource/employee/team`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeImportCreate
   * @summary Imports the resources asynchronous.
   * @request POST:/api/resource/employee/import
   * @response `200` `ImportedResourceEmployeeCsvResultModelResultHandler` Success
   */
  resourceEmployeeImportCreate = (data: { file?: File }, params: RequestParams = {}) =>
    this.request<ImportedResourceEmployeeCsvResultModelResultHandler, any>({
      path: `/api/resource/employee/import`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags ResourceEmployee
   * @name ResourceEmployeeVerifyEmailExistanceCreate
   * @summary Verify if an employee email was already created for that company
   * @request POST:/api/resource/employee/verify-email-existance
   * @response `200` `BooleanResultHandler` Success
   */
  resourceEmployeeVerifyEmailExistanceCreate = (
    query: ResourceEmployeeVerifyEmailExistanceCreateParams,
    params: RequestParams = {}
  ) =>
    this.request<BooleanResultHandler, any>({
      path: `/api/resource/employee/verify-email-existance`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserMeList
   * @summary Gets the personal data.
   * @request GET:/api/user/me
   * @response `200` `ResourceModelResultHandler` Success
   */
  userMeList = (params: RequestParams = {}) =>
    this.request<ResourceModelResultHandler, any>({
      path: `/api/user/me`,
      method: 'GET',
      format: 'json',
      ...params
    });
  /**
   * No description
   *
   * @tags User
   * @name UserChangePasswordCreate
   * @summary Set the change password
   * @request POST:/api/user/change-password
   * @response `200` `BooleanResultHandler` Success
   */
  userChangePasswordCreate = (data: ChangePasswordCriteria, params: RequestParams = {}) =>
    this.request<BooleanResultHandler, any>({
      path: `/api/user/change-password`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
}
