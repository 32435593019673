import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { useTheme } from '@mui/material/styles';
import { Toolbar, Stack, Grid, Card, Box, MenuItem, Theme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TextInput } from 'components/TextInput';
import { KeyValue } from 'types/KeyValue';
import { KeyValueModel } from 'apis/backoffice-api/data-contracts';
import { useTranslation } from 'react-i18next';

export type AssessmentResourceFilterFormProps = {
  onSearch?: (prop: AssessmentResourceFilterFormValues) => void;
};

export type AssessmentResourceFilterFormValues = {
  name: string;
};

export const AssessmentResourceFilterFormInitialValues = {
  name: ''
};

export function AssessmentResourceFilterForm(props: AssessmentResourceFilterFormProps) {
  const { onSearch } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const FilterSchema = Yup.object().shape({});
  const formik = useFormik<AssessmentResourceFilterFormValues>({
    initialValues: AssessmentResourceFilterFormInitialValues,
    validationSchema: FilterSchema,
    onSubmit: async (values: AssessmentResourceFilterFormValues) => {
      onSearch && onSearch({ ...values });
    }
  });
  const { handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Toolbar style={styles.toolbar}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  display='flex'
                  alignContent={'flex-end'}
                  justifyContent={'flex-end'}
                  style={styles.card}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextInput
                        {...getFieldProps('name')}
                        fullWidth
                        label={t('common:capitalize', { value: t('common:search') })}
                        margin='normal'
                      />
                      <Box
                        sx={{ pt: 3 }}
                        display='flex'
                        alignItems='flex-end'
                        justifyContent='flex-end'
                        style={styles.card}>
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Form>
    </FormikProvider>
  );
}

const useStyles = (theme: Theme) => ({
  toolbar: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    margin: theme.spacing(0, 0, 3, 0)
  },
  card: {
    padding: theme.spacing(0, 0, 0, 3)
  }
});
