import { nbAuthConfig } from "config";
import { NbAuthApiModel } from "./NbAuthApiModel";
import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios';

export const useNbAuthApi = () => ({
  login: async (username: string, password: string): Promise<NbAuthApiModel> => {
    const data = new URLSearchParams();
    data.append("client_id", nbAuthConfig.clientId);
    data.append("client_secret", nbAuthConfig.clientSecret);
    data.append("grant_type", "password");
    data.append("username", username);
    data.append("password", password);
    
    return axios.post(nbAuthConfig.authDomain, data).then(resp => resp.data);
  }
})
