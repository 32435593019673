// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Typography, Link } from '@mui/material';

// components
import Page from 'components/Page';
import { MHidden } from 'components/@material-extend';
import { LoginForm } from 'pages/authentication/components/LoginForm';
import Logo from 'components/Logo';
import { type } from 'os';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { PATH_AUTH } from 'routes/paths';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  marginLeft: 'auto',
  boxSizing: 'border-box',
  marginRight: 'auto',
  display: 'block',
  paddingLeft: '24px',
  paddingRight: '24px'
}));

export default function Login() {
  const { t } = useTranslation();

  return (
    <RootStyle title={`${t('common:login')} | HR Metrics`}>
      <MHidden width='mdDown'>
        <SectionStyle>
          <Logo size={'large'} sx={{ margin: 'auto' }} type='full' />
        </SectionStyle>
      </MHidden>
      <Container>
        <ContentStyle>
          <Stack direction='row' alignItems='center' sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant='h4' gutterBottom>
                {t('validations:signInToHRMetrics')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{t('validations:enterYourDetailsBelow')}</Typography>
            </Box>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
