import { useDispatch } from "react-redux";
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import { KeyValue } from "types/KeyValue";
export function useAssesmentAction() {
    const dispatch = useDispatch();

    return {
        createAssesmentAction: (title: string, description: string, instructions: string, dateRange: RangeInput<Date | string>, createRankingQuestion: boolean) => {
            dispatch({
                type: "CREATE_ASSESMENT",
                data: {
                    title,
                    description,
                    instructions,
                    dateRange: dateRange,
                    createRankingQuestion: createRankingQuestion
                },
            });
        },
        createAssesmentSkillAction: (title: string, description: string, skills: KeyValue[]) => {
            dispatch({
                type: "CREATE_ASSESMENT_SKILL",
                data: {
                    title,
                    description,
                    skills
                },
            });
        },
    };
}
