import React, { ReactElement } from 'react';

// material
import { Theme } from '@mui/material/styles';

// components
import { SxProps } from '@mui/system';
import LoadingScreen from "components/LoadingScreen";

export type LoadingProps = {
  loading?: boolean,
  sx?: SxProps<Theme>,
  children: ReactElement;
}

export function Loading(props: LoadingProps) {
  const { loading, sx, children } = { ...props };
  return !loading ? children : <LoadingScreen sx={sx} />;
}
