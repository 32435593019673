import { Dialog, Grid } from '@mui/material';
// types
import { ResourceEmployee } from 'types/ResourceEmployee';
// components
import { View } from 'components/View';
import { ResourceEmployeeModel, AssessmentNodeModel } from '../../../../../apis/backoffice-api/data-contracts';

type RelationshipModalProps = {
  assessmentNodeModel?: AssessmentNodeModel[];
  showModal?: boolean;
  onClose?: () => void;
};

export function AssessmentRelationshipModal(props: RelationshipModalProps) {
  const { assessmentNodeModel, showModal, onClose } = props;
  switch (assessmentNodeModel?.length ?? 0) {
    default:
      return (
        <Dialog maxWidth='md' open={!!showModal} onClose={onClose}>
          {' '}
          <View> Não tem items</View>
        </Dialog>
      );
  }
}
