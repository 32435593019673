import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from 'redux/reducers/rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'user',
    'assessment'
  ],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  applyMiddleware(),
);

const persistor = persistStore(store);

export { store, persistor };