// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_RESOURCES = '/resources';
const ROOTS_ASSESSMENTS = '/assessments';
const ROOTS_SETTINGS = '/settings';
const ROOTS_RESULTS = '/results'
const ROOTS_DASHBOARD = '/';
const ROOTS_NBOX = ROOTS_RESULTS + '/nbox';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  newPassword: path(ROOTS_AUTH, '/new-password')
};


export const PATH_BACKOFFICE = {
  root: '/',
  resources: {
    root: path(ROOTS_RESOURCES, '/employee'),
    employee: {
      root: path(ROOTS_RESOURCES, '/employee'),
      list: path(ROOTS_RESOURCES, '/employee/list'),
      new: path(ROOTS_RESOURCES, '/employee/new'),
      edit: path(ROOTS_RESOURCES, '/employee/edit')
    }
  },
  assessments: {
    performance: {
      root: path(ROOTS_ASSESSMENTS, '/performance'),
      list: path(ROOTS_ASSESSMENTS, '/performance/list'),
      new: path(ROOTS_ASSESSMENTS, '/performance/new'),
      newTest: path(ROOTS_ASSESSMENTS, '/performance/newTest'),
      edit: path(ROOTS_ASSESSMENTS, '/performance/edit'),
      appraisees: path(ROOTS_ASSESSMENTS, '/performance/appraisees'),
      appraiseesGroup: path(ROOTS_ASSESSMENTS, '/performance/appraisees/group'),
      reportResults: path(ROOTS_ASSESSMENTS, "/performance/reports")
    },
    skills: {
      root: path(ROOTS_ASSESSMENTS, '/skills'),
      list: path(ROOTS_ASSESSMENTS, '/skills/list'),
      new: path(ROOTS_ASSESSMENTS, '/skills/new'),
      edit: path(ROOTS_ASSESSMENTS, '/skills/edit'),
      appraisees: path(ROOTS_ASSESSMENTS, '/skills/appraisees'),
      appraiseesGroup: path(ROOTS_ASSESSMENTS, '/skills/appraisees/group'),
    },
    profile: {
      root: path(ROOTS_ASSESSMENTS, '/profile'),
    }
  },
  settings: {
    password: {
      root: path(ROOTS_SETTINGS, '/password'),
    }
  },
  results: {
    performance: {
      root: path(ROOTS_RESULTS, '/performance'),
    },
    addPeople: {
      root: path(ROOTS_RESULTS, '/add')
    },
    nbox: {
      root: path(ROOTS_NBOX, '/'),
      addPersons: path(ROOTS_NBOX, '/add-persons'),
      appraiseesGroup: path(ROOTS_NBOX, '/add-persons/group'),
      new: {
        root: path(ROOTS_NBOX, '/new'),
      },
      edit: {
        root: path(ROOTS_NBOX, '/edit/:profilingId')
      },
      quadrant: {
        root: path(ROOTS_NBOX, '/quadrant/:profilingId'),
        position: path(ROOTS_NBOX, '/quadrant/position/:position')
      }
    },
  },
};

export const PATH_DASHBOARD = {
  root: '/',
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
