import { useEffect, useState } from 'react';

// material
import { Box, Stack, Tab } from '@mui/material';

// components
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AssessmentNodeConditionEnum, AssessmentNodeModel, ResourceInfoModel } from 'apis/backoffice-api/data-contracts';
import { Loading } from 'components/Loading';
import Scrollbar from 'components/Scrollbar';
import { useResourceService } from 'services/Resource';
import AppraiserList from './AppraiserList';
import { useTranslation } from 'react-i18next';
import { useAssessmentNodeService } from 'services/AssessmentNodeService';

export type Props = {
  setSelfAssessmentNodes: (data: AssessmentNodeModel[]) => void,
  selfAssessmentNodes?: AssessmentNodeModel[],
  assessmentId: string,
  resourceId: string,
  isReadOnly: boolean,
  appraiserAssessmentNodeModelsToEdit: AssessmentNodeModel[],
  setAppraiserAssessmentNodeModelsToEdit: (data: AssessmentNodeModel[]) => void,
}
export default function AppraiseeEdit({ 
  setSelfAssessmentNodes, 
  selfAssessmentNodes, 
  assessmentId, 
  resourceId, 
  isReadOnly,
  appraiserAssessmentNodeModelsToEdit, 
  setAppraiserAssessmentNodeModelsToEdit }: Props) {

  const [loading, setLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('1');
  const [selfAssessmentNodeModel, setSelfAssessmentNodeModel] = useState<AssessmentNodeModel>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const { t } = useTranslation();
  const { assessmentNodesDetailByAssessmentIdAndAppraiseeId } = useAssessmentNodeService();

  const getAssessmentNodesByAppraiseeId = (appraiseeId:string) =>{
    setLoading(true);
    (async () => {
      await assessmentNodesDetailByAssessmentIdAndAppraiseeId(assessmentId, appraiseeId)
        .then((data) => {
          if((data?.length ?? 0) > 0)
            setAppraiserAssessmentNodeModelsToEdit(data ?? []);
          setLoading(false);
        })
        .catch((errorValue) => {
          console.log(errorValue);
          setLoading(false);
        });
    })();
  }

  useEffect(() => {
    let selfAssessmentNodeIndex = selfAssessmentNodes?.findIndex((assessmentNode) => assessmentNode.appraiseeAssessmentParticipant?.resourceId == resourceId) ?? -1;
    if(selfAssessmentNodes != null && selfAssessmentNodeIndex >= 0){
      setSelfAssessmentNodeModel(selfAssessmentNodes[selfAssessmentNodeIndex]);

      if(selfAssessmentNodes[selfAssessmentNodeIndex]?.condition == AssessmentNodeConditionEnum.NotModified)
        getAssessmentNodesByAppraiseeId(selfAssessmentNodes[selfAssessmentNodeIndex]?.appraiseeAssessmentParticipantId ?? "");
      else{
        var selfAssessmentNode = [selfAssessmentNodes[selfAssessmentNodeIndex]];
        setAppraiserAssessmentNodeModelsToEdit(selfAssessmentNode);
      }
    }
  }, []);

  return (
    <Loading loading={loading}>
      <Stack pt={2} spacing={2}>
        <Scrollbar>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label={t('common:capitalize', {value: t('assessment:relationshipWithEvaluators')})}>
                <Tab label={t('common:capitalize', {value: t('common:directManager')})} value="1" />
                <Tab label={t('common:capitalize', {value: t('common:indirectManager')})} value="2" />
                <Tab label={t('common:capitalize', {value: t('common:subordinate')})} value="3" />
                <Tab label={t('common:capitalize', {value: t('common:peer')})} value="4" />
                <Tab label={t('common:capitalize', {value: t('common:internalClient')})} value="5" />
                <Tab label={t('common:capitalize', {value: t('common:externalClient')})} value="6" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <AppraiserList 
                relationshipName={'Leader'} 
                assessmentId={assessmentId} 
                resourceId={resourceId}
                isReadOnly={isReadOnly}
                appraiserAssessmentNodeModelsToEdit={appraiserAssessmentNodeModelsToEdit}
                setAppraiserAssessmentNodeModelsToEdit={setAppraiserAssessmentNodeModelsToEdit}/>
            </TabPanel>

            <TabPanel value="2">
              <AppraiserList 
                relationshipName={'LeaderIndirect'} 
                assessmentId={assessmentId} 
                resourceId={resourceId}
                isReadOnly={isReadOnly}
                appraiserAssessmentNodeModelsToEdit={appraiserAssessmentNodeModelsToEdit}
                setAppraiserAssessmentNodeModelsToEdit={setAppraiserAssessmentNodeModelsToEdit}/>
            </TabPanel>

            <TabPanel value="3">
              <AppraiserList 
                relationshipName={'Staff'} 
                assessmentId={assessmentId} 
                resourceId={resourceId}
                isReadOnly={isReadOnly}
                appraiserAssessmentNodeModelsToEdit={appraiserAssessmentNodeModelsToEdit}
                setAppraiserAssessmentNodeModelsToEdit={setAppraiserAssessmentNodeModelsToEdit}/>
            </TabPanel>

            <TabPanel value="4">
              <AppraiserList 
                relationshipName={'Peer'} 
                assessmentId={assessmentId} 
                resourceId={resourceId}
                isReadOnly={isReadOnly}
                appraiserAssessmentNodeModelsToEdit={appraiserAssessmentNodeModelsToEdit}
                setAppraiserAssessmentNodeModelsToEdit={setAppraiserAssessmentNodeModelsToEdit}/>
            </TabPanel>

            <TabPanel value="5">
              <AppraiserList 
              relationshipName={'ClientInternal'} 
              assessmentId={assessmentId} 
              resourceId={resourceId}
              isReadOnly={isReadOnly}
              appraiserAssessmentNodeModelsToEdit={appraiserAssessmentNodeModelsToEdit}
              setAppraiserAssessmentNodeModelsToEdit={setAppraiserAssessmentNodeModelsToEdit}/>
            </TabPanel>
            
            <TabPanel value="6">
              <AppraiserList 
                relationshipName={'ClientExternal'} 
                assessmentId={assessmentId} 
                resourceId={resourceId}
                isReadOnly={isReadOnly}
                appraiserAssessmentNodeModelsToEdit={appraiserAssessmentNodeModelsToEdit}
                setAppraiserAssessmentNodeModelsToEdit={setAppraiserAssessmentNodeModelsToEdit}/>
            </TabPanel>
          </TabContext>
        </Scrollbar>
      </Stack>
    </Loading>
  );
}
