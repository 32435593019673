import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';

// routes
import { AppraiserDialog } from '../../components/AppraiserDialog';
import {
  AssessmentNodeConditionEnum,
  AssessmentNodeModel,
  AssessmentPerformanceNextModel,
  AssessmentStatusEnum
} from 'apis/backoffice-api/data-contracts';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

type UserMoreMenuProps = {
  setSelfAssessmentNodes: (data: AssessmentNodeModel[]) => void;
  selfAssessmentNodes: AssessmentNodeModel[];
  resourceId?: string | null;
  resourceName?: string | null;
  assessment?: AssessmentPerformanceNextModel | undefined;
};

export function AssessmentResourceActionMenu({
  setSelfAssessmentNodes,
  selfAssessmentNodes,
  resourceId,
  resourceName,
  assessment,
}: UserMoreMenuProps) {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openAppraiserDialog, setOpenAppraiserDialog] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const isReadOnly = assessment?.status === AssessmentStatusEnum.Completed;

  const { t } = useTranslation();

  const handleClickAppraiserDialogOpen = () => {
    setOpenAppraiserDialog(true);
  };

  const handleDeleteResource = async (id?: string | null | undefined) => {
    let assessmentNodeToDeleteIndex = selfAssessmentNodes.findIndex(
      (assessmentNode) => assessmentNode.appraiseeAssessmentParticipant?.resourceId == id
    );

    let newAssessmentNodesFiltered = [...selfAssessmentNodes];

    newAssessmentNodesFiltered[assessmentNodeToDeleteIndex].condition = AssessmentNodeConditionEnum.Deleted;

    if (assessment?.status === AssessmentStatusEnum.Started) {
      if (newAssessmentNodesFiltered.length >= 1) {
        setSelfAssessmentNodes(newAssessmentNodesFiltered);
      } else {
        enqueueSnackbar(`${t('common:capitalize', { value: t('skill:cantDeleteAllTheEvaluators') })}`, {
          variant: 'error'
        });
      }
    } else {
      setSelfAssessmentNodes(newAssessmentNodesFiltered);
    }

    setModalDeleteVisible(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsMenuOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={ref.current}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          sx: { width: 220, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem onClick={handleClickAppraiserDialogOpen}>
          <ListItemIcon>
            <Icon icon={isReadOnly ? eyeFill : editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={
              isReadOnly
                ? `${t('common:capitalize', { value: t('common:viewAppraisers') })}`
                : `${t('common:capitalize', { value: t('common:editAppraisers') })}`
            }
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        {!isReadOnly && (
          <MenuItem onClick={() => setModalDeleteVisible(true)} disabled={isReadOnly}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t('common:capitalize', { value: t('common:deleteAppraisees') })}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
      </Menu>
      <AppraiserDialog
        appraiserModalOpen={openAppraiserDialog}
        setAppraiserModalOpen={setOpenAppraiserDialog}
        isReadOnly={isReadOnly}
        resourceName={resourceName ?? ''}
        resourceId={resourceId ?? ''}
        setSelfAssessmentNodes={setSelfAssessmentNodes}
        selfAssessmentNodes={selfAssessmentNodes}
      />

      <Dialog open={modalDeleteVisible} onClose={() => setIsOpen(false)} maxWidth='xs'>
        <DialogTitle>
          {t('common:capitalize', { value: t('assessment:doYouWantToConfirmTheExclusionOfTheAppraisee') })}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setModalDeleteVisible(false)}>
            {t('common:capitalize', { value: t('common:cancel') })}
          </Button>
          <Button variant='contained' onClick={() => handleDeleteResource(resourceId)} autoFocus>
            {t('common:capitalize', { value: t('common:delete') })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
