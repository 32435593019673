import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editOutline from '@iconify/icons-eva/edit-outline';
import eyeOutline from '@iconify/icons-eva/eye-outline';
import { Link as RouterLink } from 'react-router-dom';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { useSnackbar } from 'notistack';

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';

// routes
import { PATH_BACKOFFICE } from 'routes/paths';
import { AppraiserDialog } from '../../components/AppraiserDialog';
import {
  AssessmentNodeModel,
  AssessmentPerformanceNextModel,
  AssessmentStatusEnum
} from 'apis/backoffice-api/data-contracts';
import { useAssessmentPerformanceService } from 'services/AssessmentPerformanceService';
import { useTranslation } from 'react-i18next';

type UserMoreMenuProps = {
  setAssessmentNodes: (data: AssessmentNodeModel[]) => void;
  assessmentNodes: AssessmentNodeModel[];
  resourceId?: string | null;
  resourceName?: string | null;
  assessmentStatus?: AssessmentStatusEnum | undefined;
};

export function AssessmentResourceActionMenu({
  setAssessmentNodes,
  assessmentNodes,
  resourceId,
  resourceName,
  assessmentStatus
}: UserMoreMenuProps) {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openAppraiserDialog, setOpenAppraiserDialog] = useState(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState(false);
  const isReadOnly = assessmentStatus === AssessmentStatusEnum.Completed;
  const { t } = useTranslation();

  const handleClickAppraiserDialogOpen = () => {
    setOpenAppraiserDialog(true);
  };

  const handleDeleteResource = async (id?: string | null | undefined) => {
    let newAssessmentNodesFiltered = assessmentNodes.filter(
      (assessmentNode) => assessmentNode.appraiseeAssessmentParticipant?.resourceId != id
    );
    if (assessmentStatus === AssessmentStatusEnum.Started) {
      if (newAssessmentNodesFiltered.length >= 1) {
        setAssessmentNodes(newAssessmentNodesFiltered);
      } else {
        enqueueSnackbar(`${t('common:capitalize', { value: t('skill:cantDeleteAllTheEvaluators') })}`, {
          variant: 'error'
        });
      }
    } else {
      setAssessmentNodes(newAssessmentNodesFiltered);
    }

    setModalDeleteVisible(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsMenuOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={ref.current}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{
          sx: { width: 220, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem onClick={handleClickAppraiserDialogOpen}>
          <ListItemIcon>
            <Icon icon={isReadOnly ? eyeOutline : editOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={
              isReadOnly
                ? `${t('common:capitalize', { value: t('common:viewAppraisers') })}`
                : `${t('common:capitalize', { value: t('common:editAppraisers') })}`
            }
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        {!isReadOnly && (
          <MenuItem onClick={() => setModalDeleteVisible(true)} disabled={isReadOnly}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t('common:capitalize', { value: t('common:deleteAppraisees') })}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
      </Menu>
      <AppraiserDialog
        appraiserModalOpen={openAppraiserDialog}
        setAppraiserModalOpen={setOpenAppraiserDialog}
        isReadOnly={isReadOnly}
        resourceName={resourceName ?? ''}
        resourceId={resourceId ?? ''}
        setAssessmentNodes={setAssessmentNodes}
        assessmentNodes={assessmentNodes}
      />
      <Dialog open={modalDeleteVisible} onClose={() => setIsOpen(false)} maxWidth='xs'>
        <DialogTitle>
          {t('common:capitalize', { value: t('skill:doYouWantToConfirmTheExclusionOfTheAppraisee') })}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setModalDeleteVisible(false)}>
            {t('common:capitalize', { value: t('common:cancel') })}
          </Button>
          <Button variant='contained' onClick={() => handleDeleteResource(resourceId)} autoFocus>
            {t('common:capitalize', { value: t('common:delete') })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
