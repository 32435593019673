import { useNbAuthApi } from "apis/nbauth-api/NbAuthApi";
import { useAuthAction } from "redux/actions/authAction";
import { useSelector } from "react-redux";
import { backofficeApiConfig } from "config";
import { AuthReducerState } from "redux/reducers/authReducer";
import { DateTime } from "luxon";
import { AuthApi } from 'apis/auth-api';
import { ForgotPasswordCriteria, NewPasswordCriteria, VerifyTokenCriteria } from 'apis/auth-api/data-contracts';

export const useAuthService = () => {
  const { login } = useNbAuthApi();

  const { loginAction, logoutAction } = useAuthAction();
  const { loggedIn, expiresAt } = useSelector((state: { auth: AuthReducerState }) => state?.auth);

  const authApi = new AuthApi({
    baseURL: backofficeApiConfig.authDomain,
  });

  return {
    login: async (username: string, password: string): Promise<void> => {
      await localStorage.removeItem('persist:root')
      await Promise.resolve(login(username, password).then(async (authData) => {
        loginAction(
          authData.access_token,
          authData.refresh_token,
          DateTime.now().plus(authData.expires_in * 1000),
        );
      }));
    },

    logout: async () => {
      await localStorage.removeItem('persist:root')
      logoutAction()
    },

    isAuthenticated: loggedIn && !!expiresAt && DateTime.fromISO(expiresAt) > DateTime.now(),

    authForgotPasswordCreate: async (data: ForgotPasswordCriteria): Promise<any> =>
      authApi.authForgotPasswordCreate(data).then((resp) => resp),

    authValidateToken: async (data: VerifyTokenCriteria): Promise<any> =>
      authApi.authVerifyCreate(data).then((resp) => resp),

    authNewPasswordCreate: async (data: NewPasswordCriteria): Promise<any> =>
      authApi.authNewPasswordCreate(data).then((resp) => resp),
  }
}
