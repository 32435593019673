import { useEffect, useState, MouseEvent } from 'react';

// material
import {
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Stack,
  Card,
  Grid,
  Typography,
  Avatar,
  Autocomplete,
  TextField,
  ListItemIcon,
  ListItemText,
  Checkbox,
  useTheme,
  Theme,
  Button,
  CircularProgress
} from '@mui/material';
import { debounce } from '@mui/material/utils';

//services
import { useAssessmentPerformanceService } from 'services/AssessmentPerformanceService';

// components
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import SearchNotFound from 'components/SearchNotFound/SearchNotFound';
import {
  AssessmentNodeModel,
  AssessmentNodeStatusEnum,
  AssessmentStatusEnum,
  ResourceInfoModel
} from 'apis/backoffice-api/data-contracts';
import { getFirstCharacter } from 'utils/firstCharacter';
import { v4 as uuidv4 } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { spacing } from '@mui/system';
import { useTranslation } from 'react-i18next';

export type Props = {
  assessmentId: string;
  selectedResources: ResourceInfoModel[];
  setSelectedResources: (newState: ResourceInfoModel[]) => void;
  status?: AssessmentStatusEnum;
};

export default function AppraiserListEmail({
  assessmentId,
  selectedResources,
  setSelectedResources,
  status
}: Props) {
  const [limit] = useState<number>(20);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [resourcesOnList, setResourcesOnList] = useState<ResourceInfoModel[]>([]);
  const [resourceInfos, setResourceInfos] = useState<ResourceInfoModel[]>([]);
  const [resourcesFilteredAdded, setResourcesFilteredAdded] = useState<ResourceInfoModel[]>([]);
  const { assessmentAppraisersInAssessmentDetail } = useAssessmentPerformanceService();
  const theme = useTheme();
  const styles = useStyles(theme);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [tablepage, setTablePage] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(status === AssessmentStatusEnum.Started ? true : false);
  const [filter, setFilter] = useState('');
  const [chkInitialized, setChkInitialized] = useState(true);
  const [chkNotInitialized, setChkNotInitialized] = useState(true);
  const [chkCompleted, setChkCompleted] = useState(true);
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());
  const [resourceInfosAutocomplete, setResourceInfosAutocomplete] = useState<ResourceInfoModel[]>();

  const [loadingResources, setLoadingResources] = useState(true);

  const [selectedAutoComplete, setSelectedAutoComplete] = useState<ResourceInfoModel>({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [resourceInfoModelClean, setResourceInfoModelClean] = useState<ResourceInfoModel>({
    firstName: '',
    lastName: '',
    email: ''
  });
  const { t } = useTranslation();
  interface ResourceInfoAdd extends ResourceInfoModel {
    chkAdded?: boolean;
  }

  useEffect(() => {
    if (status != AssessmentStatusEnum.NotStarted) {
      setResourcesOnList(resourceInfos);
      let updatedSelectedResources = Array.from(new Set(resourceInfos));
      setSelectedResources(updatedSelectedResources);
    }
  }, [resourceInfos]);

  useEffect(() => {
    fetchResourceInfo();
    if (status != AssessmentStatusEnum.NotStarted) {
      setExpanded('panel1');
    } else {
      setExpanded('panel2');
    }
  }, []);

  useEffect(() => {
    setTotalCount(resourcesOnList.length);
  }, [resourcesOnList]);

  const changeNotInitializedStatus = (chkNotInitialized: boolean) => {
    let resourcesFiltered: ResourceInfoModel[] = [];
    let newResourceSelected: string[] = [];
    resourcesFiltered = getResourcesByCheckBoxes('notInitialized', chkNotInitialized);
    newResourceSelected = resourcesFiltered.map((x) => x.resourceId ?? '');
    resourcesFiltered = sortResourcesAlphabeticly(resourcesFiltered);
    
    setResourcesOnList(resourcesFiltered);
    setSelectedResources(resourcesFiltered);
    setIsAllSelected(newResourceSelected.length === resourcesFiltered.length && resourcesFiltered.length !== 0);
  };

  const changeInitializedStatus = (chkInitialized: boolean) => {
    let resourcesFiltered: ResourceInfoModel[] = [];
    let newResourceSelected: string[] = [];

    resourcesFiltered = getResourcesByCheckBoxes('initialized', chkInitialized);
    newResourceSelected = resourcesFiltered.map((x) => x.resourceId ?? '');
    resourcesFiltered = sortResourcesAlphabeticly(resourcesFiltered);
    
    setResourcesOnList(resourcesFiltered);
    setSelectedResources(resourcesFiltered);
    setIsAllSelected(newResourceSelected.length === resourcesFiltered.length && resourcesFiltered.length !== 0);
  };

  const changeCompletedStatus = (chkCompleted: boolean) => {
    let resourcesFiltered: ResourceInfoModel[] = [];
    let newResourceSelected: string[] = [];

    resourcesFiltered = getResourcesByCheckBoxes('completed', chkCompleted);
    newResourceSelected = resourcesFiltered.map((x) => x.resourceId ?? '');
    resourcesFiltered = sortResourcesAlphabeticly(resourcesFiltered);
    
    setResourcesOnList(resourcesFiltered);
    setSelectedResources(resourcesFiltered);
    setIsAllSelected(newResourceSelected.length === resourcesFiltered.length && resourcesFiltered.length !== 0);
  };

  const getResourcesByCheckBoxes = (chkName: string, chkValue: boolean) => {
    let resources: ResourceInfoModel[] = [];

    const notInitialized = resourceInfos.filter((x) => x?.statusInAssessment === 'NotInitialized');
    const initialized = resourceInfos.filter((x) => x?.statusInAssessment === 'Initialized');
    const completed = resourceInfos.filter((x) => x?.statusInAssessment === 'Completed');

    if (chkName === 'notInitialized') {
      if (chkValue == true) resources.push(...notInitialized);
      if (chkInitialized) resources.push(...initialized);
      if (chkCompleted) resources.push(...completed);
    }

    if (chkName === 'initialized') {
      if (chkValue == true) resources.push(...initialized);
      if (chkNotInitialized) resources.push(...notInitialized);
      if (chkCompleted) resources.push(...completed);
    }

    if (chkName === 'completed') {
      if (chkValue == true) resources.push(...completed);
      if (chkNotInitialized) resources.push(...notInitialized);
      if (chkInitialized) resources.push(...initialized);
    }

    return resources;
  };

  const sortResourcesAlphabeticly = (resources: ResourceInfoModel[]) => {
    return resources.sort((a, b) => {
      if (a?.firstName != null && b?.firstName != null) {
        if (a?.firstName + ' ' + a?.lastName < b?.firstName + ' ' + b?.lastName) {
          return -1;
        }
        if (a?.firstName + ' ' + a?.lastName > b?.firstName + ' ' + b?.lastName) {
          return 1;
        }
      }
      return 0;
    });
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (status != AssessmentStatusEnum.NotStarted) {
      setExpanded(isExpanded ? panel : false);
    }
  };

  const fetchResourceInfo = async () => {
    setLoadingResources(true);
    await assessmentAppraisersInAssessmentDetail(assessmentId ?? '')
      .then((data) => {
        const resourceInfosData = data as ResourceInfoModel[];

        resourceInfosData.sort((a, b) => {
          if (a?.firstName != null && b?.firstName != null) {
            if (a?.firstName + ' ' + a?.lastName < b?.firstName + ' ' + b?.lastName) {
              return -1;
            }
            if (a?.firstName + ' ' + a?.lastName > b?.firstName + ' ' + b?.lastName) {
              return 1;
            }
          }
          return 0;
        });
        if (status === AssessmentStatusEnum.NotStarted) {
          setResourceInfosAutocomplete(resourceInfosData ?? []);
        } else {
          setResourceInfos(resourceInfosData ?? []);
        }
      })
      .catch((errorValue) => {
        console.log(errorValue);
      })
      .finally(() => {
        setLoadingResources(false);
      });
  };

  const onChangeResourceStatus = (e: React.ChangeEvent<HTMLInputElement>, nodeStatus: AssessmentNodeStatusEnum) => {
    if (nodeStatus === AssessmentNodeStatusEnum.Started) {
      setChkInitialized(e.target.checked);
      changeInitializedStatus(e.target.checked);
    } else if (nodeStatus === AssessmentNodeStatusEnum.NotStarted) {
      setChkNotInitialized(e.target.checked);
      changeNotInitializedStatus(e.target.checked);
    } else {
      setChkCompleted(e.target.checked);
      changeCompletedStatus(e.target.checked);
    }
  };

  const handlePageChange = (e: MouseEvent<HTMLButtonElement> | null, newPage: number) => setTablePage(newPage + 1);

  const onChangeResourceSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updateSelectedResources = [...selectedResources];
    if (e.target.checked) updateSelectedResources.push(resourcesOnList.filter((resourceInfo) => resourceInfo.resourceId === e.target.value)[0]);
    else updateSelectedResources.splice(updateSelectedResources.findIndex((selectedResource) => selectedResource.resourceId === e.target.value), 1);
    setSelectedResources(updateSelectedResources);

    setIsAllSelected(updateSelectedResources.length === resourcesOnList.length);
  };

  const onChangeAllResourcesSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllSelected(e.target.checked);
    let updatedSelectedResources = [...selectedResources];

    if (e.target.checked) {
      updatedSelectedResources = Array.from(new Set(resourcesOnList));
    } else {
      updatedSelectedResources = [];
    }
    setSelectedResources([...updatedSelectedResources]);
  };

  const handleStatusText = (statusText: string) => {
    switch (statusText) {
      case 'Initialized':
        return t('sendEmail:initialized');
      case 'NotInitialized':
        return t('sendEmail:notInitialized');
      case 'Completed':
        return t('sendEmail:completed');
      default:
        '--';
    }
  };

  // const defaultProps = {
  //   options: resourceInfos,
  //   getOptionLabel: (option: ResourceInfoModel) => option.firstName + ' ' + option.lastName + '  ' + option.email ?? ''
  // };

  const handleAddAutoComplete = () => {
    if (selectedAutoComplete.resourceId) {
      const resourceFiltered = resourcesOnList?.filter((resource) => {
        return resource?.resourceId == selectedAutoComplete.resourceId;
      });

      let updateResourcesFiltered = [...resourcesOnList];
      let updateSelectedResources = [...selectedResources];
      let updateResourcesFilteredAdded = [...resourcesFilteredAdded];

      if (resourceFiltered.length == 0) {
        updateResourcesFiltered.push(selectedAutoComplete);
        updateResourcesFilteredAdded.push(selectedAutoComplete);
        updateSelectedResources.push(selectedAutoComplete);

        updateResourcesFiltered.sort((a, b) => {
          if (a?.firstName != null && b?.firstName != null) {
            if (a?.firstName + ' ' + a?.lastName < b?.firstName + ' ' + b?.lastName) {
              return -1;
            }
            if (a?.firstName + ' ' + a?.lastName > b?.firstName + ' ' + b?.lastName) {
              return 1;
            }
          }
          return 0;
        });

        setResourcesOnList(updateResourcesFiltered);
        setResourcesFilteredAdded(updateResourcesFilteredAdded);
        setSelectedResources(updateSelectedResources);
      } else {
        let resourceAdd = resourcesFilteredAdded?.filter(
          (resourceInfo) => resourceInfo?.resourceId === selectedAutoComplete.resourceId
        );
        let selectedResourceExist = selectedResources?.filter(
          (resourceId) => resourceId === selectedAutoComplete.resourceId
        );

        if (resourceAdd.length == 0) {
          updateResourcesFilteredAdded.push(selectedAutoComplete);
          setResourcesFilteredAdded(updateResourcesFilteredAdded);
        }
        if (selectedResourceExist.length == 0) {
          updateSelectedResources.push(selectedAutoComplete);
          setSelectedResources(updateSelectedResources);
        }
      }
    }
    setSelectedAutoComplete(resourceInfoModelClean);
  };

  return (
    <div style={{ backgroundColor: 'white', padding: '20px' }}>
      {status !== AssessmentStatusEnum.NotStarted && (
        <Box marginBottom={3}>
          <Card style={styles.card} key='importar'>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: '36px' }} />}
                aria-controls='panel1bh-content'
                id='panel1bh-header'>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item xs={3} md={6}>
                      <Typography variant='h5'>{t('sendEmail:selectSender')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <Stack
                direction='row'
                alignContent='center'
                alignItems='center'
                paddingBottom={0}
                width='100%'
                justifyContent='center'>
                <div>
                  <Checkbox
                    checked={chkInitialized}
                    color='primary'
                    value={'Initialized'}
                    onChange={(e) => onChangeResourceStatus(e, AssessmentNodeStatusEnum.Started)}
                  />
                  {t('sendEmail:startedAssessment')}
                </div>
                <div>
                  <Checkbox
                    checked={chkNotInitialized}
                    color='primary'
                    value={'NotInitialized'}
                    onChange={(e) => onChangeResourceStatus(e, AssessmentNodeStatusEnum.NotStarted)}
                  />
                  {t('sendEmail:notStartedAssessment')}
                </div>
                <Checkbox
                  checked={chkCompleted}
                  color='primary'
                  value={'Completed'}
                  onChange={(e) => onChangeResourceStatus(e, AssessmentNodeStatusEnum.Completed)}
                />
                {t('sendEmail:completedAssessment')}
              </Stack>
            </Accordion>
          </Card>
        </Box>
      )}

      {status !== AssessmentStatusEnum.Started && (
        <Box marginBottom={3}>
          <Card style={styles.card} key='importar'>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: '36px' }} />}
                aria-controls='panel2bh-content'
                id='panel2bh-header'>
                <Grid item xs={12}>
                  <Grid container justifyContent='space-between'>
                    <Grid item xs={3} md={12}>
                      <Typography variant='h5'>{t('sendEmail:selectSendersByAppraiserName')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <Stack
                direction='row'
                alignContent={'space-between'}
                paddingBottom={0}
                width='100%'
                spacing={2}
                paddingLeft={3}>
                {
                  <Autocomplete
                    options={resourceInfosAutocomplete ?? []}
                    getOptionLabel={(option: ResourceInfoModel) =>
                      option.firstName + ' ' + option.lastName + '  ' + option.email ?? ''
                    }
                    noOptionsText={t('common:capitalize', { value: t('common:noOptions') })}
                    id='resourceInfoSearch'
                    sx={{ width: '80%' }}
                    renderInput={(params) => <TextField {...params} label={t('common:name')} variant='outlined' />}
                    onChange={(event, resource) => {
                      setSelectedAutoComplete(resource as ResourceInfoModel);
                    }}
                  />

                  // <Autocomplete
                  //   {...defaultProps}
                  //   id='resourceInfoSearch'
                  //   autoComplete
                  //   noOptionsText={t('common:capitalize', { value: t('common:noOptions') })}
                  //   value={selectedAutoComplete.firstName ? selectedAutoComplete : undefined}
                  //   includeInputInList
                  //   sx={{ width: 600 }}
                  //   renderInput={(params) => <TextField {...params} label={t('common:name')} variant='outlined' />}
                  //   onChange={(event, resource) => {
                  //     setSelectedAutoComplete(resource as ResourceInfoModel);
                  //   }}
                  // />
                }
                <Button
                  size='large'
                  variant='contained'
                  onClick={() => handleAddAutoComplete()}
                  style={{ background: '#AE397F' }}>
                  {t('sendEmail:addToEmailList')}
                </Button>
              </Stack>
            </Accordion>
          </Card>
        </Box>
      )}

      <Box marginBottom={3}>
        <Card sx={{ pt: 3 }} key={'card'}>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '0 27px 24px 27px' }}>
            <Box display={'flex'} alignContent={'center'}>
              <Checkbox checked={isAllSelected} onChange={onChangeAllResourcesSelection} />
              <Typography variant='subtitle2' lineHeight='40px' noWrap>
                {t('common:selectAll')}
              </Typography>
            </Box>
            <Typography variant='subtitle2' lineHeight='40px' noWrap>
              {resourcesOnList.length} {t('common:results')}
            </Typography>
          </Box>
          <TableContainer sx={{ marginTop: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>{t('common:name')}</TableCell>
                  <TableCell align='left'>{t('common:email')}</TableCell>
                  <TableCell align='left'>{t('common:role')}</TableCell>
                  <TableCell align='left'>{t('common:department')}</TableCell>
                  <TableCell align='left'>{t('common:team')}</TableCell>
                  <TableCell align='left'>{t('common:branch')}</TableCell>
                  <TableCell align='left'>{t('common:status')}</TableCell>
                </TableRow>
              </TableHead>
              {totalCount > 0 ? (
                <TableBody>
                  {resourcesOnList.slice((tablepage - 1) * limit, tablepage * limit).map((resourceInfo) => {
                    return (
                      <TableRow hover key={resourceInfo.resourceId}>
                        <TableCell align='left'>
                          <Stack direction='row' alignItems='center' spacing={2}>
                            <Checkbox
                              checked={selectedResources.some((selectedResource) => selectedResource.resourceId === (resourceInfo?.resourceId ?? ''))}
                              color='primary'
                              value={resourceInfo.resourceId}
                              onChange={onChangeResourceSelection}
                            />
                            {!!resourceInfo.profileImageUrl ? (
                              <Avatar alt={resourceInfo.firstName ?? ''} src={resourceInfo.profileImageUrl} />
                            ) : (
                              <Avatar>{getFirstCharacter(resourceInfo.firstName ?? '')}</Avatar>
                            )}
                            <Typography variant='subtitle2' noWrap>
                              {`${resourceInfo.firstName ?? ''} ${resourceInfo.lastName ?? ''}`.trim()}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align='left'>{resourceInfo.email ?? '--'}</TableCell>
                        <TableCell align='left'>{resourceInfo.role ?? '--'}</TableCell>
                        <TableCell align='left'>{resourceInfo.department ?? '--'}</TableCell>
                        <TableCell align='left'>{resourceInfo.team ?? '--'}</TableCell>
                        <TableCell align='left'>{resourceInfo.branch ?? '--'}</TableCell>
                        <TableCell align='left'>{handleStatusText(resourceInfo.statusInAssessment ?? '')}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align='center' colSpan={8}>
                      {loadingResources ? <CircularProgress /> : <SearchNotFound />}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[limit]}
            component='div'
            count={totalCount}
            page={tablepage - 1}
            rowsPerPage={limit}
            labelRowsPerPage='Rows'
            labelDisplayedRows={({ from, to, count }) => {
              return '' + from + '-' + to + `${t('common:TextPagination')}` + count;
            }}
            onPageChange={handlePageChange}
          />
        </Card>
      </Box>
    </div>
  );
}

const useStyles = (theme: Theme) => ({
  toolbar: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    margin: theme.spacing(0, 0, 3, 0)
  },
  card: {
    padding: theme.spacing(0, 3, 3, 3)
  }
});
