import { Dialog, Grid } from "@mui/material";

// types
import { ResourceEmployee } from "types/ResourceEmployee";

// components
import { View } from "components/View";

import { ResourceEmployeeModel, AssessmentNodeModel } from "../../../../../apis/backoffice-api/data-contracts";
import { useTranslation } from "react-i18next";

type RelationshipModalProps = {
  assessmentNodeModel?: AssessmentNodeModel[],
  showModal?: boolean,
  onClose?: () => void
};

export function AssessmentRelationshipModal(props: RelationshipModalProps) {

  const { t } = useTranslation();

  const { assessmentNodeModel, showModal, onClose } = props;
  switch (assessmentNodeModel?.length ?? 0) {
    default:
      return <Dialog
        maxWidth="md"
        open={!!showModal}
        onClose={onClose}
      > <View> {t('common:capitalize', {value: t('common:noItems')})}</View>
      </Dialog>
  }
}